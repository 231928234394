import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'
import STATES from '../helpers/async'

const SET_ACTIVE_ORG = 'SET_ACTIVE_ORG'
const SET_ACTIVE_ORG_COURTS_ASYNC = 'SET_ACTIVE_ORG_ASYNC'
const SET_ACTIVE_ORG_COURTS = 'SET_ACTIVE_ORG_COURTS'
const SET_ACTIVE_ORG_RECENT_MATCHES = 'SET_ACTIVE_ORG_RECENT_MATCHES'
const SET_ACTIVE_ORG_STATUS = 'SET_ACTIVE_ORG_STATUS'
const SET_ACTIVE_ORG_MEMBERSHIP_CATS = 'SET_ACTIVE_ORG_MEMBERSHIP_CATS'
const SET_ACTIVE_ORG_MEMBERSHIP_CATS_STATUS = 'SET_ACTIVE_ORG_MEMBERSHIP_CATS_STATUS'
const SET_ORGS_STATUS = 'SET_ORGS_STATUS'
const SET_ORGS = 'SET_ORGS'
const SET_ORGS_ERROR = 'SET_ORGS_ERROR'

const initialState = {
  activeOrg: null,
  activeOrgCourts: [],
  activeOrgCourtsStatus: STATES.READY,
  activeOrgOfficers: [],
  activeOrgMembershipCats: [],
  activeOrgMembershipCatsStatus: STATES.READY,
  activeOrgRecentMatches: [],
  activeOrgStatus: STATES.READY,
  orgsStatus: STATES.READY,
  orgs: [],
  total: 0
};

const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_ORG:
      return Object.assign({}, state, {
        activeOrg: action.activeOrg,
        activeOrgOfficers: action.activeOrgOfficers,
        activeOrgStatus: STATES.READY
      });

    case SET_ACTIVE_ORG_COURTS_ASYNC:
      return Object.assign({}, state, {
        activeOrgCourtsStatus: action.activeOrgCourtsStatus,
      })

    case SET_ACTIVE_ORG_COURTS:
      return Object.assign({}, state, {
        activeOrgCourts: action.activeOrgCourts,
        activeOrgCourtsStatus: STATES.READY
      });

    case SET_ACTIVE_ORG_MEMBERSHIP_CATS:
      return Object.assign({}, state, {
        activeOrgMembershipCats: action.activeOrgMembershipCats,
        activeOrgMembershipCatsStatus: STATES.READY
      });

    case SET_ACTIVE_ORG_MEMBERSHIP_CATS_STATUS:
      return Object.assign({}, state, {
        activeOrgMembershipCatsStatus: action.status
      });

    case SET_ACTIVE_ORG_RECENT_MATCHES:
      return Object.assign({}, state, {
        activeOrgRecentMatches: action.activeOrgRecentMatches
      });

    case SET_ACTIVE_ORG_STATUS:
      return Object.assign({}, state, {
        activeOrgStatus: action.status
      })

    case SET_ORGS:
      return Object.assign({}, state, {
        orgs: action.orgs,
        orgsStatus: STATES.READY,
        total: action.total
      });

    case SET_ORGS_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        activeOrgStatus: STATES.READY
      });

    case SET_ORGS_STATUS:
      return Object.assign({}, state, {
        orgsStatus: action.orgsStatus
      });

    default:
      return state;
  }
};

const clearActiveOrg = () => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_ORG,
      activeOrg: {},
      activeOrgOfficers: []
    })
  }
}

const getMembershipCats = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_ORG_MEMBERSHIP_CATS_STATUS,
      status: STATES.FETCHING
    })
    return rtoClient.get(`${VERBS.ORG}/${id}/Categories`, { pageSize: 30 })
      .then(res => {
        dispatch({
          type: SET_ACTIVE_ORG_MEMBERSHIP_CATS,
          activeOrgMembershipCats: res.data?.items || []
        })
      })
      .catch(err => {
        console.warn(err)
        dispatch({
          type: SET_ACTIVE_ORG_MEMBERSHIP_CATS_STATUS,
          status: STATES.READY
        })
      })
  }
}

const getOrgs = (page = 1, pageSize) => {
  return (dispatch, getState) => {
    const params = { page }
    if (pageSize) {
      params.pageSize = pageSize
    }
    dispatch({
      type: SET_ORGS_STATUS,
      orgsStatus: STATES.FETCHING
    })
    return rtoClient.get(VERBS.ORGANIZATIONS, params)
      .then(res => {
        const { items, totalCount } = res.data

        const countries = getState().country.countries
        const orgs = parseOrgs(countries, items)

        dispatch({
          type: SET_ORGS,
          orgs,
          total: totalCount
        })
      })
      .catch(err => {
        dispatch({
          type: SET_ORGS_ERROR,
          error: err.message
        })
      })
  }
}

const getRecentMatches = (courtId, page = 1) => {
  return (dispatch) => {
    const now = new Date()
    let oneMonthAgo = new Date()
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
    const params = {
      courtId,
      page,
      pageSize: '20'
    }
    const url = `${VERBS.MATCH}/${oneMonthAgo.toJSON()}/${now.toJSON()}`
    return rtoClient.get(url, params)
      .then(res => {
        const { data } = res

        dispatch({
          type: SET_ACTIVE_ORG_RECENT_MATCHES,
          activeOrgRecentMatches: data.items
        })
      })
      .catch(err => {
        dispatch({
          type: SET_ORGS_ERROR,
          error: err.message
        })
      })
  }
}

const parseOrgs = (countries, orgs) => {
  if (!countries.length) return orgs

  return orgs.map(org => {
    const country = countries.find(country => country.identity === org.countryID)
    if (country) {
      org.country = country?.nameShort
    }
    return org
  })
}

const setActiveOrg = (org) => {
  if (!org.identity) return
  return (dispatch) => {
    return rtoClient.get(`${VERBS.ORG}/${org.identity}/Officers`)
      .then(res => {
        const { items } = res.data

        dispatch(setActiveOrgCourts(org.identity))
        dispatch({
          type: SET_ACTIVE_ORG,
          activeOrg: org,
          activeOrgOfficers: items
        })
      })
      .catch(err => {
        dispatch({
          type: SET_ORGS_ERROR,
          error: err.message
        })
      })
  }
}

const setActiveOrgCourts = (identity) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_ORG_COURTS_ASYNC,
      activeOrgCourtsStatus: STATES.FETCHING
    })
    return rtoClient.get(`${VERBS.ORG}/${identity}/Courts`)
      .then(res => {
        const { items } = res.data

        dispatch({
          type: SET_ACTIVE_ORG_COURTS,
          activeOrgCourts: items
        })
      })
      .catch(err => {
        dispatch({
          type: SET_ACTIVE_ORG_COURTS_ASYNC,
          activeOrgCourtsStatus: STATES.READY
        })
      })
  }
}

const setOrgById = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_ORG_STATUS,
      status: STATES.FETCHING
    })
    return rtoClient.get(`${VERBS.ORG}/${id}`)
      .then(res => {
        const { data } = res

        dispatch(setActiveOrg(data))
      })
      .catch(err => {
        dispatch({
          type: SET_ORGS_ERROR,
          error: err.message
        })
      })
  }
}

export default orgReducer;

export {
  clearActiveOrg,
  getMembershipCats,
  getRecentMatches,
  getOrgs,
  setActiveOrg,
  setOrgById,
  setActiveOrgCourts
}
