import React from 'react'
import { Box } from 'grommet'
import {
  number,
  shape,
  string
} from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Handicaps from '../../components/Handicaps'
import HandicapMovements from '../../components/HandicapMovements'
import Header from '../../components/Header'
import ResponsiveBox from '../../components/ResponsiveBox'
import Row from '../../components/Row'
import Spinner from '../../components/Spinner'
import SubHeader from '../../components/SubHeader'
import getUserName from '../../helpers/getUserName'

const Profile = ({
  countries,
  match,
  user = {}
}) => {
  if (!user) return null
  const {
    gender,
    nameFirst,
    nameTag,
    personID,
    proAmLong,
    representsCountryID,
    residenceCountryID
  } = user

  const NA = 'N/A'
  const representsCountry = (countries.length && representsCountryID) ?
    countries.find(country => country.identity === representsCountryID).nameLong : NA
  const residenceCountry = (countries.length && residenceCountryID) ?
    countries.find(country => country.identity === residenceCountryID).nameLong : NA

  if (match?.params?.id !== user?.personID?.toString()) {
    return (
      <Box align='center'>
        <Spinner label='Loading Player' />
      </Box>
    )
  }

  return (
    <Box>
      <Header title='Home' />
      <Box
        gap='small'
        margin={{ vertical: 'medium' }}
      >
        <SubHeader title='Details' />
        <ResponsiveBox gap='small'>
          <Row
            label='Preferred name'
            value={nameFirst || NA}
          />
          <Row
            label='Full name'
            value={getUserName(user, true)}
          />
        </ResponsiveBox>
        <ResponsiveBox gap='small'>
          <Row
            label='Tag'
            value={nameTag || NA}
          />
          <Row
            label='RTO number'
            value={`#${personID}`}
          />
        </ResponsiveBox>
        <ResponsiveBox gap='small'>
          <Row
            label='Country'
            value={representsCountry}
          />
          <Row
            label='Country of residence'
            value={residenceCountry}
          />
        </ResponsiveBox>
        <ResponsiveBox gap='small'>
          <Row
            label='Gender'
            value={gender || NA}
          />
          <Row
            label='Pro/Am Status'
            value={proAmLong}
          />
        </ResponsiveBox>
      </Box>
      <Box margin={{ vertical: 'medium' }}>
        <SubHeader title='Handicaps' />
        <Handicaps />
      </Box>
      <Box margin={{ vertical: 'medium' }}>
        <SubHeader title='Handicap Movements' />
        <HandicapMovements />
      </Box>
    </Box>
  )
}

Profile.propTypes = {
  user: shape({
    gender: string,
    nameFirst: string,
    nameLast: string,
    namePrefix: string,
    nameTag: string,
    personID: number,
    proAmLong: string,
    representsCountryID: number,
    residenceCountryID: number
  })
}

const mapStateToProps = (state) => ({
  countries: state.country.countries,
  user: state.player.activePlayer
})

export default withRouter(connect(mapStateToProps)(Profile))