import STATES from '../helpers/async';
import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'

const SET_SANCTIONED_MATCHES = 'SET_SANCTIONED_MATCHES'
const SET_SANCTIONED_MATCHES_STATE = 'SET_SANCTIONED_MATCHES_STATE'

const initialState = {
  sanctionedMatches: [],
  sanctionedMatchesStatus: STATES.READY
};

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SANCTIONED_MATCHES:
      return Object.assign({}, state, {
        sanctionedMatches: action.sanctionedMatches,
        sanctionedMatchesStatus: STATES.READY
      });

    case SET_SANCTIONED_MATCHES_STATE:
      return Object.assign({}, state, {
        sanctionedMatchesStatus: action.sanctionedMatchesStatus
      });

    default:
      return state;
  }
};

const getSanctionedMatches = () => {
  return (dispatch) => {
    dispatch({
      type: SET_SANCTIONED_MATCHES_STATE,
      sanctionedMatchesStatus: STATES.FETCHING
    })
    return rtoClient.get(VERBS.SANCTIONED_MATCH)
      .then(res => {
        dispatch({
          type: SET_SANCTIONED_MATCHES,
          sanctionedMatches: res.data?.items || []
        })
      })
      .catch(err => {
        console.warn(err)
        dispatch({
          type: SET_SANCTIONED_MATCHES_STATE,
          sanctionedMatchesStatus: STATES.READY
        })
      })
  }
}

export default configurationReducer;

export {
  getSanctionedMatches
}