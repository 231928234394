import React from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Form,
  TextInput,
  Text
} from 'grommet'

import PrimaryButton from '../../components/PrimaryButton'
import DateSelect from '../../components/DateSelect'
import Modal from '../../components/Modal'
import StyledFormField from '../../components/StyledFormField'
import getUserName from '../../helpers/getUserName'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'
import { setActiveOrg } from '../../ducks/org'

const ClubOfficerEdit = ({
  dispatch,
  officer,
  onClose,
  org,
  player
}) => {
  const [title, setTitle] = React.useState('')
  const [startDate, setStartDate] = React.useState(new Date())
  const [endDate, setEndDate] = React.useState()
  const action = !!officer ? 'Edit' : 'Add New'

  React.useEffect(() => {
    if (officer) {
      setTitle(officer.title)
      setStartDate(new Date(officer.startDate.replaceAll('-', '/')))
      setEndDate(new Date(officer.endDate.replaceAll('-', '/')))
    }
  }, [officer])

  const editOfficer = (params) => {
    rtoClient.put(`${VERBS.ORG_OFFICER}/${officer.orgOfficerID}`, params)
      .then(res => {
        dispatch(setActiveOrg(org))
        onClose()
      })
      .catch(err => console.warn(err))
  }

  const addOfficer = (params) => {
    rtoClient.post(`${VERBS.ORG_OFFICER}`, params)
      .then(res => {
        dispatch(setActiveOrg(org))
        onClose()
      })
      .catch(err => console.warn(err))
  }

  const onSubmit = () => {
    const params = {
      orgID: org.identity,
      personID: officer?.personID || player?.identity,
      title,
      startDate: startDate?.toJSON(),
      endDate: endDate?.toJSON()
    }
    if (!!officer) {
      editOfficer(params)
    } else if (!!player) {
      addOfficer(params)
    }
  }

  return (
    <Modal
      label={`${action} Officer`}
      onClose={onClose}
    >
      <Form onSubmit={onSubmit}>
        {!!player ? (
          <Text size='small'>
            Add <strong>{getUserName(player)}</strong> as an officer for <strong>{org.nameLong}</strong>
          </Text>
        ) : (
          <Text size='small'>
            Edit <strong>{officer.nameFirstLastTag}</strong> for <strong>{org.nameLong}</strong>
          </Text>
        )}
        <Box>
          <StyledFormField
            htmlFor='title'
            label='Title'
            name='title'
          >
            <TextInput
              id='title'
              name='title'
              onChange={e => setTitle(e.target.value)}
              size='small'
              value={title}
            />
          </StyledFormField>
          <DateSelect
            date={startDate}
            label='Start Date'
            name='startDate'
            setDate={(date) => setStartDate(date)}
          />
          <DateSelect
            date={endDate}
            label='End Date'
            name='endDate'
            setDate={(date) => setEndDate(date)}
          />
          <Box align='center'>
            <PrimaryButton
              disabled={!title.length}
              label='Save'
              type='submit'
            />
          </Box>
        </Box>
      </Form>
    </Modal>
  )
}

export default connect()(ClubOfficerEdit)