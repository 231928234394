import React from 'react'
import {
  Box,
  Button,
  Text
} from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import SubHeader from '../../components/SubHeader'

const OddsResult = ({
  onClose,
  results = {},
  values
}) => {
  const { odds } = results

  const handicapDifference = Math.abs(values.playerOneHandicap - values.playerTwoHandicap)

  return (
    <Box
      gap='small'
      pad='small'
    >
      <Box
        align='center'
        direction='row'
        justify='between'
      >
        <SubHeader
          margin='none'
          title='Results'
        />
        <Button
          label={<FontAwesomeIcon icon={faTimes} />}
          onClick={() => onClose()}
          plain
        />
      </Box>
      <Box
        align='center'
        justify='center'
        gap='small'
        height='small'
        width='medium'
      >
        <Box
          direction='row'
          gap='xsmall'
        >
          <Text weight='bold'>{values.playerOneName.nameLast}</Text>
          <Text>({values.playerOneHandicap})</Text>
          <Text>vs.</Text>
          <Text weight='bold'>{values.playerTwoName.nameLast}</Text>
          <Text>({values.playerTwoHandicap})</Text>
        </Box>
        <Text>The handicap difference is {handicapDifference}</Text>
        <Box direction='row'>
          <Text>{odds}</Text>
        </Box>
      </Box>
    </Box>
  )
}

export default OddsResult