import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'

const SET_ACTIVE_BOOKING_COURT = 'SET_ACTIVE_BOOKING_COURT'
const SET_BOOKING_MATCHES = 'SET_BOOKING_MATCHES'

const initialState = {
  activeCourt: null,
  courts: [],
  matches: []
};

const bookingSheetReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_ACTIVE_BOOKING_COURT:
      return Object.assign({}, state, {
        activeCourt: action.activeCourt,
        courts: action.courts
      });

    case SET_BOOKING_MATCHES:
      return Object.assign({}, state, {
        matches: action.matches
      })

    default:
      return state;
  }
};

const getBookings = (startDate, endDate) => {
  return (dispatch, getState) => {
    const court = getState().bookingSheet.activeCourt
    if (!court) return
    rtoClient.get(`${VERBS.MATCH}/${startDate}/${endDate}`, { courtId: court.identity })
      .then(res => {
        dispatch({
          type: SET_BOOKING_MATCHES,
          matches: res.data.items
        })
      })
      .catch(err => console.warn(err))
  }
}

const initializeBookings = (orgId, startDate, endDate) => {
  return (dispatch) => {
    rtoClient.get(`${VERBS.ORG}/${orgId}/Courts`)
      .then(res => {
        const courts = res.data.items
        dispatch({
          type: SET_ACTIVE_BOOKING_COURT,
          activeCourt: courts.length ? courts[0] : [],
          courts
        })
        dispatch(getBookings(startDate, endDate))
      })
      .catch(err => console.warn(err))
  }
}

export default bookingSheetReducer;

export {
  getBookings,
  initializeBookings
}