export const CLUBS = [
  'Queen\'s',
  'Moreton Morrell',
  'Wellington'
]

export const OCCUPATIONS = [
  'Accounting',
  'Banking, Finance, & Insurance',
  'Construction',
  'Consulting',
  'Education',
  'Entertainment',
  'Government',
  'Health & Medical',
  'Hospitality & Tourism',
  'HR & Recruitment',
  'IT',
  'Legal',
  'Manufacturing',
  'Media',
  'Primary Industry',
  'Real Estate',
  'Retail',
  'Retired',
  'Sales & Marketing',
  'Science',
  'Sports & Recreation',
  'Trades',
  'Transport & Logistics',
  'Other'
]

export const SCHOOLS = [
  'Other',
  'Bradfield',
  'Canford',
  'Charterhouse',
  'Cheltenham',
  'Clifton',
  'Eton',
  'Haileybury',
  'Harrow',
  'Kings Canterbury',
  'Malvern',
  'Marlborough',
  'Oratory',
  'Radley',
  'Rugby',
  'Sevenoaks',
  'St Pauls',
  'Stowe',
  'Tonbridge',
  'Wellington',
  'Westminster',
  'Winchester'
]