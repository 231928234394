import React from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Form,
  Select,
  Text
} from 'grommet'

import Header from '../../components/Header'
import PlayerSelect from '../../components/PlayerSelect'
import PrimaryButton from '../../components/PrimaryButton'
import StyledFormField from '../../components/StyledFormField'
import { getCourts } from '../../ducks/court'
import MATCH_TYPES from '../../helpers/matchTypes'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'

const DEFAULT = {
  hl: 'h',
  courtID: 0,
  player1: 0,
  player2: 0,
  player3: 0,
  player4: 0,
}

const WinCalculator = ({
  activePlayer,
  courts = [],
  dispatch
}) => {
  const [values, setValues] = React.useState(DEFAULT)

  React.useEffect(() => {
    dispatch(getCourts())
    if (activePlayer) {
      const newVals = Object.assign(DEFAULT, { player1: activePlayer })
      setValues(newVals)
    }
  }, [activePlayer, dispatch])

  const onSubmit = () => {
    const params = Object.assign(values)
    params.player1 = (values.player1 && values.player1.personID) || 0
    params.player2 = (values.player2 && values.player2.identity) || 0
    params.player3 = (values.player3 && values.player3.identity) || 0
    params.player4 = (values.player4 && values.player4.identity) || 0
    rtoClient.post(VERBS.CALCULATE_ODDS)
      .then(res => console.log(res))
      .catch(err => console.warn(err))
  }

  return (
    <Box>
      <Header title='Win Calculator' />
      <Form
        onChange={next => setValues(next)}
        onSubmit={() => onSubmit()}
      >
        <Box
          margin={{ horizontal: 'auto' }}
          width='medium'
        >
          <Box>
            <Box margin={{ bottom: 'xsmall' }}>
              <Text size='small' weight='bold'>Player/Pair One</Text>
            </Box>
            <StyledFormField
              htmlFor='player1__input'
              name='player1'
              required
            >
              <Select
                labelKey={e => e.nameFirstLast}
                name='player1'
                options={[activePlayer]}
                size='small'
                value={values.player1}
              />
            </StyledFormField>
            <StyledFormField
              htmlFor='player2__input'
              name='player2'
            >
              <PlayerSelect
                id='player2'
                name='player2'
              />
            </StyledFormField>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xsmall' }}>
              <Text size='small' weight='bold'>Player/Pair Two</Text>
            </Box>
            <StyledFormField
              htmlFor='player3__input'
              name='player3'
              required
            >
              <PlayerSelect
                id='player3'
                name='player3'
              />
            </StyledFormField>
            <StyledFormField
              htmlFor='player4__input'
              name='player4'
            >
              <PlayerSelect
                id='player4'
                name='player4'
              />
            </StyledFormField>
          </Box>
          <StyledFormField
            htmlFor='court'
            label='Court'
            name='court'
            required
          >
            <Select
              labelKey={e => e.nameLong}
              name='court'
              options={courts}
              required
              size='small'
              valueKey={{ key: 'identity', reduce: true }}
            />
          </StyledFormField>
          <StyledFormField
            htmlFor='hl'
            name='hl'
            label='Match Type'
          >
            <Select
              name='hl'
              labelKey={e => e.label}
              options={MATCH_TYPES}
              size='small'
              valueKey={{ key: 'value', reduce: true }}
              value={values.hl}
            />
          </StyledFormField>
          <Box
            align='center'
            margin={{ top: 'small' }}
          >
            <PrimaryButton
              label='Submit'
              type='submit'
            />
          </Box>
        </Box>
      </Form>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activePlayer: state.player.activePlayer,
  courts: state.court.courts,
})

export default connect(mapStateToProps)(WinCalculator)