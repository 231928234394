import React from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Heading,
  Image
} from 'grommet'

import SwitchClub from './SwitchClub'

const BookingsBar = ({
  activeUserOrg
}) => {
  const [image, setImage] = React.useState(null)

  React.useEffect(() => {
    if (activeUserOrg) {
      try {
        const img = require(`../../media/images/logos/${activeUserOrg.orgID}.gif`)?.default
        setImage(img)
      } catch (e) {
        console.warn('Club logo not found')
      }
    }
  }, [activeUserOrg])

  return (
    <Box
      align='center'
      direction='row'
    >
      <Box
        align='center'
        margin='xsmall'
      >
        <Image
          height='40em'
          width='40em'
          src={image}
        />
      </Box>
      <Heading
        level='2'
        margin={{ vertical: 'none' }}
      >
        {activeUserOrg?.orgNameLong}
      </Heading>
      <SwitchClub />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeUserOrg: state.login.activeUserOrg
})

export default connect(mapStateToProps)(BookingsBar)