import React from 'react'
import {
  Box,
  Text
} from 'grommet'

export default function Reporting() {
  return (
    <Box>
      <Text>Reporting</Text>
    </Box>
  )
}