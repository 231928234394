import React from 'react'
import { withRouter } from 'react-router'

import Banner from './Banner'
import BookingsBar from './BookingsBar'

const TopBar = ({ location }) => {
  const isBookingRoute = location.pathname.indexOf('bookings') >= 0
  return isBookingRoute ? <BookingsBar /> : <Banner />
}

export default withRouter(TopBar)