const COLUMNS = [
  {
    property: 'player',
    header: 'vs Player'
  }, {
    property: 'losses',
    header: '%L'
  }, {
    property: 'lossWins',
    header: '% Loss / % Wins'
  }, {
    property: 'wins',
    header: '%W'
  }, {
    property: 'winLoss',
    header: 'Wins & Losses'
  }, {
    property: 'details',
    header: 'Details'
  }
]

export default COLUMNS