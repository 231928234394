import React from 'react'
import {
  Box,
  Text
} from 'grommet'
import styled from 'styled-components'

import Header from '../components/Header'

const StyledList = styled.li`
  font-size: 0.85rem;
`

export default function Terms() {
  return (
    <Box>
      <Header title='Terms of Use' />
      <Box
        gap='small'
        margin={{ vertical: 'medium' }}
      >
        <Text size='small'>
          Real Tennis Online is a joint initiative of the governing bodies of real tennis in several countries.
        </Text>
        <Text size='small'>
          These organisations have pooled their resources to produce a website "engine" that provides shared, integrated features to various real tennis related websites around the world ("RTO websites"). Such websites contain the text "powered by RTO" at the bottom of every page.
        </Text>
        <Text size='small'>
          This agreement together with any additional terms, conditions, notices and disclaimers contained elsewhere on RTO websites form the conditions of supply of material on, use of and access to RTO websites.
        </Text>
        <Text size='small'>
          Your continued use of RTO websites constitutes an affirmative, acknowledgment and agreement by you that you accept and agree to abide and be bound by these conditions and any modifications.
        </Text>
        <Text size='small'>
          Should you object to any of these conditions or any subsequent modifications your only recourse is to immediately discontinue your use of RTO websites.
        </Text>
        <Text size='small' weight='bold'>
          1. Accuracy of information and disclaimer of warranties:
        </Text>
        <Text size='small'>
          All material on RTO websites is provided in good faith. It is derived from sources believed to be accurate and current as at the date of publication. Your use of RTO websites is at your sole risk. Access to RTO websites is provided on an 'as available' basis. RTO makes no representation or warranty that (i) any material on RTO websites will be reliable, accurate or complete (nor does it accept any responsibility arising in any way for errors in, or omissions from that material); or (ii) your access to RTO websites will be uninterrupted, timely or secure. RTO will not be liable for loss resulting from any action or decision by you in reliance on the information on RTO websites, nor any interruption, delay in operation or transmission, virus, communications failure, Internet access difficulties, or malfunction in equipment or software.
        </Text>
        <Text size='small' weight='bold'>
          2. Your conduct:
        </Text>
        <Text size='small'>
          As a condition of your use of RTO websites, you warrant to RTO that you will not use RTO websites for any purpose that is unlawful or prohibited by these terms of use. You agree to abide by all applicable laws and regulations.
        </Text>
        <Text size='small'>
          In particular, you agree not to:
        </Text>
        <ul>
          <StyledList>
            use RTO websites to defame, abuse, harass, stalk, threaten or otherwise offend others;
          </StyledList>
          <StyledList>
            publish, distribute, e-mail, transmit or disseminate any material which is unlawful, obscene, defamatory, indecent, offensive or inappropriate;
          </StyledList>
          <StyledList>
            use any automated scripting tools, HTML scrapers or other software to capture or copy data displayed on the website;
          </StyledList>
          <StyledList>
            engage in or promote any surveys, contests, pyramid schemes, chain letters, unsolicited e-mailing or spamming via RTO websites;
          </StyledList>
          <StyledList>
            impersonate any person or entity;
          </StyledList>
          <StyledList>
            upload, post, e-mail, transmit or otherwise make available using RTO websites any material that you do not have a right to make available under any law or contractual obligation or which contains viruses, or other computer codes, files or programs designed to interrupt, limit or destroy the functionality of other computer software or hardware;
          </StyledList>
          <StyledList>
            breach any applicable laws or regulations.
          </StyledList>
        </ul>
        <Text size='small'>
          You will indemnify RTO if RTO suffers any loss or damage or incur any costs in connection with any breach of these conditions or any other legal obligation by you or your use of or conduct on RTO websites.
        </Text>
        <Text size='small' weight='bold'>
          3. Third party links and advertising:
        </Text>
        <Text size='small'>
          Hyperlinks and pointers to websites operated by third parties will appear on RTO websites from time to time. These websites do not form part of RTO websites and are not under the control of RTO. RTO does not have any responsibility for the contents of any such hyperlink or linked website. If you link to any such websites you leave RTO websites and do so entirely at your own risk.
        </Text>
        <Text size='small' weight='bold'>
          4. Cancellation and termination
        </Text>
        <Text size='small'>
          To cancel or terminate this agreement you simply need to discontinue your use of RTO websites. You may need to contact any real tennis clubs or associations of which you are a member and request that they remove your details from the website.
        </Text>
        <Text size='small'>
          RTO, in its sole discretion, has the right to suspend or terminate your use of RTO websites and to refuse any and all current or future use of RTO websites, for any reason at any time. Such suspension or termination will result in the deactivation or deletion of your access to RTO websites.
        </Text>
        <Text size='small'>
          RTO reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the RTO websites (or any part thereof) with or without notice.
        </Text>
        <Text size='small' weight='bold'>
          5. Limitation of liability:
        </Text>
        <Text size='small'>
          RTO does not exclude any rights and remedies available to you under relevant legislation that cannot be excluded, restricted or modified. Otherwise RTO excludes all conditions and warranties that may be implied by law.
        </Text>
        <Text size='small'>
          You expressly agree and understand that in no circumstances will RTO be liable to you for any indirect, incidental, special and/or consequential losses or damages or loss of profits of whatever nature howsoever arising from use of RTO websites.
        </Text>
        <Text size='small' weight='bold'>
          6. Applicable law:
        </Text>
        <Text size='small'>
          These conditions and all other specific and additional terms which govern your use of and access to RTO websites will be governed by and construed in accordance with the laws of your home country.
        </Text>
        <Text size='small'>
          Last updated on 23 April 2018
        </Text>
      </Box>
    </Box>
  )
}