import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  Text,
  TextInput
} from 'grommet'
import { connect } from 'react-redux'

import { login, setError } from '../../ducks/login'
import DangerBanner from '../DangerBanner'
import FadeButton from './FadeButton'

const LoginForm = ({
  dispatch,
  error,
  setForgotPassword
}) => {
  const [value, setValue] = React.useState({ email: '', password: '' });
  const disableSubmit = !value.email.length || !value.password.length

  React.useEffect(() => {
    dispatch(setError(''))
  }, [dispatch])

  return (
    <Box align='center'>
      <Text alignSelf='start' margin={{ bottom: 'small' }} weight='bold'>
        Login
      </Text>
      <Text size='small'>
        Please sign-in below to access the full benefits of RTO using
        either your registered email address or RTO number.
      </Text>
      <Box
        margin={{ vertical: 'medium' }}
        width='medium'
      >
        <Form
          onChange={nextValue => setValue(nextValue)}
          onSubmit={({ value }) => dispatch(login(value.email, value.password))}
          value={value}
        >
          <FormField
            name='email'
            htmlFor='email'
            label='RTO email or number'
          >
            <TextInput id='email' name='email' pad='xsmall' value={value.email} />
          </FormField>
          <FormField
            name='password'
            htmlFor='password'
            label='Password'
          >
            <TextInput id='password' name='password' type='password' value={value.password} />
          </FormField>
          <FadeButton
            label={<Text size='small'>Forgot your password?</Text>}
            onClick={setForgotPassword}
            plain
          />
          {error && <DangerBanner message={error} />}
          <Box
            align='end'
            margin={{ top: 'small' }}
          >
            <Button
              disabled={disableSubmit}
              label='Sign In'
              type="submit"
            />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  error: state.login.error
})

export default connect(mapStateToProps)(LoginForm)