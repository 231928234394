import { combineReducers } from 'redux';

import bookingSheet from './bookingSheet'
import configuration from './configuration'
import country from './country'
import court from './court'
import handicap from './handicap'
import initialize from './initialize'
import login from './login'
import match from './match'
import player from './player'
import org from './org'

export default combineReducers({
  bookingSheet,
  configuration,
  country,
  court,
  handicap,
  initialize,
  login,
  match,
  player,
  org
});