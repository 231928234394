import React from 'react'
import {
  Box,
  DataTable,
  Pagination
} from 'grommet'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import COLUMNS from './columns'
import Header from '../../components/Header'
import Spinner from '../../components/Spinner'
import { clearActiveOrg, getOrgs } from '../../ducks/org'
import STATES from '../../helpers/async'

const Clubs = ({
  dispatch,
  orgs = [],
  orgsStatus,
  total
}) => {
  const [page, setPage] = React.useState(1)

  const history = useHistory()
  const changePage = (page) => {
    dispatch(getOrgs(page, '20'))
    setPage(page)
  }

  React.useEffect(() => {
    dispatch(clearActiveOrg())
    dispatch(getOrgs(1, '20'))
  }, [dispatch])

  return (
    <Box gap='medium'>
      <Header title='All Clubs &amp; Associations' />
      {orgsStatus === STATES.FETCHING ? (
        <Box align='center'>
          <Spinner label='Loading Clubs' />
        </Box>
      ) : (
        <Box gap='small'>
          <DataTable
            columns={COLUMNS}
            data={orgs}
            onClickRow={(row) => history.push(`/clubs/${row.datum.identity}`)}
          />
          <Pagination
            align='center'
            margin={{ top: 'small' }}
            numberItems={total}
            onChange={(num) => changePage(num.page)}
            page={page}
            size='small'
            step={20}
          />
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  countries: state.country.countries,
  orgs: state.org.orgs,
  orgsStatus: state.org.orgsStatus,
  page: state.org.page,
  total: state.org.total
})

export default connect(mapStateToProps)(Clubs)