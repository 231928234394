import React from 'react'
import { Route } from 'react-router-dom'
import { Box } from 'grommet'

import Countries from './Countries'
import Courts from './Courts'
import HandicapFlags from './HandicapFlags'
import SanctionedMatches from './SanctionedMatches'
import Settings from './Settings'

export default function Configuration({ match }) {
  return (
    <Box>
      <Route exact path={`${match.path}/`} component={Settings} />
      <Route path={`${match.path}/courts`} component={Courts} />
      <Route path={`${match.path}/countries`} component={Countries} />
      <Route path={`${match.path}/sanctioned-matches`} component={SanctionedMatches} />
      <Route path={`${match.path}/handicap-flags`} component={HandicapFlags} />
    </Box>
  )
}