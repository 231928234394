import { Auth } from 'aws-amplify'

import { getCountries } from './country';
import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'
import { getPlayerTypes } from './player';

const SET_ACTIVE_USER_ORG = 'SET_ACTIVE_USER_ORG'
const SET_USER_MEMBERSHIPS = 'SET_USER_MEMBERSHIPS'
const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
const SET_USER = 'SET_USER';

const initialState = {
  activeUserOrg: null,
  error: '',
  user: null,
  memberships: []
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return Object.assign({}, state, {
        user: action.user
      });

    case SET_ACTIVE_USER_ORG:
      return Object.assign({}, state, {
        activeUserOrg: action.activeUserOrg
      });

    case SET_USER_MEMBERSHIPS:
      return Object.assign({}, state, {
        activeUserOrg: action.activeUserOrg,
        memberships: action.memberships
      });

    case SET_LOGIN_ERROR:
      return Object.assign({}, state, {
        error: action.error
      });

    default:
      return state;
  }
}

const fetchUser = (id) => {
  return (dispatch) => {
    return rtoClient.get(`${VERBS.PERSON}/${id}`)
      .then(res => {
        const user = res.data
        dispatch({
          type: SET_USER,
          user
        })
      })
      .catch(err => {
        console.warn('User fetch error: ', err.message)
      })
  }
}

const getUserMemberships = () => {
  return (dispatch, getState) => {
    const user = getState().login.user
    dispatch({
      type: SET_USER_MEMBERSHIPS
    })
    if (!user || !user.identity) return
    return rtoClient.get(`${VERBS.PERSON}/${user.identity}/Memberships`)
      .then(res => {
        dispatch({
          type: SET_USER_MEMBERSHIPS,
          memberships: res.data,
          activeUserOrg: res.data.length ? res.data[0] : null
        })
      })
      .catch(err => {
        console.warn('Error fetching memberships: ', err.message)
      })
  }
}

const login = (username, password) => {
  return (dispatch) => {
    Auth.signIn(username, password)
      .then(async result => {
        const userId = await rtoClient.getBearerToken()
        if (userId) {
          dispatch(getCountries())
          dispatch(getPlayerTypes())
          dispatch(fetchUser(userId))
        }
      })
      .catch(err => {
        console.error(err)
        dispatch(setError(err.message))
      })
    return null
  }
}

const logout = () => {
  return (dispatch) => {
    Auth.signOut()
      .then(result => {
        dispatch({
          type: SET_USER,
          user: null
        })
      })
      .catch(err => {
        console.error(err)
      })
  }
}

const setActiveUserOrg = (org) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_USER_ORG,
      activeUserOrg: org
    })
  }
}

const setError = (err = '') => {
  return (dispatch) => {
    return dispatch({
      type: SET_LOGIN_ERROR,
      error: err
    })
  }
}

export default loginReducer;

export {
  fetchUser,
  getUserMemberships,
  login,
  logout,
  setActiveUserOrg,
  setError
};