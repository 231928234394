import React from 'react'
import {
  Box,
  DataTable,
  Layer,
  Text
} from 'grommet'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import EditButton from '../../../components/EditButton'
import Header from '../../../components/Header'
import NoneFound from '../../../components/NoneFound'
import PrimaryButton from '../../../components/PrimaryButton'
import EditCourt from './EditCourt'
import { getCourts } from '../../../ducks/court'

const getColumns = (countryObj, setActiveCourt) => {
  return [
    {
      property: 'nameLong',
      header: 'Court',
      render: (datum) => <EditButton label={datum.nameLong} onClick={() => setActiveCourt(datum)} plain />
    }, {
      property: 'nameShort',
      header: 'Code',
    }, {
      property: 'inPlay',
      header: 'In Play',
      render: (datum) => <Text>{datum.isInPlay ? 'Yes' : 'No'}</Text>
    }, {
      property: 'isDeleted',
      header: 'Deleted?',
      render: (datum) => datum.isDeleted ? <FontAwesomeIcon color='red' icon={faTimes} /> : null
    }, {
      property: 'country',
      header: 'Country',
      render: (datum) => <Text>{countryObj[datum.countryID]?.nameLong}</Text>
    }
  ]
}

const Courts = ({
  countries = [],
  courts = [],
  dispatch
}) => {
  const [activeCourt, setActiveCourt] = React.useState(null)
  const [editCourt, setEditCourt] = React.useState(false)
  const [countryObj, setCountryObj] = React.useState({})


  const onSetActiveCourt = (court) => {
    setActiveCourt(court)
    setEditCourt(true)
  }

  const onCloseModal = () => {
    setEditCourt(false)
    setActiveCourt(null)
  }

  React.useEffect(() => {
    dispatch(getCourts())

    const countriesObject = countries.reduce((acc, cur) => {
      acc[cur.identity] = cur
      return acc
    }, {})

    setCountryObj(countriesObject)
  }, [countries, dispatch])

  if (!courts.length) {
    return (
      <Box align='center'>
        <NoneFound message='No Courts found' />
      </Box>
    )
  }

  return (
    <Box>
      {editCourt && (
        <Layer onClickOutside={() => onCloseModal()}>
          <EditCourt
            courtToEdit={activeCourt}
            countries={countries}
            onClose={() => onCloseModal()}
          />
        </Layer>
      )}
      <Box gap='small'>
        <Header title='All Courts' />
        <DataTable
          columns={getColumns(countryObj, onSetActiveCourt)}
          data={courts}
          onClickRow={e => onSetActiveCourt(e.datum)}
        />
        <Box
          align='center'
          margin={{ top: 'small' }}
        >
          <PrimaryButton
            label='Create Court'
            onClick={() => setEditCourt(!editCourt)}
          />
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  countries: state.country.countries,
  courts: state.court.courts
})

export default connect(mapStateToProps)(Courts)