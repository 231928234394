import React from 'react'
import {
  Box,
  Text
} from 'grommet'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import CommonAnchor from '../CommonAnchor'
import isActiveRoute from '../../helpers/isActiveRoute'

const DesktopView = styled(Box)`
  @media (max-width: 815px) {
    display: none;
  }
`

const SubNav = ({
  id,
  label,
  location,
  requiresId = false,
  root,
  routes = []
}) => {
  if (!label && requiresId) return null

  return (
    <DesktopView
      direction='row'
      fill
      justify='between'
      wrap
    >
      <Text margin={{ right: 'small' }}>{label}</Text>
      <Box
        align='center'
        direction='row'
        gap='xsmall'
        wrap
      >
        {routes.map((route, i) => {
          const url = id ? `${root}/${id}${route.href}` : `${root}${route.href}`
          const isActive = isActiveRoute(location, route)
          return (
            <CommonAnchor
              key={`SUB_${i}`}
              isActive={isActive}
              label={route.label}
              url={url}
            />
          )
        })}
      </Box>
    </DesktopView>
  )
}

export default withRouter(SubNav)