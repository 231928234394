const MATCH_TYPES = [
  { label: 'Handicap', value: 'H' },
  { label: 'Level', value: 'L' },
]

export const WEIGHT_TYPES = [
  { label: 'Social/Friendly', value: 'S' },
  { label: 'Competative', value: 'C' },
  { label: 'Competitive (IHSC Sanctioned)', value: 'X' },
  { label: 'World Championship', value: 'W' },
]

export default MATCH_TYPES