import React from 'react'
import {
  Box,
  Button,
  DataTable,
  Layer,
} from 'grommet'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import EditButton from '../../../components/EditButton'
import Header from '../../../components/Header'
import NoneFound from '../../../components/NoneFound'
import EditCountry from './EditCountry'

const getColumns = (setActiveCountry) => {
  return [
    {
      property: 'nameLong',
      header: 'Country',
      render: (datum) => <EditButton label={datum.nameLong} onClick={() => setActiveCountry(datum)} plain />
    }, {
      property: 'isDeleted',
      header: 'Deleted?',
      render: (datum) => datum.isDeleted ? <FontAwesomeIcon color='red' icon={faTimes} /> : null
    }, {
      property: 'nameShort',
      header: 'Code'
    }
  ]
}


const Countries = ({
  countries = []
}) => {
  const [showEdit, setShowEdit] = React.useState(false)
  const [activeCountry, setActiveCountry] = React.useState(null)

  if (!countries.length) {
    return (
      <Box align='center'>
        <NoneFound message='No Countries found' />
      </Box>
    )
  }

  const onSetActiveCountry = (country) => {
    setActiveCountry(country)
    setShowEdit(true)
  }

  const onClose = () => {
    setShowEdit(false)
    setActiveCountry(null)
  }

  return (
    <Box>
      {showEdit && (
        <Layer onClickOutside={() => setShowEdit(false)}>
          <EditCountry
            activeCountry={activeCountry}
            onClose={onClose}
          />
        </Layer>
      )}
      <Box gap='small'>
        <Header title='All Countries' />
        <DataTable
          columns={getColumns((country) => onSetActiveCountry(country))}
          data={countries}
          onClickRow={e => onSetActiveCountry(e.datum)}
        />
        <Box align='center'>
          <Button
            label='Create Country'
            onClick={() => setShowEdit(!showEdit)}
          />
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  countries: state.country.countries
})

export default connect(mapStateToProps)(Countries)