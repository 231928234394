import React from 'react'
import { Box } from 'grommet'

import ConfirmationCodeForm from './ConfirmationCodeForm'
import ForgotEmailForm from './ForgotEmailForm'

const ForgotEmail = ({
  onResetSuccess,
  returnToLogin
}) => {
  const [enterCredentials, setEnterCredentials] = React.useState(false)
  const [username, setUsername] = React.useState('')

  const showCredentialsForm = (name) => {
    setEnterCredentials(true)
    setUsername(name)
  }

  return (
    <Box>
      {enterCredentials ? (
        <ConfirmationCodeForm
          onResetSuccess={onResetSuccess}
          returnToLogin={returnToLogin}
          username={username}
        />
      ) : (
        <ForgotEmailForm
          returnToLogin={returnToLogin}
          setEnterCredentials={showCredentialsForm}
        />
      )}
    </Box>
  )
}

export default ForgotEmail