import React from 'react'
import {
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  Text,
  TextInput
} from 'grommet'
import { connect } from 'react-redux'
import styled from 'styled-components'
import 'react-calendar/dist/Calendar.css';

import DangerBanner from '../../components/DangerBanner'
import SuccessBanner from '../../components/SuccessBanner'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'
import {
  // CLUBS,
  OCCUPATIONS,
  SCHOOLS
} from './content'
import DateSelect from '../../components/DateSelect'
import FieldRow from '../../components/FieldRow'
import Header from '../../components/Header'
import ResponsiveBox from '../../components/ResponsiveBox'
import SelectMenu from '../../components/SelectMenu'
import Spinner from '../../components/Spinner'
import StyledFormField from '../../components/StyledFormField'
import { getMemberships } from '../../ducks/player'

const StyledButton = styled(Button)`
  background: #f4f4df;
  color: black;
`

const StyledField = styled(FormField)`
  > div {
    border: 0;
  }
`

const OverflowText = styled(Text)`
  overflow-wrap: anywhere;
`

const GENDER_OPTIONS = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' }
]

const hundredYearsAgo = new Date()
hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);

const PersonalDetails = ({
  countries,
  dispatch,
  memberships,
  user = {}
}) => {
  const [values, setValues] = React.useState(user)
  const [other, setOther] = React.useState('')
  const [birthday, setBirthday] = React.useState()
  const [school, setSchool] = React.useState('')
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const otherSelected = school === 'Other'

  React.useEffect(() => {
    if (user) {
      if (user.dateOfBirth) {
        setBirthday(new Date(user.dateOfBirth.replaceAll('-', '/')))
      }
      dispatch(getMemberships())
      setValues(user)
    }
  }, [dispatch, user])

  React.useEffect(() => {
    const userSchool = values && values.school

    if (!userSchool) return
    const isKnownSchool = SCHOOLS.some(school => school === userSchool)

    if (!isKnownSchool) {
      const vals = Object.assign(user, { school: 'Other' })
      setValues(vals)
      setSchool('Other')
      setOther(userSchool)
    }
  }, [user, values])

  const updatePlayer = () => {
    let selectedSchool = otherSelected ? other : values.school
    const params = Object.assign(values, { school: selectedSchool, dateOfBirth: birthday })
    rtoClient.put(`${VERBS.PERSON}/${values.personID}`, params)
      .then((res) => {
        setSuccess(true)
        setError(false)
      })
      .catch(err => {
        setSuccess(false)
        setError(true)
      })
  }

  if (!values) {
    return (
      <Box align='center'>
        <Spinner label='Loading Player' />
      </Box>
    )
  }

  return (
    <Box>
      <Header title='Personal Details' />
      <Box align='center'>
        <Form
          onChange={nextValue => setValues(nextValue)}
          onSubmit={() => updatePlayer()}
          value={values}
        >
          <Box
            gap='small'
            margin={{ top: 'medium' }}
          >
            <ResponsiveBox gap='small'>
              <FieldRow
                label='Prefix'
                id='namePrefix'
                name='namePrefix'
                value={values.namePrefix}
              />
              <FieldRow
                id='nameInitials'
                label='Full Iniitial(s)'
                name='nameInitials'
                value={values.nameInitials}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='nameFirst'
                label='Preferred name'
                name='nameFirst'
                value={values.nameFirst}
              />
              <FieldRow
                id='nameLast'
                label='Last name'
                name='nameLast'
                value={values.nameLast}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='nameSuffix'
                label='Suffix'
                name='nameSuffix'
                value={values.nameSuffix}
              />
              <FieldRow
                id='nameTag'
                label='Tag'
                name='nameTag'
                value={values.nameTag}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <SelectMenu
                id="gender"
                items={GENDER_OPTIONS}
                label='Gender'
                labelKey="label"
                name="gender"
                selection={values.gender || ''}
                valueKey={{ key: 'value', reduce: true }}
              />
              <DateSelect
                date={birthday}
                label='Date of Birth'
                name='dateOfBirth'
                setDate={(date) => setBirthday(date)}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='email'
                label='Email address'
                name='email'
                value={values.email}
              />
              {memberships.length > 0 && (
                <SelectMenu
                  id="primaryOrgID"
                  items={memberships}
                  label='Primary Org'
                  labelKey="orgNameLong"
                  name="primaryOrgID"
                  selection={values.primaryOrgID || ''}
                  valueKey={{ key: 'orgID', reduce: true }}
                />
              )}
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='twitterID'
                label='Twitter ID'
                name='twitterID'
                value={values.twitterID}
              />
              <FieldRow
                id='address'
                label='Postal Address'
                name='address'
                value={values.address}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='city'
                label='Town/City/Suburb'
                name='city'
                value={values.city}
              />
              <FieldRow
                id='state'
                label='State/Province/Region'
                name='state'
                value={values.state}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='postalCode'
                label='Postal Code'
                name='postalCode'
                value={values.postalCode}
              />
              <SelectMenu
                id='residenceCountryID'
                label='Country of Residence'
                name='residenceCountryID'
                items={countries}
                labelKey={(e) => e.nameLong}
                selection={values.residenceCountryID}
                valueKey={{ key: 'identity', reduce: true }}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <SelectMenu
                id='representsCountryID'
                label='Country'
                name='representsCountryID'
                items={countries}
                labelKey={(e) => e.nameLong}
                selection={values.representsCountryID}
                valueKey={{ key: 'identity', reduce: true }}
              />
              <FieldRow
                id='phoneHome'
                label='Home phone'
                name='phoneHome'
                value={values.phoneHome}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='phoneBusiness'
                label='Business phone'
                name='phoneBusiness'
                value={values.phoneBusiness}
              />
              <FieldRow
                id='phoneMobile'
                label='Mobile phone'
                name='phoneMobile'
                value={values.phoneMobile}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <SelectMenu
                id='occupation'
                label='Occupation'
                name='occupation'
                items={OCCUPATIONS}
                selection={values.occupation}
              />
              <FieldRow
                id='university'
                label='University'
                name='university'
                value={values.university}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <SelectMenu
                id='school'
                label='School'
                name='school'
                onChange={(e) => setSchool(e.value)}
                items={SCHOOLS}
                selection={values.school}
              />
              <StyledFormField
                disabled={!otherSelected}
                htmlFor='other'
                label={<OverflowText size='small'>If 'Other', specify</OverflowText>}
              >
                <TextInput
                  disabled={!otherSelected}
                  id='other'
                  onChange={e => setOther(e.target.value)}
                  size='small'
                  value={other}
                />
              </StyledFormField>
            </ResponsiveBox>
            {/* <ResponsiveBox>
            <SelectMenu
              label='Primary (Billing) Club'
              name='primaryClub'
              items={CLUBS}
              selection={values.club}
            />
          </ResponsiveBox> */}
          </Box>
          <Box
            justify='center'
            direction='row'
            gap='medium'
            margin={{ top: 'medium' }}
          >
            <StyledField name='isTennisPlayer'>
              <CheckBox
                label={<Text size='small'>Tennis Player</Text>}
                name='isTennisPlayer'
              />
            </StyledField>
            <StyledField name='isRacketsPlayer'>
              <CheckBox
                label={<Text size='small'>Rackets Player</Text>}
                name='isRacketsPlayer'
              />
            </StyledField>
            <StyledField name='isStudent'>
              <CheckBox
                label={<Text size='small'>Student</Text>}
                name='isStudent'
              />
            </StyledField>
          </Box>
          {success && <SuccessBanner message='Profile Saved Successfully' />}
          {error && <DangerBanner message='Oops! Please try saving again' />}
          <Box
            align='center'
            gap='small'
          >
            <StyledButton
              label='Save'
              primary
              type='submit'
            />
            <Text size='small'>
              <strong>Note:</strong> This person cannot be deleted from the database while active memberships exist.
            </Text>
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  countries: state.country.countries,
  memberships: state.player.memberships,
  user: state.player.activePlayer
})

export default connect(mapStateToProps)(PersonalDetails)