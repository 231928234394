import React from 'react'
import { Box } from 'grommet'

import Header from '../components/Header'

export default function Privacy() {
  return (
    <Box>
      <Header title='Privacy' />
    </Box>
  )
}