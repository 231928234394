import React from 'react'
import {
  Box,
  Text
} from 'grommet'
import { string } from 'prop-types'
import styled from 'styled-components'

const BoldText = styled(Text)`
  font-weight: bold;
`

export default function Row({
  label,
  value
}) {
  return (
    <Box
      align='center'
      direction='row'
      gap='small'
      fill
      margin={{ right: 'small' }}
    >
      <Box basis='1/3'>
        <BoldText size='small'>{label}</BoldText>
      </Box>
      <Box
        basis='2/3'
        border
        pad='xsmall'
      >
        <Text size='small'>{value}</Text>
      </Box>
    </Box>
  )
}

Row.propTypes = {
  label: string,
  value: string
}
