import { Text } from 'grommet'

const COLUMNS = [
  {
    property: 'nameRTO',
    header: 'RTO Name',
    render: datum => <Text size='small'>{datum.nameRTO}</Text>
  }, {
    property: 'nameLong',
    header: 'Full Name',
    render: datum => <Text size='small'>{datum.nameLong}</Text>
  }, {
    property: 'type',
    header: 'Type',
    render: datum => <Text size='small'>{datum.type}</Text>
  }, {
    property: 'country',
    header: 'Country',
    render: datum => <Text size='small'>{datum.country}</Text>
  }, {
    property: 'lastMatch',
    header: 'Last Match',
    render: datum => <Text size='small'>{datum.lastMatch}</Text>
  }, {
    property: 'matches',
    header: '# Matches (30 Days)',
    render: datum => <Text size='small'>{datum.matches}</Text>,
    size: 'xsmall',
  },
]

export default COLUMNS