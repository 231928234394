import {
  Text,
  TextInput
} from 'grommet'
import styled from 'styled-components'
import StyledFormField from './StyledFormField'

const OverflowText = styled(Text)`
  overflow-wrap: anywhere;
`

const FieldRow = ({
  disabled = false,
  id,
  label,
  min,
  name,
  step,
  type = 'text',
  value = '',
  validate,
}) => {
  return (
    <StyledFormField
      disabled={disabled}
      htmlFor={id}
      label={<OverflowText size='small'>{label}</OverflowText>}
      name={name}
      validate={validate}
    >
      <TextInput
        disabled={disabled}
        id={id}
        min={min}
        name={name}
        size='small'
        step={step}
        type={type}
        value={value || ''}
      />
    </StyledFormField>
  )
};

export default FieldRow