import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  Text,
  TextInput
} from 'grommet'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import FadeButton from './FadeButton'
import DangerBanner from '../DangerBanner'

const DEFAULT = { username: '' }

const ForgotEmailForm = ({
  returnToLogin,
  setEnterCredentials
}) => {
  const [error, setError] = React.useState(null)
  const [value, setValue] = React.useState(DEFAULT);

  React.useEffect(() => {
    setError(false)
  }, [])

  const forgotPassword = () => {
    Auth.forgotPassword(value.username)
      .then(data => {
        setEnterCredentials(value.username)
        setError(false)
      })
      .catch(err => setError(true));
  }

  const disableForgot = !value.username.length

  return (
    <Box>
      <Text margin={{ bottom: 'small' }} weight='bold'>
        Forgot Password?
      </Text>
      <Box align='center'>
        <Text size='small'>
          Enter your username to receive an email with password reset instructions
        </Text>
        <Box
          margin={{ vertical: 'medium' }}
          width='medium'
        >
          <Form
            onChange={nextValue => setValue(nextValue)}
            onSubmit={() => forgotPassword()}
          >
            <FormField
              name='username'
              htmlFor='username'
              label='RTO email or number'
            >
              <TextInput
                id='username'
                name='username'
                pad='xsmall'
                value={value.username}
              />
            </FormField>
            <FadeButton
              label={<Text size='small'>Return to Login</Text>}
              onClick={returnToLogin}
              plain
            />
            {error && (
              <DangerBanner message='Oops, something went wrong. Please try again.' />
            )}
            <Box
              align='end'
              margin={{ top: 'small' }}
            >
              <Button
                disabled={disableForgot}
                label='Get Code'
                type="submit"
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </Box>
  )
}

export default connect()(ForgotEmailForm)