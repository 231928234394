import React from 'react'
import {
  Anchor,
  Box,
  Text
} from 'grommet'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledAnchor = styled(Anchor)`
  background: ${props => props.background};
  height: 100%;
`

const StyledLink = styled(Link)`
  align-items: center;
  color: ${props => props.color};
  display: flex;
  height: 100%;
  outline: none;
  text-decoration: none;
  transition: 0.25s;
  
  &:hover, &:focus {
    background: ${props => props.activebackground};
    color: ${props => props.activecolor};
  }
`

const NavAnchor = ({
  href,
  isActive = false,
  isExternal,
  label,
  margin = 'small',
  onClick,
  state = {}
}) => {
  const background = isActive ? state.activeBackground : state.inactiveBackground
  const color = isActive ? state.activeColor : state.inactiveColor

  return (
    <Box>
      <StyledAnchor
        as='span'
        background={background}
        onClick={onClick}
        size='xsmall'
      >
        {isExternal ? (
          <StyledLink
            activebackground={state.activeBackground}
            activecolor={state.activeColor}
            color={color}
            isactive={isActive.toString()}
            target='_blank'
            to={{ pathname: href }}
          >
            <Text
              margin={margin}
              size='small'
            >
              {label}
            </Text>
          </StyledLink>
        ) : (
          <StyledLink
            activebackground={state.activeBackground}
            activecolor={state.activeColor}
            color={color}
            isactive={isActive.toString()}
            to={href}
          >
            <Text
              margin={margin}
              size='small'
            >
              {label}
            </Text>
          </StyledLink>
        )}
      </StyledAnchor >
    </Box>
  )
}

export default NavAnchor