import React from 'react'
import { Box } from 'grommet'
import { string } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import ItalicText from './ItalicText'

export default function NoneFound({
  action = null,
  margin = 'none',
  message = '',
  size = 'medium'
}) {
  return (
    <Box
      align='center'
      direction='row'
      gap='xsmall'
      margin={margin}
    >
      <FontAwesomeIcon
        color='#fda336'
        icon={faInfoCircle}
      />
      <ItalicText size={size}>{message} {action && action}</ItalicText>
    </Box>
  )
}

NoneFound.propTypes = {
  message: string
}