import React from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Button,
  CheckBox,
  DropButton,
  Form,
  FormField,
  Select,
  Text
} from 'grommet'
import Calendar from 'react-calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import DangerButton from '../../components/DangerButton'
import Modal from '../../components/Modal'
import PlayerSelect from '../../components/PlayerSelect'
import Spinner from '../../components/Spinner'
import { getMemberships } from '../../ducks/player'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'

const DEFAULT = {
  isDeleted: false,
  isPrimary: false,
  orgCategoryCode: '',
  orgSourceCode: '',
  proposerPersonID: null,
  receiveAnnualReport: false,
  receivedFreeLessons: false,
  receiveNewsletter: false,
  seconderPersonID: null
}

const StyledBox = styled(Box)`
  display: block;
  overflow: auto;
`

const StyledFormField = styled(FormField)`
  > div {
    border: 0;
    padding: 0.2em;
  }
`

const MEMBER_CODE = 'M'
const SOURCE_CODE = 'S'

const NewMembershipModal = ({
  activeMembership,
  closeModal,
  dispatch,
  orgID,
  orgName,
  user
}) => {
  const [endDate, setEndDate] = React.useState()
  const [isLoading, setIsLoading] = React.useState(true)
  const [memberTypes, setMemberTypes] = React.useState([])
  const [openCal, setOpenCal] = React.useState(false)
  const [openEndCal, setOpenEndCal] = React.useState(false)
  const [proposer, setProposer] = React.useState()
  const [seconder, setSeconder] = React.useState()
  const [sourceTypes, setSourceTypes] = React.useState([])
  const [startDate, setStartDate] = React.useState(new Date())
  const [values, setValues] = React.useState(DEFAULT)

  const parseMemberCats = (cats) => {
    const sourceTypes = []
    const memberTypes = []
    cats.forEach(cat => {
      if (cat.orgCategoryType === MEMBER_CODE) {
        memberTypes.push(cat)
      } else if (cat.orgCategoryType === SOURCE_CODE) {
        sourceTypes.push(cat)
      }
    })
    setMemberTypes(memberTypes)
    setSourceTypes(sourceTypes)
    setIsLoading(false)
  }

  const onSubmit = (deleting = false) => {
    const proposer = values.proposerPersonID?.identity || null
    const seconder = values.seconderPersonID?.identity || null

    const params = {
      isDeleted: deleting,
      personID: user.personID,
      orgID,
      orgCategoryCode: values.orgCategoryCode || null,
      startDate: startDate?.toJSON() || null,
      endDate: endDate?.toJSON() || null,
      orgSourceCode: values.orgSourceCode || null,
      proposerPersonID: proposer,
      seconderPersonID: seconder,
      receiveAnnualReport: values.receiveAnnualReport,
      receiveNewsletter: values.receiveNewsletter,
      receivedFreeLessons: values.receivedFreeLessons
    }

    if (!!activeMembership) {
      rtoClient.put(`${VERBS.ORG_MEMBER}/${activeMembership.personOrgID}`, params)
        .then(res => {
          dispatch(getMemberships())
          closeModal(false)
        })
        .catch(err => {
          console.warn(err)
          closeModal(false)
        })
    } else {
      rtoClient.post(`${VERBS.ORG_MEMBER}`, params)
        .then(res => {
          dispatch(getMemberships())
          closeModal(false)
        })
        .catch(err => {
          console.warn(err)
          closeModal(false)
        })
    }
  }

  React.useEffect(() => {
    if (!!activeMembership) {
      rtoClient.get(`${VERBS.ORG_MEMBER}/${activeMembership.personOrgID}`)
        .then(res => {
          setValues(res.data)
          if (res.data?.startDate) {
            const newStartDate = res.data?.startDate?.replaceAll('-', '/')
            const newEndDate = res.data?.endDate?.replaceAll('-', '/')
            newStartDate && setStartDate(new Date(newStartDate))
            newEndDate && setEndDate(new Date(newEndDate))
          }
          if (res.data?.proposerPersonID) {
            rtoClient.get(`${VERBS.PERSON}/${res.data.proposerPersonID}`)
              .then(res => {
                if (res && res.data) {
                  setProposer(res.data)
                }
              })
          }
          if (res.data?.seconderPersonID) {
            rtoClient.get(`${VERBS.PERSON}/${res.data.seconderPersonID}`)
              .then(res => {
                if (res && res.data) {
                  setSeconder(res.data)
                }
              })
          }
        })
        .catch(err => console.warn(err))
    }

    rtoClient.get(`${VERBS.ORG}/${orgID}/Categories`, { pageSize: 50 })
      .then(res => parseMemberCats(res.data?.items || []))
      .catch(err => console.warn(err))
  }, [activeMembership, orgID])

  const action = !!activeMembership ? 'Edit' : 'New'

  return (
    <StyledBox>
      <Modal
        gap='small'
        label={`${action} Membership`}
        margin='medium'
        onClose={closeModal}
      >
        {isLoading ? (
          <Box align='center'>
            <Spinner label='Loading Content' />
          </Box>
        ) : (
          <Form
            onChange={(next) => setValues(next)}
            onSubmit={() => onSubmit()}
          >
            <Box gap='xsmall'>
              {!activeMembership && (
                <Text size='small'>Add Membership for {orgName}</Text>
              )}
              {(memberTypes.length > 0) && (
                <FormField
                  htmlFor='orgCategoryCode__input'
                  label='Membership Category'
                  margin='none'
                  name='orgCategoryCode'
                >
                  <Select
                    id='orgCategoryCode'
                    labelKey={(e) => e.description}
                    name='orgCategoryCode'
                    options={memberTypes}
                    size='small'
                    value={values.orgCategoryCode}
                    valueKey={{ key: 'orgCategoryCode', reduce: true }}
                  />
                </FormField>
              )}
              {(sourceTypes.length > 0) && (
                <FormField
                  htmlFor='orgSourceCode__input'
                  label='Source'
                  margin='none'
                  name='orgSourceCode'
                >
                  <Select
                    id='orgSourceCode'
                    labelKey={(e) => e.description}
                    name='orgSourceCode'
                    options={sourceTypes}
                    size='small'
                    value={values.orgSourceCode}
                    valueKey={{ key: 'orgCategoryCode', reduce: true }}
                  />
                </FormField>
              )}
              <FormField
                label={activeMembership ? 'Date Joined' : 'Start Date'}
                margin='none'
                name='startDate'
              >
                <DropButton
                  label={
                    <Box
                      align='center'
                      justify='between'
                      direction='row'
                    >
                      <Text weight={100} size='small'>{startDate && new Date(startDate).toDateString()}</Text>
                      <FontAwesomeIcon icon={faCalendar} />
                    </Box>
                  }
                  dropAlign={{ top: 'bottom', right: 'right' }}
                  dropContent={
                    <Calendar
                      onChange={(newDate) => {
                        setStartDate(newDate)
                        setOpenCal(false)
                      }}
                      value={startDate}
                    />
                  }
                  margin='0.25em'
                  onClose={() => setOpenCal(false)}
                  onOpen={() => setOpenCal(true)}
                  open={openCal}
                  plain
                />
              </FormField>
              <FormField
                htmlFor='proposerPersonID__input'
                label='Proposed By'
                margin='none'
                name='proposerPersonID'
              >
                <PlayerSelect
                  id='proposerPersonID'
                  onChange={(e) => setProposer(e.value)}
                  value={proposer}
                />
              </FormField>
              <FormField
                htmlFor='seconderPersonID__input'
                label='Seconded By'
                margin='none'
                name='seconderPersonID'
              >
                <PlayerSelect
                  id='seconderPersonID'
                  onChange={(e) => setSeconder(e.value)}
                  value={seconder}
                />
              </FormField>
              {!!activeMembership && (
                <FormField
                  label='End Date'
                  margin='none'
                  name='endDate'
                >
                  <DropButton
                    label={
                      <Box
                        align='center'
                        justify='between'
                        direction='row'
                      >
                        <Text weight={100} size='small'>{!!endDate && new Date(endDate).toDateString()}</Text>
                        <FontAwesomeIcon icon={faCalendar} />
                      </Box>
                    }
                    dropAlign={{ top: 'bottom', right: 'right' }}
                    dropContent={
                      <Calendar
                        onChange={(newDate) => {
                          setEndDate(newDate)
                          setOpenEndCal(false)
                        }}
                        value={endDate}
                      />
                    }
                    margin='0.25em'
                    onClose={() => setOpenEndCal(false)}
                    onOpen={() => setOpenEndCal(true)}
                    open={openEndCal}
                    plain
                  />
                </FormField>
              )}
              <Box margin={{ vertical: 'small' }}>
                <StyledFormField
                  margin='none'
                  name='receivedFreeLessons'
                >
                  <CheckBox
                    checked={values.receivedFreeLessons}
                    label={<Text size='small'>Free lessons taken?</Text>}
                    name='receivedFreeLessons'
                  />
                </StyledFormField>
                <StyledFormField
                  margin='none'
                  name='receiveAnnualReport'
                >
                  <CheckBox
                    checked={values.receiveAnnualReport}
                    label={<Text size='small'>Receives annual report?</Text>}
                    name='receiveAnnualReport'
                  />
                </StyledFormField>
                <StyledFormField
                  margin='none'
                  name='receiveNewsletter'
                >
                  <CheckBox
                    checked={values.receiveNewsletter}
                    label={<Text size='small'>Receives newsletter?</Text>}
                    name='receiveNewsletter'
                  />
                </StyledFormField>
              </Box>
            </Box>
            <Box
              direction='row'
              gap='small'
              justify='center'
              margin={{ top: 'small' }}
            >
              <Button
                label='Save'
                type='submit'
              />
              {!!activeMembership && (
                <DangerButton
                  label='Delete'
                  onClick={() => onSubmit(true)}
                />
              )}
            </Box>
          </Form>
        )}
      </Modal>
    </StyledBox>
  )
}

export default connect()(NewMembershipModal)