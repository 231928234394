import React from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Select,
  Text
} from 'grommet'

import { getUserMemberships, setActiveUserOrg } from '../../ducks/login'

const SwitchClub = ({
  activeUserOrg,
  dispatch,
  memberships = []
}) => {
  React.useEffect(() => {
    dispatch(getUserMemberships())
  }, [dispatch])

  return (
    <Box
      align='center'
      direction='row'
      gap='small'
      margin={{ left: 'auto' }}
      pad='xsmall'
    >
      <Text
        size='small'
        weight='bold'
      >
        Switch Club
      </Text>
      <Box
        margin={{ left: 'auto' }}
      >
        <Select
          labelKey={e => e.orgNameLong}
          onChange={e => dispatch(setActiveUserOrg(e.value))}
          options={memberships}
          size='small'
          value={activeUserOrg}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeUserOrg: state.login.activeUserOrg,
  memberships: state.login.memberships,
  user: state.login.user
})

export default connect(mapStateToProps)(SwitchClub)