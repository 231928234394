import React from 'react'
import {
  Anchor,
  Box,
  DataTable,
  Text
} from 'grommet'

const COLUMNS = [
  {
    property: 'identity',
    header: 'Match',
    primary: true,
    render: datum => <Anchor href={`/matches/match/${datum.identity}`}>{datum.identity}</Anchor>
  }, {
    property: 'matchDate',
    header: 'Date',
    render: datum => <Text size='small'>{datum.matchDate}</Text>
  }, {
    property: 'hl',
    header: 'H/L',
    render: datum => <Text size='small'>{datum.hl}</Text>
  }, {
    property: 'players',
    header: 'Player(s)',
    render: datum => {
      return (
        <Box>
          {datum.players?.map((player) => {
            return <Text size='small'>{player}</Text>
          })}
        </Box>
      )
    }
  }, {
    property: 'df',
    header: '',
    render: datum => <Text size='small'>{datum.df}</Text>
  }, {
    property: 'opponents',
    header: 'Opponent(s)',
    render: datum => {
      return (
        <Box>
          {datum.opponents?.map((opponent) => {
            return <Text size='small'>{opponent}</Text>
          })}
        </Box>
      )
    }
  }, {
    property: 'score',
    header: 'Score',
    render: datum => <Text size='small'>{datum.score}</Text>
  }
]

export default function MatchTable({
  matches
}) {
  return (
    <DataTable
      columns={COLUMNS}
      data={matches}
    />
  )
}