import React from 'react'
import { connect } from 'react-redux'
import { Box } from 'grommet'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import {
  endOfWeek,
  startOfWeek
} from 'date-fns'

import { initializeBookings } from '../../../ducks/bookingSheet'

const TABLET_WIDTH = 768

const Sheet = ({
  activeCourt,
  activeUserOrg,
  courts = [],
  dispatch,
  matches = []
}) => {
  const [isDayView, setDayView] = React.useState(false)

  const calendarRef = React.useRef(null)

  React.useEffect(() => {
    if (activeUserOrg) {
      const today = new Date()
      const [startDate, endDate] = [startOfWeek(today), endOfWeek(today)]
      dispatch(initializeBookings(activeUserOrg.orgID, startDate.toJSON(), endDate.toJSON()))
    }
  }, [activeUserOrg, dispatch])

  React.useEffect(() => {
    const toggleView = () => {
      const shouldShowDayView = window.innerWidth <= TABLET_WIDTH;
      const calendarApi = calendarRef && calendarRef.current && calendarRef.current.getApi()
      if (shouldShowDayView && !isDayView && calendarApi) {
        setDayView(true)
        calendarApi.changeView('timeGridDay')
      } else if (!shouldShowDayView && isDayView && calendarApi) {
        setDayView(false)
        calendarApi.changeView('timeGridWeek')
      }
    }

    window.addEventListener('resize', toggleView)
    toggleView()

    return () => window.removeEventListener('resize', toggleView)
  }, [
    calendarRef,
    isDayView
  ])

  return (
    <Box>
      <FullCalendar
        allDaySlot={false}
        contentHeight='auto'
        plugins={[timeGridPlugin]}
        initialView='timeGridWeek'
        nowIndicator
        ref={calendarRef}
        slotDuration='00:45:00'
        slotMinTime='06:00:00'
        slotMaxTime='18:00:00'
      />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeCourt: state.bookingSheet.activeCourt,
  activeUserOrg: state.login.activeUserOrg,
  courts: state.bookingSheet.courts,
  matches: state.bookingSheet.matches
})

export default connect(mapStateToProps)(Sheet)