const IDS = {
  ORG: 'ORG',
  PERSON: 'PERSON'
}

const bookingRoutes = [
  {
    isBooking: true,
    label: 'Booking Sheet',
    href: '/bookings/sheet',
    subRoutes: [
      { label: 'Add a Booking', href: '/add-booking' },
      { label: 'Add a Match', href: '/add-match' },
      { label: 'Add an Event', href: '/add-event' },
      { label: 'Add Maintenance Time', href: '/maintenance' },
      { label: 'Export Booking Information', href: '/export-bookings' }
    ]
  }, {
    isBooking: true,
    label: 'Bookings',
    href: '/bookings/my-bookings',
    subRoutes: []
  }, {
    isBooking: true,
    label: 'Contact List',
    href: '/bookings/contact-list',
    subRoutes: []
  }, {
    isBooking: true,
    label: 'Find a Game',
    href: '/bookings/find-a-game',
    subRoutes: []
  }, {
    isBooking: true,
    label: 'Reporting',
    href: '/bookings/reporting',
    subRoutes: [
      {
        label: 'Monthly Statements'
      }, {
        label: 'System Usage'
      }
    ]
  }, {
    isBooking: true,
    label: 'Admin',
    href: '/bookings/admin',
    subRoutes: [
      {
        label: 'System Admin'
      }, {
        label: 'Club Details'
      }, {
        label: 'Court Admin'
      }, {
        label: 'User Admin'
      }, {
        label: 'Club Wide Messages'
      }, {
        label: 'Input Match Scores'
      }, {
        label: 'Set Opening Balances'
      }, {
        label: 'Update Your Player Databse'
      }, {
        label: 'Manage Pro Availability'
      }, {
        label: 'Nominal Codes'
      }
    ]
  }, {
    isBooking: true,
    isExternal: true,
    label: 'T&RA Home',
    href: 'https://www.tennisandrackets.com',
    subRoutes: []
  }, {
    background: 'olive',
    label: 'Home',
    href: '/',
    subRoutes: []
  }
]

const mainRoutes = [
  {
    label: 'Home',
    href: '/',
    subRoutes: []
  }, {
    label: 'People',
    href: '/people',
    id: IDS.PERSON,
    requiresId: true,
    subRoutes: [
      { label: 'Home', href: '/', isRoot: true },
      { label: 'Personal Details', href: '/details' },
      { label: 'Handicaps', href: '/handicaps' },
      { label: 'Memberships', href: '/memberships' },
      { label: 'Privileges', href: '/privileges' },
      // { label: 'Messages', href: '/messages' },
      { label: 'Account', href: '/account' },
      { label: 'Dashboard', href: '/dashboard' },
      { label: 'Head2Head', href: '/head2head' },
      { label: 'Win Calculator', href: '/win-calculator' },
      { label: 'Rankings', href: '/rankings' },
    ]
  }, {
    label: 'Clubs & Associations',
    href: '/clubs',
    id: IDS.ORG,
    requiresId: true,
    subRoutes: [
      { label: 'Home', href: '/', isRoot: true },
      { label: 'Profile', href: '/details' },
      { label: 'Matches', href: '/matches' },
      { label: 'Officers', href: '/officers' },
      // { label: 'Groups', href: '/groups' },
      // { label: 'Messages', href: '/messages' },
      { label: 'Membership Categories', href: '/membership-categories' },
      { label: 'Membership Changes', href: '/membership-changes' },
      { label: 'Codes', href: '/codes' },
      { label: 'Courts', href: '/courts' },
    ]
  }, {
    label: 'Matches',
    href: '/matches',
    subRoutes: [
      { label: 'New Match', href: '' },
      { label: 'Match Search', href: '/search' }
    ]
  }, {
    label: 'Configuration',
    href: '/configuration',
    subRoutes: [
      { label: 'Settings', href: '' },
      { label: 'Courts', href: '/courts' },
      { label: 'Countries', href: '/countries' },
      { label: 'Sanctioned Matches', href: '/sanctioned-matches' },
      { label: 'Handicap Flags', href: '/handicap-flags' }
    ]
  }, {
    label: 'Calculators',
    href: '/calculators',
    subRoutes: []
  }, {
    isBooking: true,
    background: 'clay',
    label: 'Bookings',
    href: '/bookings/sheet',
    subRoutes: []
  }
]

export {
  bookingRoutes,
  IDS,
  mainRoutes
}