import React from 'react'
import { Box } from 'grommet'
import { connect } from 'react-redux'
import {
  Route,
  withRouter
} from 'react-router-dom'

import Account from './Account'
import { setActivePlayer } from '../../ducks/player'
import Dashboard from '../Dashboard'
import Handicaps from '../Handicaps'
import HeadToHead from '../HeadToHead'
import Memberships from '../Memberships'
import Messages from '../Messages'
import PersonalDetails from '../PersonalDetails'
import Privileges from '../Privileges'
import Profile from './Profile'
import Rankings from '../Rankings'
import WinCalculator from './WinCalculator'

const People = ({
  dispatch,
  match
}) => {
  const userId = match.params.id

  React.useEffect(() => {
    dispatch(setActivePlayer(userId))

    return () => dispatch(setActivePlayer(null));
  }, [dispatch, userId])

  return (
    <Box>
      <Route exact path={`${match.path}/`} component={Profile} />
      <Route path={`${match.path}/details`} component={PersonalDetails} />
      <Route path={`${match.path}/handicaps`} component={Handicaps} />
      <Route path={`${match.path}/memberships`} component={Memberships} />
      <Route path={`${match.path}/privileges`} component={Privileges} />
      <Route path={`${match.path}/messages`} component={Messages} />
      <Route path={`${match.path}/account`} component={Account} />
      <Route path={`${match.path}/dashboard`} component={Dashboard} />
      <Route path={`${match.path}/head2head`} component={HeadToHead} />
      <Route path={`${match.path}/win-calculator`} component={WinCalculator} />
      <Route path={`${match.path}/rankings`} component={Rankings} />
    </Box>
  )
}

export default withRouter(connect()(People))