import React from 'react'
import {
  Box,
  Text
} from 'grommet'

import Header from '../components/Header'
import ItalicText from '../components/ItalicText'

export default function About() {
  return (
    <Box>
      <Header title='About' />
      <Box
        gap='small'
        margin={{ horizontal: 'auto', top: 'small' }}
        width='large'
      >
        <Text>
          Real Tennis Online is a joint initiative of the governing bodies of real
          tennis in several countries.
        </Text>
        <Text>
          These organisations have pooled their resources to produce a website "engine"
          that provides shared, integrated features to various real tennis related websites
          around the world.
        </Text>
        <Text>
          RTO's mission is:
        </Text>
        <ItalicText>
          "To create a best-in-class web presence that meets every need of real tennis players,
          fans, teachers, students, historians, journalists, sponsors and advertisers throughout
          the world."
        </ItalicText>
      </Box>
    </Box>
  )
}