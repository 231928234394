import React from 'react'
import {
  Box,
  DataTable
} from 'grommet'
import SubHeader from '../../components/SubHeader'
import COLUMNS, { MOCK_DATA } from './columns'

export default function RankedPlayers() {
  return (
    <Box>
      <SubHeader title='Matching Ranked Players' />
      <DataTable
        columns={COLUMNS}
        data={MOCK_DATA}
      />
    </Box>
  )
}