import React from 'react'
import {
  Box,
  DropButton,
  Text
} from 'grommet'
import Calendar from 'react-calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

import StyledFormField from './StyledFormField'

const DateSelect = ({
  date,
  label,
  minDate,
  name,
  setDate
}) => {
  const [openCal, setOpenCal] = React.useState(false)

  return (
    <StyledFormField
      label={label}
      name={name}
    >
      <DropButton
        label={
          <Box
            align='center'
            justify='between'
            direction='row'
          >
            <Text size='small' weight={100}>{date && new Date(date).toDateString()}</Text>
            <FontAwesomeIcon icon={faCalendar} />
          </Box>
        }
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropContent={
          <Calendar
            minDate={minDate}
            onChange={(newDate) => {
              setDate(newDate)
              setOpenCal(false)
            }}
            value={date}
          />
        }
        margin='0.25em'
        onClose={() => setOpenCal(false)}
        onOpen={() => setOpenCal(true)}
        open={openCal}
        plain
      />
    </StyledFormField>
  )
}

export default DateSelect