import React from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Form,
  Heading
} from 'grommet'

import PrimaryButton from '../../components/PrimaryButton'
import MatchResults from './MatchResults'
import Header from '../../components/Header'
import { getCourts } from '../../ducks/court'
import SelectMenu from '../../components/SelectMenu'
import DateSelect from '../../components/DateSelect'
import StyledFormField from '../../components/StyledFormField'
import PlayerSelect from '../../components/PlayerSelect'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'

const DEFAULT = {
  court: '',
  date: null,
  playerOne: null,
  playerTwo: null,
  playerThree: null,
  playerFour: null,
}

const MatchSearch = ({
  courts = [],
  dispatch
}) => {
  const [matchResults, setMatchResults] = React.useState([])
  const [performedSearch, setPerformedSearch] = React.useState(false)
  const [selectedCourt, setSelectedCourt] = React.useState(null)
  const [date, setDate] = React.useState(new Date())
  const [values, setValues] = React.useState(DEFAULT)

  React.useEffect(() => {
    if (!courts.length) {
      dispatch(getCourts())
    } else {
      setSelectedCourt(courts[0])
    }
  }, [courts, dispatch])

  const fetchMatches = () => {
    const params = { courtID: selectedCourt.identity }
    params.playerOne = (values.playerOne && values.playerOne.identity) || 0
    params.playerTwo = (values.playerTwo && values.playerTwo.identity) || 0
    params.playerThree = (values.playerThree && values.playerThree.identity) || 0
    params.playerFour = (values.playerFour && values.playerFour.identity) || 0
    params.matchDate = date.toJSON();
    rtoClient.post(`${VERBS.MATCH}/Search`, params)
      .then(res => {
        setMatchResults(res.data.items)
        setPerformedSearch(true)
      })
      .catch(err => console.warn(err))
  }

  return (
    <Box gap='small'>
      <Header title='Match Search' />
      {!performedSearch && (
        <Box
          margin={{ horizontal: 'auto' }}
          width='medium'
        >
          <Form
            onChange={nextValue => setValues(nextValue)}
            onSubmit={() => fetchMatches()}
          >
            <SelectMenu
              id='court'
              label='Court'
              labelKey={e => e.nameLong}
              items={courts}
              name='court'
              required={true}
              selection={values.court}
              valueKey={{ key: 'identity', reduce: true }}
            />
            <DateSelect
              date={date}
              label='Date'
              name='date'
              setDate={setDate}
            />
            <Box
              border
              margin={{ bottom: 'small' }}
              pad='small'
            >
              <Heading level='4' margin='none'>Pair One</Heading>
              <StyledFormField
                htmlFor='playerOne__input'
                label='Player One'
                name='playerOne'
              >
                <PlayerSelect id='playerOne' />
              </StyledFormField>
              <StyledFormField
                htmlFor='playerTwo__input'
                label='Player Two'
                name='playerTwo'
              >
                <PlayerSelect id='playerTwo' />
              </StyledFormField>
            </Box>
            <Box border pad='small'>
              <Heading level='4' margin='none'>Pair Two</Heading>
              <StyledFormField
                htmlFor='playerThree__input'
                label='Player One'
                name='playerThree'
              >
                <PlayerSelect id='playerThree' />
              </StyledFormField>
              <StyledFormField
                htmlFor='playerFour__input'
                label='Player Two'
                name='playerFour'
              >
                <PlayerSelect id='playerFour' />
              </StyledFormField>
            </Box>
            <Box
              align='center'
              margin={{ top: 'medium' }}
            >
              <PrimaryButton
                label='Search'
                type='submit'
              />
            </Box>
          </Form>
        </Box>
      )}
      {performedSearch && (
        <MatchResults
          clearResults={() => {
            setPerformedSearch(false)
            setMatchResults([])
          }}
          matches={matchResults}
        />
      )}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  courts: state.court.courts
})

export default connect(mapStateToProps)(MatchSearch)