import React from 'react'
import { Box } from 'grommet'
import { Route } from 'react-router-dom'

import AddBooking from './AddBooking'
import Sheet from './Sheet'

export default function BookingSheet({ match }) {
  return (
    <Box>
      <Route exact path={`${match.path}/`} component={Sheet} />
      <Route exact path={`${match.path}/add-booking`} component={AddBooking} />
    </Box>
  )
}