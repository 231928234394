import React from 'react'
import {
  Box,
  Form,
  FormField,
  Select,
  Text,
  TextInput
} from 'grommet'

import PrimaryButton from '../../components/PrimaryButton'
import Modal from '../../components/Modal'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'
import getErrorMessage from '../../helpers/getErrorMessage'

const EditHandicap = ({
  currentUser,
  flags = [],
  handicap,
  onClose
}) => {
  const [values, setValues] = React.useState(handicap)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    setValues(handicap)
  }, [handicap])

  const onSubmit = () => {
    const params = {
      enteredByPersonID: currentUser.identity,
      hcap: values.hcap,
      hcapFlagID: values.hcapFlagID,
      hcapMax: values.hcapMax,
      personID: handicap.personID,
      playerID: handicap.playerID,
      playerTypeID: handicap.playerTypeID,
      reason: values.reason || ''
    }
    rtoClient.post(`${VERBS.PLAYER}/${handicap.playerID}/Adjustment`, params)
      .then(res => {
        setError(null)
        onClose()
      })
      .catch(err => {
        const message = getErrorMessage(err.response)
        setError(message)
      })
  }

  return (
    <Modal
      label='Edit Handicap'
      onClose={onClose}
    >
      <Box width='medium'>
        <Form
          onChange={(next) => setValues(next)}
          onSubmit={onSubmit}
        >
          <FormField
            label='Handicap Type'
            name='type'
          >
            <TextInput
              disabled
              name='type'
              value={handicap.playerTypeLong}
            />
          </FormField>
          <FormField
            label='New Handicap'
            name='hcap'
          >
            <TextInput
              name='hcap'
              type='number'
              value={values.hcap}
            />
          </FormField>
          <FormField
            label='Capped at'
            name='hcapMax'
          >
            <TextInput
              name='hcapMax'
              type='number'
              value={values.hcapMax || ''}
            />
          </FormField>
          <FormField
            htmlFor='hcapFlagID__input'
            label='Handicap Flag'
            name='hcapFlagID'
          >
            <Select
              clear
              id='hcapFlagID'
              labelKey={(e) => e.description}
              name='hcapFlagID'
              options={flags}
              valueKey={{ key: 'identity', reduce: true }}
              value={values.hcapFlagID}
            />
          </FormField>
          <FormField
            htmlFor='reason'
            label='Reason for Change'
            name='reason'
          >
            <TextInput
              id='reason'
              name='reason'
            />
          </FormField>
          {error && (
            <Box align='center'>
              <Text color='red'>
                {error}
              </Text>
            </Box>
          )}
          <Box
            align='center'
            margin={{ top: 'medium' }}
          >
            <PrimaryButton
              a11yTitle='Save new handicap'
              label='Save'
              type='submit'
            />
          </Box>
        </Form>
      </Box>
    </Modal>
  )
}

export default EditHandicap