import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  TextInput
} from 'grommet'

import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'
import Modal from '../../components/Modal'

const DEFAULT = {
  description: '',
  domesticRate: '',
  orgCategoryCode: '',
  overSeasRate: '',
}

const CreateMembershipCat = ({
  onClose,
  orgID
}) => {
  const [values, setValues] = React.useState(DEFAULT)

  const onSave = () => {
    const params = {
      description: values.description,
      domesticRate: values.domesticRate,
      isDeleted: false,
      orgCategoryCode: values.orgCategoryCode,
      orgCategoryType: 'M',
      orgID: orgID,
      overSeasRate: values.overSeasRate
    }

    rtoClient.post(VERBS.ORG_CATEGORY, params)
      .then(res => onClose(true))
      .catch(err => {
        console.warn(err)
        onClose()
      })
  }

  return (
    <Modal
      label='Create Membership Category'
      onClose={() => onClose()}
    >
      <Form
        onChange={nextValue => setValues(nextValue)}
        onSubmit={() => onSave()}
      >
        <FormField
          label='Description'
          name='description'
        >
          <TextInput
            name='description'
            value={values.description}
          />
        </FormField>
        <FormField
          label='Category Code'
          name='orgCategoryCode'
        >
          <TextInput
            name='orgCategoryCode'
            value={values.orgCategoryCode}
          />
        </FormField>
        <FormField
          label='Domestic Rate'
          name='domesticRate'
        >
          <TextInput
            min='0'
            name='domesticRate'
            step='0.01'
            type='number'
            value={values.domesticRate}
          />
        </FormField>
        <FormField
          label='Overseas Rate'
          name='overSeasRate'
        >
          <TextInput
            min='0'
            name='overSeasRate'
            step='0.01'
            type='number'
            value={values.overSeasRate}
          />
        </FormField>
        <Box align='center'>
          <Button
            label='Create'
            primary
            type='submit'
          />
        </Box>
      </Form>
    </Modal>
  )
}

export default CreateMembershipCat