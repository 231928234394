const TIME_SPAN = {
  ONE_MONTH: 'ONE_MONTH',
  THREE_MONTHS: 'THREE_MONTHS',
  SIX_MONTHS: 'SIX_MONTHS',
  ONE_YEAR: 'ONE_YEAR'
}

const DAYS = {
  THREE_MONTHS: '90',
  SIX_MONTHS: '180',
  ONE_YEAR: '365'
}

function startEndDates(timeSpan) {
  let startDate = new Date()
  const endDate = new Date()

  switch (timeSpan) {
    case TIME_SPAN.ONE_MONTH:
      startDate.setMonth(startDate.getMonth() - 1)
      break;
    case TIME_SPAN.THREE_MONTHS:
      startDate.setMonth(startDate.getMonth() - 3)
      break;
    case TIME_SPAN.SIX_MONTHS:
      startDate.setMonth(startDate.getMonth() - 6)
      break;
    default:
      startDate.setYear(startDate.getFullYear() - 1)
  }

  return [startDate, endDate]
}

export {
  DAYS,
  startEndDates,
  TIME_SPAN
}