import React from 'react'
import { Box } from 'grommet'
import { Route } from 'react-router-dom'
import BookingSheet from './BookingSheet'
import MyBookings from './MyBookings'
import FindAGame from './FindAGame'
import ContactList from './ContactList'
import Reporting from './Reporting'

export default function People({ match }) {
  return (
    <Box>
      <Route exact path={`${match.path}/`} component={BookingSheet} />
      <Route path={`${match.path}/sheet`} component={BookingSheet} />
      <Route exact path={`${match.path}/find-a-game`} component={FindAGame} />
      <Route exact path={`${match.path}/my-bookings`} component={MyBookings} />
      <Route exact path={`${match.path}/contact-list`} component={ContactList} />
      <Route exact path={`${match.path}/reporting`} component={Reporting} />
    </Box>
  )
}