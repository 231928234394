import React from 'react'
import {
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  Text
} from 'grommet'
import { connect } from 'react-redux'
import styled from 'styled-components'

import DateSelect from '../../components/DateSelect'
import Header from '../../components/Header'
import SubHeader from '../../components/SubHeader'
import FieldRow from '../../components/FieldRow'
import ResponsiveBox from '../../components/ResponsiveBox'
import SelectMenu from '../../components/SelectMenu'

const StyledField = styled(FormField)`
  > div {
    border: 0;
  }
`

const TYPES = [
  { label: 'Association', value: 'A' },
  { label: 'Club', value: 'C' },
  { label: 'Evening/Nomadic Club', value: 'E' },
  { label: 'Other Organisation', value: 'O' },
]

const ClubDetails = ({
  activeOrg = {},
  countries = []
}) => {
  const [values, setValues] = React.useState(activeOrg)
  const [joinedDate, setJoinedDate] = React.useState(new Date())
  const [snapshotDate, setSnapshotDate] = React.useState(new Date())

  React.useEffect(() => {
    const valSize = Object.keys(values).length
    const orgSize = Object.keys(activeOrg).length
    if (!valSize && orgSize) {
      setValues(activeOrg)
    }
  }, [activeOrg, values])

  return (
    <Box>
      <Header title='Club Profile' />
      <Box
        alignSelf='center'
        width='large'
      >
        <Form
          onChange={nextValue => setValues(nextValue)}
          value={values}
        >
          <Box margin={{ bottom: 'medium' }}>
            <ResponsiveBox gap='small'>
              <SelectMenu
                id='type'
                items={TYPES}
                label='Type'
                labelKey={(e) => e.label}
                name='type'
                selection={values.type}
                valueKey={{ key: 'value', reduce: true }}
              />
              <FieldRow
                id='nameRTO'
                label='RTO Name'
                name='nameRTO'
                value={values.nameRTO}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='nameLong'
                label='Full Name'
                name='nameLong'
                value={values.nameLong}
              />
              <FieldRow
                id='nameShort'
                label='Short Name'
                name='nameShort'
                value={values.nameShort}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='address'
                label='Address'
                name='address'
                value={values.address}
              />
              <FieldRow
                id='city'
                label='Town/City/Suburb'
                name='city'
                value={values.city}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='postalCode'
                label='Postal Code'
                name='postalCode'
                value={values.state}
              />
              <SelectMenu
                id='countryID'
                label='Country'
                name='countryID'
                items={countries}
                labelKey={(e) => e.nameLong}
                selection={values.countryID}
                valueKey={{ key: 'identity', reduce: true }}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='latitude'
                label='Latitude'
                name='latitude'
                step='0.000001'
                type='number'
                value={values.latitude}
              />
              <FieldRow
                id='longitude'
                label='Longitude'
                name='longitude'
                step='0.000001'
                type='number'
                value={values.longitude}
              />
            </ResponsiveBox>
            <ResponsiveBox gap='small'>
              <FieldRow
                id='phone'
                label='Phone'
                name='phone'
                value={values.phone}
              />
              <FieldRow
                id='email'
                label='Email'
                name='email'
                value={values.email}
              />
            </ResponsiveBox>
            <ResponsiveBox
              gap='small'
              justify='center'
            >
              <FieldRow
                id='websiteURL'
                label='Website'
                name='websiteURL'
                value={values.websiteURL}
              />
            </ResponsiveBox>
            <ResponsiveBox>
              <FieldRow
                id='tennisCourtCount'
                label='# of Tennis Courts'
                name='tennisCourtCount'
                type='number'
                value={values.tennisCourtCount}
              />
              <FieldRow
                id='racketsCourtCount'
                label='# of Rackets Courts'
                name='racketsCourtCount'
                type='number'
                value={values.racketsCourtCount}
              />
            </ResponsiveBox>
            <Box
              direction='row'
              justify='center'
            >
              <StyledField margin='none'>
                <CheckBox
                  label={<Text size='small'>Tennis</Text>}
                  name='isTennisOrg'
                />
              </StyledField>
              <StyledField margin='none'>
                <CheckBox
                  label={<Text size='small'>Rackets</Text>}
                  name='isRacketsOrg'
                />
              </StyledField>
            </Box>
          </Box>
          <Box>
            <SubHeader title='TRA Membership Details' />
            <Box>
              <ResponsiveBox>
                {/* <FieldRow
                  id='trA_JoinedDate'
                  label='Joined Date'
                  name='trA_JoinedDate'
                  value={values.trA_JoinedDate}
                /> */}
                <DateSelect
                  date={joinedDate}
                  label='Joined Date'
                  name='joinedDate'
                  setDate={(date) => setJoinedDate(date)}
                />
                <DateSelect
                  date={snapshotDate}
                  label='Snap Shot Date'
                  name='snapshotDate'
                  setDate={(date) => setSnapshotDate(date)}
                />
                {/* <FieldRow
                  id='trA_SnapshotDate'
                  label='Snap Shot Date'
                  name='trA_SnapshotDate'
                  value={values.trA_SnapshotDate}
                /> */}
              </ResponsiveBox>
              <ResponsiveBox
                gap='small'
                justify='center'
              >
                <SelectMenu
                  id='trA_OrgCategoryCode'
                  label='Membership Category'
                  name='trA_OrgCategoryCode'
                  selection={values.trA_OrgCategoryCode}
                />
              </ResponsiveBox>
              <Box
                direction='row'
                justify='center'
              >
                <StyledField margin='none'>
                  <CheckBox
                    label={<Text size='small'>Receives Annual Report?</Text>}
                    name='trA_ReceiveAnnualReport'
                  />
                </StyledField>
                <StyledField margin='none'>
                  <CheckBox
                    label={<Text size='small'>Receives Newsletter?</Text>}
                    name='trA_ReceiveNewsletter``'
                  />
                </StyledField>
              </Box>
            </Box>
          </Box>
          <Box
            direction='row'
            gap='small'
            justify='center'
            margin={{ top: 'medium' }}
          >
            <Button label='Cancel' size='small' />
            <Button label='Delete' size='small' />
            <Button label='Save' primary size='small' />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeOrg: state.org.activeOrg,
  countries: state.country.countries
})

export default connect(mapStateToProps)(ClubDetails)