import React from 'react'
import {
  Box,
  Button
} from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import MatchTable from '../../components/Tables/MatchTable'
import NoneFound from '../../components/NoneFound'

export default function MatchResults({
  clearResults,
  matches = []
}) {
  return (
    <Box gap='small'>
      <Box align='end'>
        <Button
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          label='Search Again'
          onClick={clearResults}
          plain
        />
      </Box>
      {matches.length ? (
        <MatchTable matches={matches} />
      ) : (
        <Box align='center'>
          <NoneFound message='No match results found' />
        </Box>
      )}
    </Box>
  )
}