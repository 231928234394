import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'

const SET_HANDICAP_FLAGS = 'SET_HANDICAP_FLAGS'
const SET_MOVEMENTS = 'SET_MOVEMENTS'

const initialState = {
  flags: [],
  movements: [],
  movementSummary: []
};

const handicapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOVEMENTS:
      return Object.assign({}, state, {
        movements: action.movements,
        movementSummary: action.movementSummary
      });

    case SET_HANDICAP_FLAGS:
      return Object.assign({}, state, {
        flags: action.flags
      });

    default:
      return state;
  }
};

const createHandicapFlag = (description) => {
  return (dispatch) => {
    return rtoClient.post(VERBS.HANDICAP_FLAG, { description })
      .then(res => {
        console.log(res);
      })
      .catch(err => console.warn(err))
  }
}

const getHandicapFlags = () => {
  return (dispatch) => {
    return rtoClient.get(VERBS.HANDICAP_FLAG)
      .then(res => {
        const { items } = res.data

        dispatch({
          type: SET_HANDICAP_FLAGS,
          flags: items
        })
      })
      .catch(err => console.warn(err))
  }
}

const getMovements = (playerId, timeSpan) => {
  return (dispatch) => {
    return rtoClient.get(`${VERBS.PLAYER}/${playerId}${VERBS.MOVEMENTS}/${timeSpan}`)
      .then(res => {
        const {
          playerMatchMovements,
          playerMovementSummary
        } = res.data

        dispatch({
          type: SET_MOVEMENTS,
          movements: playerMatchMovements,
          movementSummary: playerMovementSummary
        })
      })
      .catch(err => console.warn(err))
  }
}

export default handicapReducer;

export {
  createHandicapFlag,
  getHandicapFlags,
  getMovements
}