import React from 'react'
import { Box } from 'grommet'

const ResponsiveBox = ({ children }) => {
  const [direction, setDirection] = React.useState('row')

  React.useEffect(() => {
    const handleWindowResize = e => {
      const direction = window.innerWidth > 768 ? 'row' : 'column'
      setDirection(direction)
    };

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, []);

  return (
    <Box direction={direction} gap='small'>
      {children}
    </Box>
  )
}

export default ResponsiveBox