import { Button } from 'grommet'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  &:hover, &:focus {
    color: #a03619;
  }

  font-size: 0.9rem;
`

export default StyledButton