import React from 'react'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text
} from 'grommet'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import Header from '../components/Header'
import Row from '../components/Row'
import Spinner from '../components/Spinner'
import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'

const BorderedCell = styled(TableCell)`
  border: 1px solid black;
`

const Match = ({ match }) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [matchInfo, setMatchInfo] = React.useState(null)
  const [playerInfo, setPlayerInfo] = React.useState([])
  const [teamInfo, setTeamInfo] = React.useState([])

  React.useEffect(() => {
    rtoClient.get(`${VERBS.MATCH}/${match.params.id}/View`)
      .then(res => {
        const {
          matchInfo,
          playerInfo,
          teamInfo
        } = res.data

        setMatchInfo(matchInfo)
        setPlayerInfo(playerInfo)
        setTeamInfo(teamInfo)
        setIsLoading(false)
      })
      .catch(err => {
        console.warn(err)
        setIsLoading(false)
      })
  }, [match])

  if (isLoading) {
    return (
      <Box align='center'>
        <Spinner label='Loading Match' />
      </Box>
    )
  }

  return (
    <Box gap='medium'>
      <Header title='Match Details' />
      {(!!playerInfo.length && !!teamInfo.length) && (
        <Table margin='small'>
          <TableHeader>
            <TableRow>
              <TableCell />
              <TableCell>
                <Box>
                  <Text>{playerInfo[0].nameFirstLast}</Text>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Text><Text>{playerInfo[1].nameFirstLast}</Text></Text>
                </Box>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>Score:</TableCell>
              <BorderedCell>{teamInfo[0].score}</BorderedCell>
              <BorderedCell>{teamInfo[1].score}</BorderedCell>
            </TableRow>
            <TableRow>
              <TableCell>Handicap:</TableCell>
              <BorderedCell>{teamInfo[0].hcapLong}</BorderedCell>
              <BorderedCell>{teamInfo[1].hcapLong}</BorderedCell>
            </TableRow>
            <TableRow>
              <TableCell>Handicap Diff Played:</TableCell>
              <BorderedCell>{teamInfo[0].handicapDifferencePlayed}</BorderedCell>
              <BorderedCell>{teamInfo[1].handicapDifferencePlayed}</BorderedCell>
            </TableRow>
            <TableRow>
              <TableCell>Result:</TableCell>
              <BorderedCell>{teamInfo[0].result}</BorderedCell>
              <BorderedCell>{teamInfo[1].result}</BorderedCell>
            </TableRow>
            <TableRow>
              <TableCell>Handicap Change:</TableCell>
              <BorderedCell>{teamInfo[0].hcapChange}</BorderedCell>
              <BorderedCell>{teamInfo[1].hcapChange}</BorderedCell>
            </TableRow>
          </TableBody>
        </Table>
      )}

      {!!matchInfo && (
        <Box
          align='center'
          gap='small'
        >
          <Row
            label='Court'
            value={matchInfo.courtNameLong}
          />
          <Row
            label='Date'
            value={matchInfo.matchDateLong}
          />
          <Row
            label='Type'
            value={matchInfo.scLong}
          />
          {matchInfo.description && (
            <Row
              label='Description'
              value={matchInfo.description}
            />
          )}
          <Row
            label='Sets Played'
            value={`${matchInfo.setsPlayed} (${matchInfo.setsCompleted} Sets Completed)`}
          />
          <Row
            label='Weighting'
            value={matchInfo.weighting?.toString()}
          />
          <Row
            label='Entered by'
            value={matchInfo.enteredByNameFirstLast}
          />
        </Box>
      )}

      <Button
        alignSelf='center'
        label='Delete'
      />
    </Box>
  )
}

export default withRouter(Match)