import {
  applyMiddleware,
  createStore
} from 'redux'
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers'

const createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore)

export default createStoreWithMiddleware(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)