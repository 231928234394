import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'

const SET_CONFIGURATION = 'SET_CONFIGURATION'

const initialState = {
  state: {}
};

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIGURATION:
      return Object.assign({}, state, {
        state: action.state
      });

    default:
      return state;
  }
};

const getConfiguration = () => {
  return (dispatch) => {
    return rtoClient.get(VERBS.CONFIGURATION)
      .then(res => {
        const { data } = res

        dispatch({
          type: SET_CONFIGURATION,
          state: data
        })
      })
      .catch(err => console.warn(err))
  }
}

const setConfiguration = (params = {}) => {
  return (dispatch) => {
    return rtoClient.put(VERBS.CONFIGURATION, params)
      .then(res => {
        const { data } = res

        dispatch({
          type: SET_CONFIGURATION,
          state: data
        })
      })
      .catch(err => console.warn(err))
  }
}

export default configurationReducer;

export {
  getConfiguration,
  setConfiguration
}