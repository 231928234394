import React from 'react'
import {
  Box,
  DataTable,
  Layer
} from 'grommet'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import PrimaryButton from '../../../components/PrimaryButton'
import EditButton from '../../../components/EditButton'
import NoneFound from '../../../components/NoneFound'
import { getHandicapFlags } from '../../../ducks/handicap'
import Header from '../../../components/Header'
import EditFlag from './EditFlag'

const getColumns = (setActiveFlag) => {
  return [
    {
      property: 'description',
      header: 'Description',
      render: (datum) => <EditButton label={datum.description} onClick={() => setActiveFlag(datum)} plain />
    }, {
      property: 'isDeleted',
      header: 'Deleted?',
      render: (datum) => datum.isDeleted ? <FontAwesomeIcon color='red' icon={faTimes} /> : null
    }
  ]
}

const HandicapFlags = ({
  dispatch,
  flags = []
}) => {
  const [showEdit, setShowEdit] = React.useState(false)
  const [activeFlag, setActiveFlag] = React.useState(null)

  React.useEffect(() => {
    dispatch(getHandicapFlags())
  }, [dispatch])

  if (!flags.length) {
    return (
      <Box align='center'>
        <NoneFound message='No Handicap Flags found' />
      </Box>
    )
  }

  const onSetActiveFlag = (flag) => {
    setActiveFlag(flag)
    setShowEdit(true)
  }

  const onClose = () => {
    setShowEdit(false)
    setActiveFlag(null)
  }

  return (
    <Box>
      {showEdit && (
        <Layer onClickOutside={() => onClose()}>
          <EditFlag
            activeFlag={activeFlag}
            onClose={() => onClose()}
          />
        </Layer>
      )}
      <Box gap='small'>
        <Header title='All Handicap Flags' />
        <DataTable
          columns={getColumns(onSetActiveFlag)}
          data={flags}
          onClickRow={e => onSetActiveFlag(e.datum)}
        />
        <Box align='center'>
          <PrimaryButton
            label='Create Flag'
            onClick={() => setShowEdit(!showEdit)}
          />
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  flags: state.handicap.flags
})

export default connect(mapStateToProps)(HandicapFlags)