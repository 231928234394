import React from 'react'
import {
  Box,
  DataTable,
  FormField,
  Layer,
  Select,
  Text
} from 'grommet'
import { connect } from 'react-redux'

import PrimaryButton from '../../components/PrimaryButton'
import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'
import Spinner from '../../components/Spinner'
import { setActiveOrgCourts } from '../../ducks/org'
import STATES from '../../helpers/async'
import EditButton from '../../components/EditButton'
import { getCourts } from '../../ducks/court'
import ClubCourtEdit from './ClubCourtEdit'
import AddCourt from './AddCourt'

const getColumns = (onSelect) => {
  return [
    {
      property: 'action',
      header: 'Action',
      render: (datum) => <EditButton label='Edit' onClick={() => onSelect(datum)} plain />
    }, {
      property: 'nameLong',
      header: 'Court'
    }, {
      property: 'nameShort',
      header: 'Code'
    }, {
      property: 'hcapAfter',
      header: 'In Play',
      render: (datum) => <Text>{datum.isInPlay ? 'Yes' : 'No'}</Text>
    }
  ]
}

const getFilteredCourts = (allCourts, orgCourts) => {
  const courtNames = orgCourts.map(court => court.nameLong)
  return allCourts.filter(court => !courtNames.includes(court.nameLong))
}

const ClubCourts = ({
  activeOrg = {},
  allCourts = [],
  courts = [],
  dispatch,
  state = STATES.READY
}) => {
  const {
    identity,
    nameRTO
  } = activeOrg
  const [confirmAddCourt, setConfirmAddCourt] = React.useState(false)
  const [courtToAdd, setCourtToAdd] = React.useState(null)
  const [selectedCourt, setSelectedCourt] = React.useState(null)

  React.useEffect(() => {
    if (identity) {
      dispatch(setActiveOrgCourts(identity))
      dispatch(getCourts())
    }
  }, [dispatch, identity])

  const onClose = (editedCourt = false) => {
    setSelectedCourt(null)
    if (editedCourt) {
      dispatch(setActiveOrgCourts(identity))
    }
  }

  const allCourtsFiltered = getFilteredCourts(allCourts, courts)

  return (
    <Box>
      {selectedCourt && (
        <Layer onClickOutside={() => onClose(false)}>
          <ClubCourtEdit
            court={selectedCourt}
            onClose={onClose}
          />
        </Layer>
      )}

      {confirmAddCourt && (
        <Layer onClickOutside={() => setConfirmAddCourt(false)}>
          <AddCourt
            courtToAdd={courtToAdd}
            onClose={() => setConfirmAddCourt(false)}
            org={activeOrg}
          />
        </Layer>
      )}

      <Header title='Courts' />
      {state === STATES.FETCHING && (
        <Box align='center'>
          <Spinner label='Loading Courts' />
        </Box>
      )}

      {(state === STATES.READY && !courts.length) && (
        <Box align='center'>
          <NoneFound message={`${nameRTO} does not contain any courts`} />
        </Box>
      )}

      {(state === STATES.READY && courts.length > 0) && (
        <DataTable
          columns={getColumns((court) => setSelectedCourt(court))}
          data={courts}
          onClickRow={e => setSelectedCourt(e.datum)}
        />
      )}

      <Box
        direction='row'
        justify='center'
        gap='small'
        margin='medium'
      >
        <FormField
          htmlFor='courtToAdd__input'
          name='courtToAdd'
          label='Add Court'
          margin='none'
        >
          <Select
            id='courtToAdd'
            name='courtToAdd'
            labelKey={e => e.nameLong}
            onChange={e => setCourtToAdd(e.value)}
            options={allCourtsFiltered}
            value={courtToAdd}
          />
        </FormField>
        <PrimaryButton
          alignSelf='end'
          disabled={!courtToAdd}
          label='Add'
          onClick={() => setConfirmAddCourt(true)}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeOrg: state.org.activeOrg,
  allCourts: state.court.courts,
  courts: state.org.activeOrgCourts,
  state: state.org.activeOrgCourtsStatus
})

export default connect(mapStateToProps)(ClubCourts)