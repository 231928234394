import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  TextInput
} from 'grommet'

import Header from '../../components/Header'
import DangerButton from '../../components/DangerButton'
import PrimaryButton from '../../components/PrimaryButton'

const DEFAULT = {
  consentDate: '26-Apr-2021',
  email: '',
  futureConsent: '',
  gift: '',
  status: 'Member'
}

export default function Account() {
  const [values, setValues] = React.useState(DEFAULT)

  return (
    <Box>
      <Header title='Account' />
      <Box align='center'>
        <Form
          onChange={next => setValues(next)}
          onSubmit={e => console.log(values)}
        >
          <Box width='medium'>
            <FormField
              disabled
              htmlFor='status'
              label='Status'
              name='status'
            >
              <TextInput
                id='status'
                name='status'
                size='small'
                value={values.status}
              />
            </FormField>
            <FormField
              htmlFor='email'
              name='email'
              label='Email address'
            >
              <TextInput
                id='email'
                name='email'
                size='small'
                value={values.email}
              />
            </FormField>
            <Button
              alignSelf='end'
              label='Send Reset Password Email'
            />
            <FormField
              disabled
              htmlFor='consentDate'
              label='GDLR Consented'
              name='consentDate'
            >
              <TextInput
                id='consentDate'
                name='consentDate'
                size='small'
                value={values.consentDate}
              />
            </FormField>
            <FormField
              htmlFor='gift'
              name='gift'
              label='Gift Aid:'
            >
              <TextInput
                id='gift'
                name='gift'
                size='small'
                type='number'
                value={values.gift}
              />
            </FormField>
            <FormField
              htmlFor='futureConsent'
              name='futureConsent'
              label='Future Consent:'
            >
              <TextInput
                id='futureConsent'
                name='futureConsent'
                size='small'
                value={values.futureConsent}
              />
            </FormField>
          </Box>
          <Box gap='small'>
            <Box
              alignSelf='end'
              direction='row'
              gap='xsmall'
            >
              <PrimaryButton
                label='Cancel'
              />
              <PrimaryButton
                label='Save'
                type='submit'
              />
            </Box>
            <DangerButton
              alignSelf='end'
              label='Permanently Delete Account'
            />
          </Box>
        </Form>
      </Box>
    </Box >
  )
}