import {
  Anchor,
  Text
} from 'grommet'

const COLUMNS = [
  {
    property: 'matchID',
    header: 'Match #',
    render: (datum) => datum.matchID ? <Anchor href={`/matches/match/${datum.matchID}`}>{datum.matchID}</Anchor> : null
  }, {
    property: 'effectiveDateLong',
    header: 'Date'
  }, {
    property: 'hcapBeforeLong',
    header: 'Hcap Before'
  }, {
    property: 'hcapAfterLong',
    header: 'Hcap After'
  }, {
    property: 'courtNameLong',
    header: 'Court'
  }, {
    property: 'partnerNameFirstLast',
    header: 'Partner'
  }, {
    property: 'opponents',
    header: 'Opponent{s}'
  }, {
    property: 'score',
    header: 'Score'
  }, {
    property: 'result',
    header: 'Result'
  }
]

export const SUMMARY_COLUMNS = [
  {
    property: 'netHcapChange',
    header: 'Net Hcap Change'
  }, {
    property: 'matches',
    header: 'Matches'
  }, {
    property: 'wins',
    header: 'Wins'
  }, {
    property: 'losses',
    header: 'Losses'
  }, {
    property: 'avgHcapDiff',
    header: 'Avg Hcap Diff'
  }, {
    property: 'W1',
    header: 'W!'
  }, {
    property: 'W',
    header: 'W'
  }, {
    property: 'D',
    header: 'D'
  }, {
    property: 'L',
    header: 'L'
  }, {
    property: 'L!',
    header: 'L!'
  }, {
    property: 'W²',
    header: <Text size='xsmall'>W<sup>2</sup></Text>
  }, {
    property: 'L²',
    header: <Text size='xsmall'>L<sup>2</sup></Text>
  }, {
    property: 'W¹',
    header: <Text size='xsmall'>W<sup>1</sup></Text>
  }, {
    property: 'L¹',
    header: <Text size='xsmall'>L<sup>1</sup></Text>
  }, {
    property: 'n',
    header: 'N'
  }
]

export default COLUMNS