import React from 'react'
import {
  Box,
  Button,
  DataTable,
  FormField,
  Layer,
  Select,
  Text
} from 'grommet'
import { connect } from 'react-redux'
import styled from 'styled-components'

import EditButton from '../../components/EditButton'
import Header from '../../components/Header'
import ItalicText from '../../components/ItalicText'
import NoneFound from '../../components/NoneFound'
import { getHandicapFlags } from '../../ducks/handicap'
import { setActivePlayers } from '../../ducks/player'
import CreateHandicap from './CreateHandicap'
import EditHandicap from './EditHandicap'

const getColumns = (onEdit, isActiveUser = false) => {
  const columns = [
    {
      property: 'action',
      header: 'Action',
      render: (datum) => <EditButton label='Edit' onClick={() => onEdit(datum)} plain />
    }, {
      property: 'playerTypeLong',
      header: 'Handicap Type'
    }, {
      property: 'hcap',
      header: 'Handicap'
    }, {
      property: 'cap',
      header: 'Cap',
      render: (datum) => <Text>{datum.isCapped ? datum.hcapMaxLong : 'No'}</Text>
    }, {
      property: 'isProvisional',
      header: 'Provisional',
      render: (datum) => <Text>{datum.isProvisional ? 'Yes' : 'No'}</Text>
    }, {
      property: 'isCategory1',
      header: 'Category1',
      render: (datum) => <Text>{datum.isCategory1 ? 'Yes' : 'No'}</Text>
    }
  ]
  if (isActiveUser) {
    columns.shift()
  }
  return columns;
}

const StyledButton = styled(Button)`
  background: #f4f4df;
  color: black;
`

const Handicap = ({
  activePlayer,
  activePlayers = [],
  dispatch,
  flags,
  playerTypes = [],
  user
}) => {
  const [activeHandicap, setActiveHandicap] = React.useState(null)
  const [editHandicap, setEditHandicap] = React.useState(null)
  const [type, setType] = React.useState(null)
  const [types, setTypes] = React.useState(playerTypes)

  React.useEffect(() => {
    if (activePlayer && activePlayer.personID) {
      dispatch(setActivePlayers(activePlayer.personID))
      dispatch(getHandicapFlags())
    }
  }, [activePlayer, dispatch])

  React.useEffect(() => {
    if (playerTypes.length) {
      const activeTypes = activePlayers.map((type) => type.playerTypeLong)
      const inactiveTypes = playerTypes.filter((type) => !activeTypes.includes(type.playerTypeLong))
      setTypes(inactiveTypes)
    }
  }, [activePlayers, playerTypes])

  const isActiveUser = activePlayer?.personID === user?.identity

  return (
    <Box>
      {activeHandicap && (
        <Layer onClickOutside={() => setActiveHandicap(null)}>
          <CreateHandicap
            currentUser={user}
            flags={flags}
            handicap={activeHandicap}
            onClose={() => {
              dispatch(setActivePlayers(activePlayer.personID))
              setActiveHandicap(null)
            }}
            userToEdit={activePlayer}
          />
        </Layer>
      )}
      {editHandicap && (
        <Layer onClickOutside={() => setEditHandicap(null)}>
          <EditHandicap
            currentUser={user}
            flags={flags}
            handicap={editHandicap}
            onClose={() => {
              dispatch(setActivePlayers(activePlayer.personID))
              setEditHandicap(null)
            }}
          />
        </Layer>
      )}
      <Header title='Handicaps' />
      {!activePlayers.length ? (
        <Box align='center'>
          <NoneFound message='No Handicaps Found for this Players' />
        </Box>
      ) : (
        <DataTable
          columns={getColumns(setEditHandicap, isActiveUser)}
          data={activePlayers}
          onClickRow={!isActiveUser ? (e) => setEditHandicap(e.datum) : null}
        />
      )}
      {isActiveUser && (
        <Box
          align='center'
          margin='medium'
        >
          <ItalicText>You are not permitted to change your own handicap</ItalicText>
        </Box>
      )}
      {(types.length > 0 && !isActiveUser) && (
        <Box
          align='center'
          direction='row'
          gap='small'
          margin={{ top: 'medium', horizontal: 'auto' }}
        >
          <FormField
            htmlFor='selectedHandicap__input'
            label='Select handicap to add'
            margin='none'
            name='selectedHandicap'
          >
            <Select
              id='selectedHandicap'
              labelKey='playerTypeLong'
              name='selectedHandicap'
              options={types}
              onChange={(e) => setType(e.value)}
              value={type}
            />
          </FormField>
          <StyledButton
            a11yTitle='Add Selected Handicap'
            alignSelf='end'
            disabled={!type}
            label='Add'
            onClick={() => setActiveHandicap(type)}
            primary
          />
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activePlayer: state.player.activePlayer,
  activePlayers: state.player.activePlayers,
  flags: state.handicap.flags,
  playerTypes: state.player.playerTypes,
  user: state.login.user
})

export default connect(mapStateToProps)(Handicap)