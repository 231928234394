import { Auth } from 'aws-amplify';
import axios from 'axios'
import { initializeAws } from './amplify';

const BASE_URL = 'https://rto-staging.api.realtennisonline.com'

const Client = {
  getBearerToken: async function () {
    return await Auth.currentSession()
      .then(res => {
        this.accessToken = res.accessToken.jwtToken
        const userId = res.idToken.payload['custom:person_id']
        return userId || false
      })
      .catch(err => {
        console.warn(err)
        return false
      })
  },

  initialize: function () {
    this.client = axios.create({
      baseURL: BASE_URL,
      timeout: 5000
    });
    initializeAws()
  },

  delete: function (verb) {
    return this.client.get(verb, {
      headers: {
        'Authorization': `Bearer ${this.accessToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
  },

  get: function (verb, params = {}) {
    return this.client.get(verb, {
      headers: {
        'Authorization': `Bearer ${this.accessToken}`,
        'accept': 'application/json',
      },
      params
    })
  },

  post: function (verb, data = {}) {
    return this.client.post(verb, data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.accessToken}`,
      }
    })
  },

  put: function (verb, data = {}) {
    return this.client.put(verb, data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.accessToken}`
      },
    })
  }
}

export default Client