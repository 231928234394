import React from 'react'
import {
  Box,
  DataTable,
  FormField,
  Layer,
  Select
} from 'grommet'
import { connect } from 'react-redux'

import EditButton from '../../components/EditButton'
import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'
import PrimaryButton from '../../components/PrimaryButton'
import Spinner from '../../components/Spinner'
import { getOrgs } from '../../ducks/org'
import EditPrivilege from './EditPrivilege'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'
import NewPrivilege from './NewPrivilege'

const ACTIONS = [
  'Club / Association Administrator',
  'Club Finance User',
  'IHSC Administrator',
  'Match Administrator',
  'Standard User for Online Booking System'
]

const getColumns = (onClick) => {
  return [
    {
      property: 'action',
      header: 'Action',
      render: (datum) => <EditButton onClick={() => onClick(datum)}>Edit</EditButton>
    }, {
      property: 'roleLong',
      header: 'Privilege'
    }, {
      property: 'startDate',
      header: 'Begins'
    }, {
      property: 'endDate',
      header: 'Expires'
    }, {
      property: 'orgName',
      header: 'Organisation'
    }
  ]
}

const Privileges = ({
  activePlayer,
  dispatch,
  orgs = []
}) => {
  const [privilege, setPrivilege] = React.useState(null)
  const [privilegeToEdit, setPrivilegeToEdit] = React.useState(null)
  const [privileges, setPrivileges] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [newPrivilege, setNewPrivilege] = React.useState(null)

  React.useEffect(() => {
    if (activePlayer?.personID) {
      rtoClient.get(`${VERBS.PERSON}/${activePlayer.personID}/Roles`)
        .then(res => {
          setPrivileges(res.data)
          setIsLoading(false)
        })
        .catch(err => {
          console.warn(err)
          setIsLoading(false)
        })
    }

    if (!orgs.length) {
      dispatch(getOrgs())
    }
  }, [activePlayer, dispatch, orgs])

  if (isLoading) {
    return (
      <Box align='center'>
        <Spinner label='Loading Privileges' />
      </Box>
    )
  }

  return (
    <Box>
      {privilegeToEdit && (
        <Layer onClickOutside={() => setPrivilegeToEdit(null)}>
          <EditPrivilege
            onClose={() => setPrivilegeToEdit(null)}
            orgs={orgs}
            privilege={privilegeToEdit}
          />
        </Layer>
      )}
      {newPrivilege && (
        <Layer onClickOutside={() => setNewPrivilege(null)}>
          <NewPrivilege
            onClose={() => setNewPrivilege(null)}
            orgs={orgs}
            privilege={newPrivilege}
          />
        </Layer>
      )}
      <Header title='Privileges' />
      {privileges.length > 0 ? (
        <DataTable
          columns={getColumns(setPrivilegeToEdit)}
          data={privileges}
          onClickRow={(e) => setPrivilegeToEdit(e.datum)}
        />
      ) : (
        <Box align='center'>
          <NoneFound message='No privileges found' />
        </Box>
      )}
      <Box
        align='center'
        direction='row'
        gap='small'
        margin={{ top: 'medium', horizontal: 'auto' }}
      >
        <FormField
          htmlFor='selectedPrivilege__input'
          label='Select privilege to add'
          margin='none'
          name='selectedPrivilege'
        >
          <Select
            id='selectedPrivilege'
            name='selectedPrivilege'
            options={ACTIONS}
            onChange={(e) => setPrivilege(e.value)}
            value={privilege}
          />
        </FormField>
        <PrimaryButton
          a11yTitle='Add Selected Privilege'
          alignSelf='end'
          disabled={!privilege}
          label='Add'
          onClick={() => setNewPrivilege(privilege)}
          primary
        />
      </Box>
    </Box >
  )
}

const mapStateToProps = (state) => ({
  activePlayer: state.player.activePlayer,
  orgs: state.org.orgs,
})

export default connect(mapStateToProps)(Privileges)