import React from 'react'
import {
  Anchor,
  Box,
  Text
} from 'grommet'

import Header from '../components/Header'

export default function Contact() {
  return (
    <Box>
      <Header title='Contact' />
      <Box
        margin={{ horizontal: 'auto', top: 'small' }}
      >
        <Text weight='bold'>
          For policy and general issues:
        </Text>
        <Anchor
          href='mailto:executive@tennisandrackets.com'
        >
          executive@tennisandrackets.com
        </Anchor>
      </Box>
    </Box>
  )
}