import React from 'react'
import {
  Box,
  Button,
  DataTable,
  Layer,
  Text
} from 'grommet'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'
import HeadToHeadOptions from './HeadToHeadOptions'
import COLUMNS from './columns'

const StyledButton = styled(Button)`
  text-decoration: underline;
`

export default function HeadToHead() {
  const [help, showHelp] = React.useState(false)

  return (
    <Box>
      {help && (
        <Layer
          onClickOutside={() => showHelp(false)}
        >
          <Box
            gap='small'
            pad='medium'
          >
            <Box direction='row'>
              <Header title='How Do I Use Quick Links?' />
              <Button
                label={<FontAwesomeIcon icon={faTimesCircle} />}
                margin={{ left: 'auto', bottom: 'small' }}
                onClick={() => showHelp(false)}
                plain
              />
            </Box>
            <Text>
              "Quick Links" is a list of players that you can create, similar to bookmarks/favourites.
            </Text>
            <Text>
              When you select a player from your Quick Links you go directly to that player's home page.
            </Text>
            <Text>
              Also, when you add players to your Quick Links, those players automatically appear on your Dashboard and Head2Head screens as well.
            </Text>
            <Text>
              To add players to your Quick Links, first go to the player's "home page" and then click the "+" button next to the Quick Links menu.
            </Text>
          </Box>
        </Layer>
      )}
      <Header title='Head2Head' />
      {false ? (
        <NoneFound message='No handicaps were found for this person.' />
      ) : (
        <Box>
          <HeadToHeadOptions />
          <DataTable
            columns={COLUMNS}
          />
          <Box align='end'>
            <NoneFound
              action={
                <StyledButton
                  label='Show me how'
                  onClick={() => showHelp(true)}
                  plain
                />
              }
              margin={{ vertical: 'xsmall' }}
              message="No matching Head To Head information was found. Add people to your 'Quick Links' to see them head to head."
              size='small'
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}