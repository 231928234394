import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  Text,
  TextInput
} from 'grommet'
import { connect } from 'react-redux'

import Modal from '../../../components/Modal'
import rtoClient from '../../../helpers/client'
import VERBS from '../../../helpers/verbs'
import { getSanctionedMatches } from '../../../ducks/match'

const DEFAULT = {
  description: ''
}

const EditMatch = ({
  activeMatch,
  dispatch,
  onClose
}) => {
  const [values, setValues] = React.useState(DEFAULT)

  React.useEffect(() => {
    if (activeMatch) {
      setValues(activeMatch)
    }
  }, [activeMatch])

  const onDelete = () => {
    rtoClient.delete(`${VERBS.SANCTIONED_MATCH}/${activeMatch.identity}`)
      .then(res => {
        dispatch(getSanctionedMatches())
        onClose()
      })
      .catch(err => {
        console.warn(err)
        onClose()
      })
  }

  const onSubmit = (deleteValue) => {
    const params = Object.assign(values, {})

    if (typeof deleteValue !== 'undefined') {
      params.isDeleted = deleteValue
    }

    if (activeMatch) {
      rtoClient.put(`${VERBS.SANCTIONED_MATCH}/${activeMatch.identity}`, params)
        .then(res => {
          dispatch(getSanctionedMatches())
          onClose()
        })
        .catch(err => {
          console.warn(err)
          onClose()
        })
    } else {
      rtoClient.post(VERBS.SANCTIONED_MATCH, params)
        .then(res => {
          dispatch(getSanctionedMatches())
          onClose()
        })
        .catch(err => {
          console.warn(err)
          onClose()
        })
    }
  }

  const action = !!activeMatch ? 'Edit' : 'Create New'

  return (
    <Modal
      label={`${action} Match`}
      onClose={onClose}
    >
      {!activeMatch && (
        <Text size='small'>
          Save a new match in the RTO database
        </Text>
      )}
      <Form
        onChange={(next) => setValues(next)}
        onSubmit={() => onSubmit()}
      >
        <FormField
          htmlFor='description'
          label='Description'
          name='description'
        >
          <TextInput
            id='description'
            name='description'
            size='small'
            value={values.description}
          />
        </FormField>
        <Box
          justify='center'
          direction='row'
          gap='small'
          margin={{ top: 'medium' }}
        >
          <Button
            label='Cancel'
            onClick={onClose}
          />
          {activeMatch && (
            <Button
              label='Delete'
              onClick={onDelete}
            />
          )}
          <Button
            disabled={!values.description.length}
            label='Save'
            primary
            type='submit'
          />
        </Box>
      </Form>
    </Modal>
  )
}

export default connect()(EditMatch)