import React from 'react'
import { Box } from 'grommet'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Spinner from '../components/Spinner'

const Home = ({
  user
}) => {
  const history = useHistory()

  React.useEffect(() => {
    if (user?.identity) {
      history.push(`/people/${user.identity}/`)
    }
  }, [history, user])

  return (
    <Box
      align='center'
      height='medium'
      justify='center'
    >
      <Spinner label='Loading' />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.login.user
})

export default connect(mapStateToProps)(Home)