import React from 'react'
import {
  Box,
  DataTable,
  Layer
} from 'grommet'
import { connect } from 'react-redux'

import EditButton from '../../../components/EditButton'
import PrimaryButton from '../../../components/PrimaryButton'
import EditMatch from './EditMatch'
import Header from '../../../components/Header'
import Spinner from '../../../components/Spinner'
import { getSanctionedMatches } from '../../../ducks/match'
import STATES from '../../../helpers/async'
import NoneFound from '../../../components/NoneFound'

const getColumns = (setActiveMatch) => {
  return [
    {
      property: 'description',
      header: 'Description',
      render: (datum) => <EditButton label={datum.description} onClick={() => setActiveMatch(datum)} plain />
    }
  ]
}

const SanctionedMatches = ({
  dispatch,
  sanctionedMatches = [],
  sanctionedMatchesStatus
}) => {
  const [activeMatch, setActiveMatch] = React.useState(null)
  const [editMatch, setEditMatch] = React.useState(false)

  const onSetActiveMatch = (match) => {
    setActiveMatch(match)
    setEditMatch(true)
  }

  const onCloseModal = () => {
    setEditMatch(false)
    setActiveMatch(null)
  }

  React.useEffect(() => {
    dispatch(getSanctionedMatches())
  }, [dispatch])

  if (sanctionedMatchesStatus === STATES.FETCHING) {
    return (
      <Box align='center'>
        <Spinner label='Loading Sanctioned Matches' />
      </Box>
    )
  }

  return (
    <Box>
      {editMatch && (
        <Layer onClickOutside={() => onCloseModal()}>
          <EditMatch
            activeMatch={activeMatch}
            onClose={() => onCloseModal()}
          />
        </Layer>
      )}
      <Header title='Sanctioned Matches' />
      {!sanctionedMatches.length ? (
        <NoneFound message='No Sanctioned Matches Found' />
      ) : (
        <DataTable
          columns={getColumns(onSetActiveMatch)}
          data={sanctionedMatches}
        />
      )}
      <Box
        align='center'
        margin={{ top: 'small' }}
      >
        <PrimaryButton
          label='Create Match'
          onClick={() => setEditMatch(!editMatch)}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  sanctionedMatches: state.match.sanctionedMatches,
  sanctionedMatchesStatus: state.match.sanctionedMatchesStatus
})

export default connect(mapStateToProps)(SanctionedMatches)