import React from 'react'
import { Anchor, Text } from 'grommet'
import { Link } from 'react-router-dom'
import { string } from 'prop-types'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  text-decoration: ${props => props.isactive === 'true' ? 'underline' : 'none'};
`

export default function CommonAnchor({
  color = 'inherit',
  isActive = false,
  label,
  size = 'small',
  weight = 'normal',
  url
}) {
  return (
    <Anchor
      as={'span'}
      margin={{ vertical: 'auto' }}
      size='small'
    >
      <StyledLink
        isactive={isActive.toString()}
        to={{ pathname: url }}
      >
        <Text
          color={color}
          size={size}
          weight={weight}
        >
          {label}
        </Text>
      </StyledLink>
    </Anchor>
  )
}

CommonAnchor.propTypes = {
  label: string,
  size: string,
  url: string
}