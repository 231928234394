import React from 'react'
import {
  Anchor,
  Box,
  Image,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text
} from 'grommet'
import { connect } from 'react-redux'
import styled from 'styled-components'

import CommonAnchor from '../../components/CommonAnchor'
import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'
import SubHeader from '../../components/SubHeader'
import Spinner from '../../components/Spinner'
import STATES from '../../helpers/async'

const StyledRow = styled(TableRow)`
  border-bottom: 1px solid black;
`

const ClubHome = ({
  activeOrg = {},
  activeOrgOfficers = [],
  activeOrgStatus
}) => {
  const {
    address,
    city,
    country,
    email,
    identity,
    fax,
    nameLong,
    nameShort,
    phone,
    postalCode,
    state,
    type,
    websiteURL
  } = activeOrg

  const [image, setImage] = React.useState(null)

  React.useEffect(() => {
    try {
      const img = require(`../../media/images/logos/${identity}.gif`)?.default
      setImage(img)
    } catch (e) {
      console.warn('Club logo not found')
    }
  }, [identity])

  if (activeOrgStatus === STATES.FETCHING) {
    return (
      <Box align='center'>
        <Spinner label='Loading Club' />
      </Box>
    )
  }

  return (
    <Box gap='small'>
      <Header title='Home' />
      <Box direction='row' gap='small'>
        <Box basis='2/3' gap='small'>
          <SubHeader margin='none' title='Details' />
          <Table>
            <TableBody>
              <StyledRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  {nameLong}
                </TableCell>
              </StyledRow>
              <StyledRow>
                <TableCell>
                  <strong>Short Name</strong>
                </TableCell>
                <TableCell>
                  {nameShort}
                </TableCell>
              </StyledRow>
              <StyledRow>
                <TableCell>
                  <strong>Type</strong>
                </TableCell>
                <TableCell>
                  {type}
                </TableCell>
              </StyledRow>
              <StyledRow>
                <TableCell>
                  <strong>Email</strong>
                </TableCell>
                <TableCell>
                  <Anchor
                    href={`mailto:${email}`}
                    label={email}
                  />
                </TableCell>
              </StyledRow>
              <StyledRow>
                <TableCell>
                  <strong>Website</strong>
                </TableCell>
                <TableCell>
                  <Anchor
                    href={`http://${websiteURL}`}
                    label={websiteURL}
                    target='_blank'
                  />
                </TableCell>
              </StyledRow>
              <StyledRow>
                <TableCell>
                  <strong>Phone</strong>
                </TableCell>
                <TableCell>
                  {phone}
                </TableCell>
              </StyledRow>
              {fax && (
                <StyledRow>
                  <TableCell>
                    <strong>Fax</strong>
                  </TableCell>
                  <TableCell>
                    {fax}
                  </TableCell>
                </StyledRow>
              )}
              <TableRow>
                <TableCell>
                  <strong>Address</strong>
                </TableCell>
                <TableCell>
                  {`${address || ''} ${city || ''}, ${state || ''}, ${postalCode || ''}, ${country || ''}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box
            direction='row'
            gap='small'
            margin={{ vertical: 'small' }}
          >
            <Box basis='1/2'>
              <SubHeader title='Officers' />
              {!activeOrgOfficers.length ? (
                <NoneFound message='No officers were found for this club' size='small' />
              ) : (
                activeOrgOfficers.map((officer, i) => {
                  return (
                    <Box
                      key={`OFFICER_${i}`}
                      margin={{ bottom: 'xsmall' }}
                    >
                      <Text size='small' weight='bold'>{officer.title}</Text>
                      <CommonAnchor
                        label={officer.nameFirstLastTag}
                        url={`/people/${officer.personID}`}
                      />
                    </Box>
                  )
                })
              )}
            </Box>
            <Box basis='1/2'>
              <SubHeader title='RTO Administrators' />
              <CommonAnchor label='Trey Bogue' url='/' />
            </Box>
          </Box>
        </Box>
        {identity && (
          <Box
            basis='1/3'
          >
            <SubHeader title='Logo' />
            <Box
              align='center'
              pad='medium'
            >
              <Image
                a11yTitle={`${{ nameLong }} logo`}
                height='100em'
                width='100em'
                src={image}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeOrg: state.org.activeOrg,
  activeOrgOfficers: state.org.activeOrgOfficers,
  activeOrgStatus: state.org.activeOrgStatus
})

export default connect(mapStateToProps)(ClubHome)