import React from 'react'
import {
  Box,
  FormField,
  TextInput
} from 'grommet'

import PlayerSelect from '../../components/PlayerSelect'

const CalculatorRow = ({
  handicapId,
  handicapPlaceholder,
  label,
  nameId,
  namePlaceholder
}) => {
  return (
    <Box
      align='end'
      direction='row'
      gap='small'
      margin={{ bottom: 'small' }}
    >
      <FormField
        htmlFor={`${nameId}__input`}
        label={label}
        name={nameId}
        width='medium'
      >
        <PlayerSelect
          id={nameId}
          placeholder={namePlaceholder}
        />
      </FormField>
      <FormField
        htmlFor={handicapId}
        label='Handicap'
        name={handicapId}
      >
        <TextInput
          id={handicapId}
          name={handicapId}
          placeholder={handicapPlaceholder}
          type='number'
        >
        </TextInput>
      </FormField>
    </Box>
  )
}

export default CalculatorRow