import React from 'react'
import {
  Box,
  Heading
} from 'grommet'
import { connect } from 'react-redux'

import SuccessBanner from '../SuccessBanner'
import ForgotEmail from './ForgotEmail'
import LoginForm from './LoginForm'

const Login = () => {
  const [forgotPassword, setForgotPassword] = React.useState(false)
  const [showResetSuccess, setShowResetSuccess] = React.useState(false)

  const onResetSuccess = () => {
    setForgotPassword(false)
    setShowResetSuccess(true)
  }

  return (
    <Box background='lightGray' height={{ min: '100vh' }}>
      <Box
        align='center'
        background='white'
        elevation='small'
        gap='small'
        margin='auto'
        pad='medium'
        width={{ max: '25em' }}
      >
        <Heading level='3'>
          Real Tennis Online
        </Heading>
        {showResetSuccess && (
          <SuccessBanner message='Password Successfully Reset!' />
        )}
        {forgotPassword ? (
          <ForgotEmail
            onResetSuccess={onResetSuccess}
            returnToLogin={() => setForgotPassword(false)}
          />
        ) : (
          <LoginForm
            setForgotPassword={() => {
              setForgotPassword(true)
              setShowResetSuccess(false)
            }}
          />
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  error: state.login.error
})

export default connect(mapStateToProps)(Login)