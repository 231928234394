import React from 'react'
import {
  Box,
  Button,
  DataTable,
  Text,
  TextInput
} from 'grommet'

const COLUMNS = [
  { header: 'Day' },
  { header: 'Date' },
  { header: 'Start' },
  { header: 'End' },
  { header: 'Description' },
]

export default function FindAGame() {
  return (
    <Box>
      <Text>Find a Game</Text>
      <Box
        alignSelf='start'
        gap='small'
      >
        <TextInput
          type='number'
        />
        <Button
          label='Filter by Handicap'
        />
      </Box>
      <DataTable
        columns={COLUMNS}
      />
    </Box>
  )
}