import React from 'react'
import {
  AccordionPanel,
  Box,
  Heading
} from 'grommet'
import styled from 'styled-components'

import CommonAnchor from '../CommonAnchor'

const StyledBox = styled(Box)`
  display: table;
`

const CollapsableSection = ({
  activeRoute,
  id,
  route
}) => {
  const isActiveRoute = activeRoute?.id === route.id;
  const inactiveRoute = route.requiresId && !isActiveRoute
  if (!route.subRoutes.length || inactiveRoute) {
    return (
      <StyledBox border='bottom' pad={{ horizontal: '3px' }}>
        <CommonAnchor
          color='rgb(68,68,68)'
          label={<Heading level='4' margin='small'>{route.label}</Heading>}
          url={route.href}
        />
      </StyledBox>
    )
  }

  return (
    <AccordionPanel label={route.label} margin={{ vertical: 'none' }}>
      <Box background="light-2">
        {route.subRoutes.map(subroute => {
          const url = id && route.requiresId ? `${route.href}/${id}${subroute.href}` : `${route.href}${subroute.href}`
          return (
            <CommonAnchor
              color='rgb(68,68,68)'
              label={<Heading level='4' margin='small'>{subroute.label}</Heading>}
              url={url}
            />
          )
        })}
      </Box>
    </AccordionPanel>
  )
}

export default CollapsableSection;