import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'

const SET_COURTS = 'SET_COURTS'

const initialState = {
  courts: []
};

const courtReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURTS:
      return Object.assign({}, state, {
        courts: action.courts
      });

    default:
      return state;
  }
};

const getCourts = () => {
  return (dispatch) => {
    return rtoClient.get(VERBS.COURT)
      .then(res => {
        const { items } = res.data
        items.sort((a, b) => (a.nameLong > b.nameLong) ? 1 : -1)

        dispatch({
          type: SET_COURTS,
          courts: items
        })
      })
      .catch(err => console.warn(err))
  }
}

export default courtReducer;

export {
  getCourts
}