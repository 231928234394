import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
  faFlag,
  faStar
} from '@fortawesome/free-solid-svg-icons'
import { Text } from 'grommet'

const COLUMNS = [
  {
    property: 'player',
    header: 'Player',
    render: (datum) => <Text weight='normal'>{datum.player}</Text>
  }, {
    property: 'country',
    header: <FontAwesomeIcon icon={faFlag} />
  }, {
    property: 'handicap',
    header: 'Hcap'
  }, {
    property: 'curRank',
    header: 'Current Ranking'
  }, {
    property: 'prevRank',
    header: 'Previous Ranking'
  }, {
    property: 'change',
    header: 'Chg',
    render: (datum) => {
      switch (datum.change) {
        case CHANGES.INCREASE:
          return <FontAwesomeIcon color='green' icon={faAngleUp} />
        case CHANGES.DECREASE:
          return <FontAwesomeIcon color='red' icon={faAngleDown} />
        default:
          return <FontAwesomeIcon color='gold' icon={faStar} />
      }
    }
  }
]

export const CHANGES = {
  DECREASE: 'decrease',
  FLAT: 'flat',
  INCREASE: 'increase'
}

export const MOCK_DATA = [
  {
    player: 'Jerry Cummins',
    country: 'UK',
    handicap: '50.0',
    curRank: '2805',
    prevRank: '2532',
    change: CHANGES.DECREASE
  }, {
    player: 'Jeremy de Halpert',
    country: 'UK',
    handicap: '50.0',
    curRank: '2805',
    prevRank: '2532',
    change: CHANGES.DECREASE
  }, {
    player: 'Fabrice De L\'Epine',
    country: 'FR',
    handicap: '50.0',
    curRank: '2805',
    prevRank: '2715',
    change: CHANGES.DECREASE
  }, {
    player: 'Jules de Poorter',
    country: 'FR',
    handicap: '50.0',
    curRank: '2805',
    prevRank: '4377',
    change: CHANGES.INCREASE
  }, {
    player: 'Phaon Derr',
    country: 'US',
    handicap: '50.0',
    curRank: '2805',
    prevRank: '',
    change: CHANGES.FLAT
  }
]

export default COLUMNS