import React from 'react'
import { connect } from 'react-redux'
import {
  Box,
  DataTable,
  FormField,
  Layer,
  Select
} from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import EditButton from '../../components/EditButton'
import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'
import PrimaryButton from '../../components/PrimaryButton'
import Spinner from '../../components/Spinner'
import { getOrgs } from '../../ducks/org'
import { getMemberships } from '../../ducks/player'
import STATES from '../../helpers/async'
import NewMembershipModal from './NewMembershipModal'

const getColumns = (setActiveMembership) => {
  return [
    {
      property: 'action',
      header: 'Action',
      render: (datum) => <EditButton label='Edit' onClick={() => setActiveMembership(datum)} plain />
    }, {
      property: 'orgNameLong',
      header: 'Club/Association',
    }, {
      property: 'orgType',
      header: 'Type'
    }, {
      property: 'orgCategoryCode',
      header: 'Membership Category'
    }, {
      property: 'isPrimaryOrg',
      header: 'Primary Club',
      render: (datum) => datum.isPrimaryOrg ? <FontAwesomeIcon color='green' icon={faCheck} /> : null
    }
  ]
}

const parseOrgs = (memberships, orgs) => {
  const memberOrgIds = memberships.map(membership => membership.orgID)
  return orgs.filter(org => !memberOrgIds.includes(org.identity))
}

const Memberships = ({
  activePlayer,
  dispatch,
  memberships = [],
  membershipStatus,
  orgs = [],
  user
}) => {
  parseOrgs(memberships, orgs)
  const [activeMembership, setActiveMembership] = React.useState(null)
  const [membershipToAdd, setMembershipToAdd] = React.useState(null)
  const [addModalIsOpen, setAddModalIsOpen] = React.useState(false)

  const onAddMembership = (membership) => {
    setActiveMembership(membership)
    setAddModalIsOpen(true)
  }

  React.useEffect(() => {
    dispatch(getMemberships())

    if (!orgs.length) {
      dispatch(getOrgs())
    }
  }, [
    activePlayer,
    dispatch,
    memberships.length,
    orgs
  ])

  if (membershipStatus === STATES.FETCHING) {
    return (
      <Box align='center' fill>
        <Spinner label='Loading' />
      </Box>
    )
  }

  const closeModal = () => {
    setAddModalIsOpen(false)
    setMembershipToAdd(null)
    setActiveMembership(null)
  }

  return (
    <Box>
      {addModalIsOpen && (
        <Layer onClickOutside={() => closeModal()}>
          <NewMembershipModal
            activeMembership={activeMembership}
            closeModal={closeModal}
            orgID={activeMembership?.orgID || membershipToAdd?.identity}
            orgName={activeMembership?.orgNameLong || membershipToAdd?.nameRTO}
            user={user}
          />
        </Layer>
      )}
      <Header title='Memberships' />
      {!memberships.length ? (
        <Box align='center'>
          <NoneFound message='No memberships were found.' />
        </Box>
      ) : (
        <DataTable
          columns={getColumns(onAddMembership)}
          data={memberships}
          onClickRow={(e) => onAddMembership(e.datum)}
        />
      )}
      <Box
        align='center'
        direction='row'
        gap='small'
        margin={{ top: 'medium', horizontal: 'auto' }}
      >
        <FormField
          htmlFor='addMembership__input'
          label='Add Membership'
          margin='none'
          name='addMembership'
        >
          <Select
            id='addMembership'
            labelKey='nameRTO'
            name='addMembership'
            options={parseOrgs(memberships, orgs)}
            onChange={(e) => setMembershipToAdd(e.value)}
            value={membershipToAdd}
          />
        </FormField>
        <PrimaryButton
          a11yTitle='Add selected membership'
          alignSelf='end'
          disabled={!membershipToAdd}
          label='Add'
          onClick={() => setAddModalIsOpen(true)}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activePlayer: state.player.activePlayer,
  memberships: state.player.memberships,
  membershipStatus: state.player.membershipStatus,
  orgs: state.org.orgs,
  user: state.player.activePlayer
})

export default connect(mapStateToProps)(Memberships)