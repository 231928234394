import rtoClient from '../helpers/client'
import { getCountries } from './country';
import { getPlayerTypes } from './player';
import { fetchUser } from './login';

const SET_INITIALIZATION = 'SET_INITIALIZATION'

const initialState = {
  isInitialized: false
};

const initializeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIALIZATION:
      return Object.assign({}, state, {
        isInitialized: action.isInitialized
      });

    default:
      return state;
  }
};

const initializeApp = () => {
  return async (dispatch) => {
    rtoClient.initialize()
    const userId = await rtoClient.getBearerToken()
    if (userId) {
      dispatch(getCountries())
      dispatch(getPlayerTypes())
      await dispatch(fetchUser(userId))
    }
    dispatch({
      type: SET_INITIALIZATION,
      isInitialized: true
    })
  }
}

export default initializeReducer;

export {
  initializeApp
}