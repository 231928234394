import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'

const SET_COUNTRIES = 'SET_COUNTRIES'

const initialState = {
  countries: []
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return Object.assign({}, state, {
        countries: action.countries
      });

    default:
      return state;
  }
};

const getCountries = () => {
  return (dispatch) => {
    return rtoClient.get(VERBS.COUNTRIES)
      .then(res => {
        const { items } = res.data

        dispatch({
          type: SET_COUNTRIES,
          countries: items.reverse()
        })
      })
      .catch(err => console.warn(err))
  }
}

export default countryReducer;

export {
  getCountries
}