import React from 'react'
import { Box } from 'grommet'

import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'
import RankingsControls from './RankingsControls'
import RankedPlayers from './RankedPlayers'
import ByHandicap from './ByHandicap'

export default function Rankings() {
  return (
    <Box>
      <Header title='Handicap Rankings' />
      {false ? (
        <NoneFound message='No handicaps were found for this person.' />
      ) : (
        <Box>
          <RankingsControls />
          <Box>
            <RankedPlayers />
            <ByHandicap />
          </Box>
        </Box>
      )}
    </Box>
  )
}