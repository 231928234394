import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  TextInput
} from 'grommet'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Header from '../../components/Header'
import {
  getConfiguration,
  setConfiguration
} from '../../ducks/configuration'

const StyledForm = styled(Form)`
  max-width: 20em;
`

const Settings = ({
  configuration,
  dispatch
}) => {
  const [values, setValues] = React.useState({
    category1HandicapThreshold: 0,
    category2HandicapThreshold: 0,
    handicapPurgePeriod: 0
  })

  React.useEffect(() => {
    const valSize = Object.keys(configuration).length
    if (!valSize) {
      dispatch(getConfiguration())
    }
    if (valSize) {
      setValues(configuration)
    }
  }, [configuration, dispatch])

  return (
    <Box>
      <Header title='Settings' />
      <Box align='center'>
        <StyledForm
          onChange={val => setValues(val)}
          value={values}
        >
          <FormField
            htmlFor='category1HandicapThreshold'
            label='Category 1 Handicap Threshold'
            name='category1HandicapThreshold'
          >
            <TextInput
              id='category1HandicapThreshold'
              name='category1HandicapThreshold'
              step='0.1'
              type='number'
              value={values.category1HandicapThreshold}
            />
          </FormField>
          <FormField
            htmlFor='category2HandicapThreshold'
            label='Category 2 Handicap Threshold'
            name='category2HandicapThreshold'
          >
            <TextInput
              id='category2HandicapThreshold'
              name='category2HandicapThreshold'
              step='0.1'
              type='number'
              value={values.category2HandicapThreshold}
            />
          </FormField>
          <FormField
            htmlFor='handicapPurgePeriod'
            label='Handicap Purge Period (Months)'
            name='handicapPurgePeriod'
          >
            <TextInput
              id='handicapPurgePeriod'
              name='handicapPurgePeriod'
              type='number'
              value={values.handicapPurgePeriod}
            />
          </FormField>
          <Box align='center'>
            <Button
              label='Save'
              onClick={() => dispatch(setConfiguration(values))}
              primary
            />
          </Box>
        </StyledForm>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  configuration: state.configuration.state
})

export default connect(mapStateToProps)(Settings)