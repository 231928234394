import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Button
} from 'grommet'

import SubHeader from './SubHeader'

const Modal = ({
  children,
  label,
  onClose,
  width = 'medium'
}) => {
  return (
    <Box
      gap='small'
      pad='medium'
      width={{ min: width }}
    >
      <Box
        align='center'
        direction='row'
        justify='between'
      >
        <SubHeader
          margin={{ right: 'large' }}
          title={label}
        />
        <Button
          a11yTitle='Close Modal'
          label={<FontAwesomeIcon icon={faTimes} />}
          onClick={() => onClose()}
          plain
        />
      </Box>
      {children}
    </Box>
  )
}

export default Modal