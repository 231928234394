import React from 'react'
import { Box } from 'grommet'
import { connect } from 'react-redux'

import Header from '../../components/Header'

const ClubCodes = () => {

  return (
    <Box>
      <Header title='Codes' />
    </Box>
  )
}

export default connect()(ClubCodes)