const VERBS = {
  CALCULATE_ODDS: '/Odds/Calculate',
  CONFIGURATION: '/Configuration',
  COUNTRIES: '/Country',
  COURT: '/Court',
  HANDICAP_FLAG: '/HandicapFlag',
  HANDICAP_MOVEMENTS: '/HcapMovement',
  MATCH: '/Match',
  MATCHES: '/Matches',
  MOVEMENTS: '/Movements',
  ORGANIZATIONS: '/Orgs',
  ORG: '/Org',
  ORG_CATEGORY: '/OrgCategory',
  ORG_COURT: '/OrgCourt',
  ORG_OFFICER: '/OrgOfficer',
  ORG_MEMBER: '/OrgMember',
  PERSON: '/Person',
  PERSON_SEARCH: '/Person/Search',
  PLAYER: '/Player',
  PLAYER_TYPE: '/PlayerType',
  SANCTIONED_MATCH: '/SanctionedMatch'
}

export default VERBS