import React from 'react'
import {
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  Select,
  Text,
  TextInput
} from 'grommet'
import { connect } from 'react-redux'
import styled from 'styled-components'

import DangerButton from '../../../components/DangerButton'
import Modal from '../../../components/Modal'
import PrimaryButton from '../../../components/PrimaryButton'
import rtoClient from '../../../helpers/client'
import VERBS from '../../../helpers/verbs'
import { getCourts } from '../../../ducks/court'

const StyledFormField = styled(FormField)`
  > div {
    border: 0;
    padding: 0.2em;
  }
`

const DEFAULT = {
  countryID: 0,
  isInPlay: false,
  nameLong: '',
  nameShort: ''
}

const EditCourt = ({
  countries,
  courtToEdit,
  dispatch,
  onClose
}) => {
  const [values, setValues] = React.useState(DEFAULT)

  React.useEffect(() => {
    if (courtToEdit) {
      setValues(courtToEdit)
    }
  }, [courtToEdit])

  const onSubmit = (deleteValue) => {
    const params = Object.assign(values, {})

    if (typeof deleteValue !== 'undefined') {
      params.isDeleted = deleteValue
    }

    if (courtToEdit) {
      rtoClient.put(`${VERBS.COURT}/${courtToEdit.identity}`, params)
        .then(res => {
          dispatch(getCourts())
          onClose()
        })
        .catch(err => {
          console.warn(err)
          onClose()
        })
    } else {
      rtoClient.post(VERBS.COURT, params)
        .then(res => {
          dispatch(getCourts())
          onClose()
        })
        .catch(err => {
          console.warn(err)
          onClose()
        })
    }
  }

  const disableSubmit = !values.nameLong?.length && !values.nameShort?.length
  const action = courtToEdit ? 'Edit' : 'Create'

  return (
    <Modal
      label={`${action} Court`}
      onClose={onClose}
    >
      <Text size='small'>
        Save a new court in the RTO database
      </Text>
      <Form
        onChange={(next) => setValues(next)}
        onSubmit={() => onSubmit()}
      >
        <FormField
          htmlFor='nameLong'
          label='Name'
          name='nameLong'
        >
          <TextInput
            id='nameLong'
            name='nameLong'
            size='small'
            value={values.nameLong}
          />
        </FormField>
        <FormField
          htmlFor='nameShort'
          label='Code'
          name='nameShort'
        >
          <TextInput
            id='nameShort'
            name='nameShort'
            size='small'
            value={values.nameShort}
          />
        </FormField>
        <FormField
          htmlFor='countryID__input'
          label='Country'
          name='countryID'
        >
          <Select
            id='countryID'
            labelKey={(e) => e.nameLong}
            name='countryID'
            options={countries}
            valueKey={{ key: 'identity', reduce: true }}
            value={values.countryID}
          />
        </FormField>
        <StyledFormField name='isInPlay'>
          <CheckBox
            checked={values.isInPlay}
            name='isInPlay'
            label='Is the court in play?'
          />
        </StyledFormField>
        <Box
          justify='center'
          direction='row'
          gap='small'
          margin={{ top: 'medium' }}
        >
          <Button
            label='Cancel'
            onClick={onClose}
          />
          {courtToEdit && (
            <DangerButton
              label={courtToEdit.isDeleted ? 'Reactivate' : 'Delete'}
              onClick={() => onSubmit(!courtToEdit.isDeleted)}
            />
          )}
          <PrimaryButton
            disabled={disableSubmit}
            label='Submit'
            type='submit'
          />
        </Box>
      </Form>
    </Modal>
  )
}

export default connect()(EditCourt)