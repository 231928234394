import React from 'react'
import {
  Box,
  DataTable,
  FormField,
  Layer
} from 'grommet'
import { connect } from 'react-redux'

import PrimaryButton from '../../components/PrimaryButton'
import EditButton from '../../components/EditButton'
import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'
import PlayerSelect from '../../components/PlayerSelect'
import ClubOfficerEdit from './ClubOfficerEdit'

const getColumns = (onEdit) => {
  return [
    {
      property: '',
      header: 'Action',
      render: (datum) => <EditButton label='Edit' onClick={() => onEdit(datum)} plain />
    }, {
      property: 'nameFirstLastTag',
      header: 'Officer'
    }, {
      property: 'title',
      header: 'Title'
    }, {
      property: 'startDate',
      header: 'Start'
    }, {
      property: 'endDate',
      header: 'End'
    }
  ]
}

const ClubOfficers = ({
  activeOrg = {},
  activeOrgOfficers = []
}) => {
  const [selectedOfficer, setSelectedOfficer] = React.useState(null)
  const [selectedPlayer, setSelectedPlayer] = React.useState(null)
  const [player, setPlayer] = React.useState(null)
  const onClose = () => {
    setSelectedOfficer(null)
    setSelectedPlayer(null)
    setPlayer(null)
  }

  return (
    <Box>
      {(selectedOfficer || player) && (
        <Layer onClickOutside={onClose}>
          <ClubOfficerEdit
            officer={selectedOfficer}
            onClose={onClose}
            org={activeOrg}
            player={player}
          />
        </Layer>
      )}
      <Box gap='small'>
        <Header title='Officers' />
        {!activeOrgOfficers.length ? (
          <Box align='center'>
            <NoneFound message='No Officers Found' />
          </Box>
        ) : (
          <DataTable
            columns={getColumns((e) => setSelectedOfficer(e))}
            data={activeOrgOfficers}
            onClickRow={e => setSelectedOfficer(e.datum)}
          />
        )}
        <Box
          direction='row'
          gap='small'
          justify='center'
        >
          <FormField
            htmlFor='officer__input'
            label='Add officer'
            margin='none'
            name='officer'
          >
            <PlayerSelect
              id='officer'
              onChange={(e) => setSelectedPlayer(e.value)}
              value={selectedPlayer}
            />
          </FormField>
          <PrimaryButton
            alignSelf='end'
            disabled={!selectedPlayer}
            label='Add'
            onClick={() => setPlayer(selectedPlayer)}
          />
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeOrg: state.org.activeOrg,
  activeOrgOfficers: state.org.activeOrgOfficers
})

export default connect(mapStateToProps)(ClubOfficers)