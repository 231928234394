import React from 'react'
import {
  Box,
  Text
} from 'grommet'

export default function DangerBanner({
  message
}) {
  return (
    <Box
      align='center'
      background='danger'
      margin={{ vertical: 'small' }}
      pad='xsmall'
      round='xsmall'
      width='100%'
    >
      <Text
        color='white'
        size='small'
      >
        {message}
      </Text>
    </Box>
  )
}