import React from 'react'
import {
  Anchor,
  Box,
  Table,
  TableBody,
  TableHeader,
  TableRow
} from 'grommet'
import styled from 'styled-components'

const PrimaryHeader = styled.td`
  border-bottom: 1px dotted black;
  text-align: end;
`

const SecondaryHeader = styled.td`
  background-color: #f4f4df;
  text-align: ${props => props.left ? 'start' : 'center'};
`

const Cell = styled.td`
  text-align: ${props => props.left ? 'start' : 'center'};
`

const StyledRow = styled(TableRow)`
  td {
    padding: 5px;
  }
`

const TermTable = () => {
  const oneMonthAgo = new Date()
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)

  return (
    <Box>
      <Table>
        <TableHeader>
          <StyledRow>
            <td></td>
            <PrimaryHeader colSpan='5'>Short Term</PrimaryHeader>
            <PrimaryHeader colSpan='3'>Long Term</PrimaryHeader>
          </StyledRow>
          <StyledRow>
            <SecondaryHeader left>Player</SecondaryHeader>
            <SecondaryHeader>Wins & Losses</SecondaryHeader>
            <SecondaryHeader colSpan='4'>Handicap Movement</SecondaryHeader>
            <SecondaryHeader colSpan='3'>Handicap Movement</SecondaryHeader>
          </StyledRow>
          <StyledRow>
            <Cell></Cell>
            <Cell></Cell>
            <Cell>Alert</Cell>
            <Cell>Open</Cell>
            <Cell></Cell>
            <Cell>Close</Cell>
            <Cell>Open</Cell>
            <Cell></Cell>
            <Cell>Close</Cell>
          </StyledRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <Cell left>
              <Anchor
                label='Will Granger'
              />
            </Cell>
            <Cell></Cell>
            <Cell></Cell>
            <Cell>50.0</Cell>
            <Cell>
              <svg width='100' height='10'>
                <rect y='5' width='100' height='1' />
                <circle cx='20' cy='5' r='3' fill='red' />
              </svg>
            </Cell>
            <Cell>50.0</Cell>
            <Cell>50.0</Cell>
            <Cell>
              <svg width='100' height='10'>
                <rect y='5' width='100' height='1' />
                <circle cx='20' cy='5' r='3' fill='red' />
              </svg>
            </Cell>
            <Cell>50.0</Cell>
          </TableRow>
        </TableBody>
      </Table>
    </Box >
  )
}

export default TermTable