import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  Select
} from 'grommet'

const ACTIVITY = [
  'All (includes inactive players)',
  'Active in previous 6 months',
  'Active in previous 12 months'
]
const AGE = [
  'All',
  'Under 25',
  'Over 40',
  'Over 50',
  'Over 60',
  'Over 70',
  'Over 80'
]
const COUNTRIES = ['All (World Rankings)', 'Afghanistan']
const DATE = ['01-Aug-2019']
const GENDER = ['All', 'Female', 'Male']
const RANKINGS = ['My Ranking', 'Top 25', 'Top 50']
const STATUS = [
  'All',
  'Amateurs',
  'Professional',
  'Category 1'
]
const TYPES = ['Singles', 'Doubles']

export default function RankingsControls() {
  return (
    <Box
      background='ash'
      pad='small'
    >
      <Form>
        <Box
          direction='row'
          gap='small'
        >
          <Box>
            <FormField label='Handicap Type' name='type'>
              <Select
                defaultValue={TYPES[0]}
                name='type'
                options={TYPES}
              />
            </FormField>
            <FormField label='Players' name='players'>
              <Select
                defaultValue={COUNTRIES[0]}
                name='players'
                options={COUNTRIES}
              />
            </FormField>
            <FormField label='Activity' name='activity'>
              <Select
                defaultValue={ACTIVITY[0]}
                name='activity'
                options={ACTIVITY}
              />
            </FormField>
          </Box>
          <Box>
            <FormField label='Rankings' name='rankings'>
              <Select
                defaultValue={RANKINGS[0]}
                name='rankings'
                options={RANKINGS}
              />
            </FormField>
            <FormField label='Gender' name='gender'>
              <Select
                defaultValue={GENDER[0]}
                name='gender'
                options={GENDER}
              />
            </FormField>
            <FormField label='Age' name='age'>
              <Select
                defaultValue={AGE[0]}
                name='age'
                options={AGE}
              />
            </FormField>
          </Box>
          <Box>
            <FormField label='Date' name='date'>
              <Select
                defaultValue={DATE[0]}
                name='date'
                options={DATE}
              />
            </FormField>
            <FormField label='Status' name='status'>
              <Select
                defaultValue={STATUS[0]}
                name='status'
                options={STATUS}
              />
            </FormField>
          </Box>
          <Box>
            <Button
              label='Refresh'
              size='small'
            />
          </Box>
        </Box>
      </Form>
    </Box>
  )
}