import React from 'react'
import { Box } from 'grommet'

import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'

export default function Messages() {
  return (
    <Box>
      <Header title='Messages' />
      <NoneFound message='No messages were found for this person.' />
    </Box>
  )
}