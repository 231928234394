import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import {
  Box,
  Text
} from 'grommet'

export default function Spinner({
  label
}) {
  return (
    <Box
      align='center'
      direction='row'
      gap='small'
    >
      {label && (
        <Text color='olive'>{label}</Text>
      )}
      <FontAwesomeIcon
        color='olive'
        icon={faSpinner}
        spin
      />
    </Box>
  )
}