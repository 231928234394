import React from 'react'
import {
  Box,
  Form,
  FormField,
  Select,
  TextInput
} from 'grommet'

import DateSelect from '../../components/DateSelect'
import Modal from '../../components/Modal'
import PrimaryButton from '../../components/PrimaryButton'

const DEFAULT = {
  name: ''
}

export default function NewPrivilege({
  onClose,
  orgs,
  privilege
}) {
  const [values, setValues] = React.useState(DEFAULT)
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)

  const onSubmit = () => {
    const dates = {
      endDate: endDate && endDate.toJSON(),
      startDate: startDate && startDate.toJSON(),
    }
    const params = Object.assign(values, dates)
    console.log(params);
  }

  return (
    <Modal
      label='Create New Privilege'
      onClose={onClose}
    >
      <Form
        onChange={(next) => setValues(next)}
        onSubmit={onSubmit}
      >
        <FormField
          label='Privilege'
          name='name'
          required
        >
          <TextInput
            name='name'
            value={values.roleLong}
          />
        </FormField>
        <DateSelect
          date={startDate}
          label='Beginning Date'
          name='startDate'
          setDate={(date) => setStartDate(date)}
        />
        <DateSelect
          date={endDate}
          label='Expiry Date'
          name='endDate'
          setDate={(date) => setEndDate(date)}
        />
        <FormField
          htmlFor='orgID__input'
          label='Organisation'
          margin='none'
          name='orgID'
        >
          <Select
            id='orgID'
            labelKey='nameRTO'
            name='orgID'
            options={orgs}
            valueKey={{ key: 'identity', reduce: true }}
            value={values.orgID || ''}
          />
        </FormField>
        <Box
          direction='row'
          gap='small'
          justify='center'
          margin={{ top: 'medium' }}
        >
          <PrimaryButton
            label='Submit'
            type='submit'
          />
        </Box>
      </Form>
    </Modal>
  )
}