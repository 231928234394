import {
  Select,
  Text
} from 'grommet'
import StyledFormField from './StyledFormField'

const SelectMenu = ({
  id,
  items = [],
  label,
  labelKey,
  name,
  onChange = () => { },
  onSearch,
  required = false,
  selection,
  valueKey
}) => {
  return (
    <StyledFormField
      htmlFor={`${id}__input`}
      label={<Text size='small'>{label}</Text>}
      name={name}
      required={true}
    >
      <Select
        id={id}
        labelKey={labelKey}
        name={name}
        onChange={onChange}
        onSearch={onSearch}
        options={items}
        size='small'
        valueKey={valueKey}
        value={selection}
      />
    </StyledFormField>
  )
}

export default SelectMenu