import Amplify, { Auth } from 'aws-amplify'

export function initializeAws() {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_CLIENT,
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  })
  return Auth.configure()
}
