import React from 'react'
import {
  Box,
  Button,
  Nav,
  Text
} from 'grommet'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { useHistory } from 'react-router-dom'

import TopNav from './TopNav'
import {
  bookingRoutes,
  IDS,
  mainRoutes
} from './routes'
import SubNav from './SubNav'
import getUserName from '../../helpers/getUserName'
import { logout } from '../../ducks/login'

const LogoutButton = styled(Button)`
  border: 1px solid white;

  &:hover, &:focus {
    background: white;
    color: black;
  }
`

const findId = (
  activeRoute,
  activePlayer,
  activeOrg
) => {
  const type = activeRoute?.id
  const personName = activePlayer ? `${activePlayer.nameFirst || ''} ${activePlayer.nameLast || ''}` : ''

  switch (type) {
    case IDS.PERSON:
      return [activePlayer?.personID, personName]
    case IDS.ORG:
      return [activeOrg?.identity, activeOrg?.nameLong || '']
    default:
      return [null, activeRoute?.label]
  }
}

const Navigation = ({
  activeOrg,
  activePlayer,
  dispatch,
  location,
  user = {}
}) => {
  const history = useHistory()
  const isBookingRoute = location.pathname.indexOf('bookings') >= 0
  const routes = isBookingRoute ? bookingRoutes : mainRoutes
  const [activeRoute, setActiveRoute] = React.useState(null)
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    const activatedRoute = routes.find(route => route.href.length > 1 && location.pathname.includes(route.href))
    setActiveRoute(activatedRoute)
    if (!initialized) {
      setInitialized(true)
    }
  }, [initialized, location.pathname, routes])

  const [entityId, entityLabel] = findId(activeRoute, activePlayer, activeOrg)

  return (
    <Box>
      <Nav
        background={isBookingRoute ? 'clay' : 'olive'}
        direction='row'
        gap='none'
        wrap
      >
        <TopNav
          activeRoute={activeRoute}
          id={entityId}
          routes={routes}
        />
        <Box
          align='center'
          direction='row'
          justify='center'
          gap='small'
          margin={{ left: 'auto', right: 'small' }}
        >
          <Text size='small'>{getUserName(user)}</Text>
          <LogoutButton
            label='Logout'
            onClick={() => {
              dispatch(logout())
              history.push('/')
            }}
            size='small'
          />
        </Box>
      </Nav>
      <Box
        background={isBookingRoute ? 'lightClay' : 'lightOlive'}
        direction='row'
        gap='xsmall'
        pad='small'
      >
        {activeRoute?.subRoutes.length ? (
          <SubNav
            id={entityId}
            label={entityLabel}
            requiresId={activeRoute.requiresId}
            root={activeRoute.href}
            routes={activeRoute.subRoutes}
          />
        ) : null}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeOrg: state.org.activeOrg,
  activePlayer: state.player.activePlayer,
  user: state.login.user
})

export default withRouter(connect(mapStateToProps)(Navigation))