import React from 'react'
import { Box, Text } from 'grommet'

export default function Banner() {
  return (
    <Box
      align='center'
      background='dust'
      pad='xxsmall'
    >
      <Text
        size='xsmall'
      >
        This website is powered by Real Tennis Online - a joint initiative of ARTA, USCTA, USCTPF, Comité Français and the T&RA.
      </Text>
    </Box>
  )
}