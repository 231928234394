import React from 'react'
import {
  Box,
  Select,
  Text
} from 'grommet'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Header from '../../components/Header'
import MatchTable from '../../components/Tables/MatchTable'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'
import { startEndDates, TIME_SPAN } from '../../helpers/timeSpan'
import NoneFound from '../../components/NoneFound'

const ClubMatches = ({
  activeOrg = {},
  activeOrgCourts = [],
  dispatch
}) => {
  const [selectedCourt, selectCourt] = React.useState(null)
  const [matches, setMatches] = React.useState([])

  React.useEffect(() => {
    if (!!selectedCourt) {
      const params = { courtId: selectedCourt.identity }
      const [startDate, endDate] = startEndDates(TIME_SPAN.ONE_MONTH)
      rtoClient.get(`${VERBS.MATCH}/${startDate.toJSON()}/${endDate.toJSON()}`, params)
        .then(res => setMatches(res.data?.items))
        .catch(err => console.warn(err))
    }
  }, [selectedCourt])

  React.useEffect(() => {
    if (activeOrgCourts.length) {
      selectCourt(activeOrgCourts[0])
    }
  }, [
    activeOrg,
    activeOrgCourts,
    dispatch
  ])

  const containsMultipleCourts = activeOrgCourts.length > 1

  return (
    <Box>
      <Header title='Recent Matches' />
      {containsMultipleCourts && (
        <Box
          align='center'
          direction='row'
          gap='small'
          margin={{ bottom: 'small', left: 'auto' }}
        >
          <Text size='small'>Select Court</Text>
          <Select
            labelKey={(e) => e.nameLong}
            onChange={e => selectCourt(e.value)}
            options={activeOrgCourts}
            size='small'
            value={selectedCourt}
          />
        </Box>
      )}
      {matches.length > 0 ? (
        <MatchTable matches={matches} />
      ) : (
        <Box align='center'>
          <NoneFound message='No matches found in the past month' />
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeOrg: state.org.activeOrg,
  activeOrgCourts: state.org.activeOrgCourts,
})

export default withRouter(connect(mapStateToProps)(ClubMatches))