import React from 'react'
import { Box } from 'grommet'
import { connect } from 'react-redux'

import Header from '../../components/Header'

const ClubMembershipChanges = () => {
  return (
    <Box>
      <Header title='Membership Changes' />
    </Box>
  )
}

export default connect()(ClubMembershipChanges)