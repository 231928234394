import React from 'react'
import { Box } from 'grommet'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'

import ClubCodes from './ClubCodes'
import ClubCourts from './ClubCourts'
import ClubDetails from './ClubDetails'
import ClubHome from './ClubHome'
import ClubMatches from './ClubMatches'
import ClubMembershipCategories from './ClubMembershipCats'
import ClubMembershipChanges from './ClubMembershipChanges'
import ClubOfficers from './ClubOfficers'
import Match from '../Match'
import { setOrgById } from '../../ducks/org'

const Club = ({
  activeOrg,
  dispatch,
  match
}) => {
  const orgId = match.params.id

  React.useEffect(() => {
    dispatch(setOrgById(orgId))
  }, [dispatch, orgId])

  if (!activeOrg) {
    return null
  }

  return (
    <Box>
      <Route exact path={`${match.path}/`} component={ClubHome} />
      <Route exact path={`${match.path}/details`} component={ClubDetails} />
      <Route exact path={`${match.path}/matches`} component={ClubMatches} />
      <Route exact path={`${match.path}/officers`} component={ClubOfficers} />
      <Route exact path={`${match.path}/membership-categories`} component={ClubMembershipCategories} />
      <Route exact path={`${match.path}/membership-changes`} component={ClubMembershipChanges} />
      <Route exact path={`${match.path}/codes`} component={ClubCodes} />
      <Route exact path={`${match.path}/courts`} component={ClubCourts} />
      <Route exact path={`${match.path}/matches/match`} component={Match} />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeOrg: state.org.activeOrg
})

export default connect(mapStateToProps)(Club)