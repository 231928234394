import React from 'react'
import { Select } from 'grommet'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'

import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'

const MIN_CHARACTERS = 3

const PlayerSelect = ({
  disabled = false,
  id,
  onChange,
  placeholder,
  value
}) => {
  const [players, setPlayers] = React.useState([])

  React.useEffect(() => {
    if (value) {
      setPlayers([value])
    }
  }, [value])

  const getNames = (name) => {
    return rtoClient.get(`${VERBS.PERSON_SEARCH}/${name}`, { pageSize: 40 })
      .then(res => {
        if (res && res.data && res.data.items) {
          setPlayers(res.data.items)
        }
        return []
      })
  }

  const debouncedAction = React.useMemo(
    () => debounce(val => getNames(val), 1000), []
  );

  const handleChange = React.useCallback(
    val => {
      if (val.length < MIN_CHARACTERS) {
        setPlayers([])
      } else {
        debouncedAction(val)
      }
    },
    [debouncedAction]
  )

  return (
    <Select
      clear
      disabled={disabled}
      id={id}
      labelKey={(e) => `${e.nameFirst} ${e.nameLast}${e.nameTag ? ` [${e.nameTag}]` : ''}`}
      name={id}
      onChange={onChange}
      onSearch={text => handleChange(text)}
      options={players}
      placeholder={placeholder}
      plain
      size='small'
      value={value}
    />
  )
}

export default connect()(PlayerSelect)