import {
  Anchor,
  Text
} from 'grommet'

const COLUMNS = [
  {
    property: 'playerTypeLong',
    header: 'Handicap Type',
    render: (datum) => <Text weight='normal'>{datum.playerTypeLong}</Text>
  }, {
    property: 'hcap',
    header: 'Handicap'
  }, {
    property: 'cap',
    header: 'Cap'
  }, {
    property: 'isProvisional',
    header: 'Provisional',
    render: (datum) => <Text>{datum.isProvisional ? 'Yes' : 'No'}</Text>
  }, {
    property: 'provisionalPeriodLong',
    header: 'Provisional Period',
    render: (datum) => <Text>{datum.provisionalPeriodLong}</Text>
  }, {
    property: 'isCategory1',
    header: 'Category1',
    render: (datum) => <Text>{datum.isCategory1 ? 'Yes' : 'No'}</Text>
  }, {
    property: 'bestHcapLong',
    header: 'Best Handicap',
    render: (datum) => <Text>{datum.bestHcapLong}</Text>
  }, {
    property: 'ranking',
    header: 'Ranking',
    render: (datum) => <Anchor label='See Ranking *' href={datum.rankingLink} />
  }
]

export const mockData = [
  {
    handicapType: 'Singles',
    handicap: '50.0',
    cap: '',
    provisional: false,
    provisionalPeriod: '0',
    category1: false,
    bestHandicap: '50.0',
    bestHandicapDate: '26-Apr-2021',
    rankingLink: 'test.com'
  }, {
    handicapType: 'Doubles',
    handicap: '50.0',
    cap: '',
    provisional: false,
    provisionalPeriod: '0',
    category1: false,
    bestHandicap: '50.0',
    bestHandicapDate: '26-Apr-2021',
    rankingLink: 'test.com'
  }
]

export default COLUMNS