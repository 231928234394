import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  Text,
  TextInput
} from 'grommet'
import { connect } from 'react-redux'

import DangerButton from '../../../components/DangerButton'
import Modal from '../../../components/Modal'
import PrimaryButton from '../../../components/PrimaryButton'
import rtoClient from '../../../helpers/client'
import VERBS from '../../../helpers/verbs'
import { getCountries } from '../../../ducks/country'

const DEFAULT = {
  nameLong: '',
  nameShort: '',
  skin: 'rto'

}

const EditCountry = ({
  activeCountry,
  dispatch,
  onClose
}) => {
  const [values, setValues] = React.useState(DEFAULT)

  const action = !!activeCountry ? 'Edit' : 'Create New'

  const onSubmit = (deleteValue) => {
    const params = Object.assign(values, {})

    if (typeof deleteValue !== 'undefined') {
      params.isDeleted = deleteValue
    }

    if (activeCountry) {
      rtoClient.put(`${VERBS.COUNTRIES}/${activeCountry.identity}`, params)
        .then(res => {
          dispatch(getCountries())
          onClose()
        })
        .catch(err => {
          console.warn(err)
          onClose()
        })
    } else {
      rtoClient.post(VERBS.COUNTRIES, params)
        .then(res => {
          dispatch(getCountries())
          onClose()
        })
        .catch(err => {
          console.warn(err)
          onClose()
        })
    }
  }

  React.useEffect(() => {
    if (!!activeCountry) {
      setValues(activeCountry)
    }
  }, [activeCountry])

  return (
    <Modal
      label={`${action} Country`}
      onClose={onClose}
    >
      {activeCountry && (
        <Text size='small'>
          Enter a name to save a new country in the RTO database
        </Text>
      )}
      <Form
        onChange={(next) => setValues(next)}
        onSubmit={() => onSubmit()}
      >
        <FormField
          htmlFor='nameLong'
          label='Country'
          name='nameLong'
        >
          <TextInput
            id='nameLong'
            name='nameLong'
            size='small'
            value={values.nameLong}
          />
        </FormField>
        <FormField
          htmlFor='nameShort'
          label='Code'
          name='nameShort'
        >
          <TextInput
            id='nameShort'
            name='nameShort'
            size='small'
            value={values.nameShort}
          />
        </FormField>
        <Box
          justify='center'
          direction='row'
          gap='small'
          margin={{ top: 'medium' }}
        >
          <Button
            label='Cancel'
            onClick={() => onClose()}
          />
          {activeCountry && (
            <DangerButton
              label={activeCountry.isDeleted ? 'Reactivate' : 'Delete'}
              onClick={(e) => onSubmit(!activeCountry.isDeleted)}
            />
          )}
          <PrimaryButton
            label='Save'
            type='submit'
          />
        </Box>
      </Form>
    </Modal>
  )
}

export default connect()(EditCountry)