import React from 'react'
import {
  Box,
  Tab,
  Tabs,
} from 'grommet'

import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'

export default function MyBookings() {
  return (
    <Box gap='small'>
      <Header title='My Bookings' />
      <Box>
        <Tabs>
          <Tab title="Past Bookings">
            <Box align='center' margin='medium'>
              <NoneFound message='There are no past bookings to display' />
            </Box>
          </Tab>
          <Tab title="Future Bookings">
            <Box align='center' margin='medium'>
              <NoneFound message='There are no future bookings to display' />
            </Box>
          </Tab>
        </Tabs>
      </Box>
    </Box>
  )
}