import React from 'react'
import {
  Box,
  Button,
  Form,
  Layer,
  RadioButtonGroup
} from 'grommet'
import styled from 'styled-components'

import CalculatorRow from './CalculatorRow'
import OddsResult from './OddsResult'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'
import Header from '../../components/Header'

const StyledLegend = styled.legend`
  font-size: 0.75em;
`

const defaultValues = {
  playerOneName: '',
  playerOneHandicap: '',
  playerTwoName: '',
  playerTwoHandicap: '',
  playerThreeName: '',
  playerThreeHandicap: '',
  playerFourName: '',
  playerFourHandicap: ''
}

const MATCH_TYPE = {
  SINGLES: 'Singles',
  DOUBLES: 'Doubles'
}

function determineDisabled(values, matchType) {
  const singlesIsComplete = !!values.playerOneName && !!values.playerTwoName && !!values.playerOneHandicap && !!values.playerTwoHandicap
  const doublesIsComplete = !!values.playerThreeName && !!values.playerFourName && !!values.playerThreeHandicap && !!values.playerFourHandicap

  if (matchType === MATCH_TYPE.SINGLES) {
    return !singlesIsComplete
  }
  return !singlesIsComplete || !doublesIsComplete
}

export default function Calculators() {
  const [values, setValues] = React.useState(defaultValues)
  const [results, setResults] = React.useState(null)
  const [matchType, setMatchType] = React.useState(MATCH_TYPE.SINGLES)

  const onCalculateOdds = () => {
    const params = {
      player1: values.playerOneName.identity,
      player1Hcap: values.playerOneHandicap,
      player2: values.playerTwoName.identity,
      player2Hcap: values.playerTwoHandicap
    }
    if (values === MATCH_TYPE.DOUBLES) {
      params.player3 = values.playerThreeName.identity
      params.player3Hcap = values.playerThreeHandicap
      params.player4 = values.playerFourName.identity
      params.player4Hcap = values.playerFourHandicap
    }
    rtoClient.post(VERBS.CALCULATE_ODDS, params)
      .then(res => setResults(res.data))
      .catch(err => console.warn(err))
  }

  const reset = () => {
    setResults(null)
    setValues(defaultValues)
  }

  return (
    <Box>
      {results && (
        <Layer onClickOutside={() => reset()}>
          <OddsResult
            onClose={() => reset()}
            results={results || {}}
            values={values}
          />
        </Layer>
      )}
      <Header title='Odds Calculator' />
      <Box align='center' margin={{ top: 'medium' }}>
        <fieldset>
          <StyledLegend>Match Type</StyledLegend>
          <RadioButtonGroup
            direction='row'
            name='matchType'
            onChange={(event) => {
              if (event.target.value === MATCH_TYPE.DOUBLES) {
                const valCopy = Object.assign(values)
                valCopy.playerThreeName = ""
                valCopy.playerFourName = ""
                valCopy.playerThreeHandicap = ""
                valCopy.playerThreeHandicap = ""
                setValues(valCopy)
              }
              setMatchType(event.target.value)
            }}
            options={[MATCH_TYPE.SINGLES, MATCH_TYPE.DOUBLES]}
            value={matchType}
          />
        </fieldset>
        <Form onChange={nextValue => setValues(nextValue)}>
          {matchType === MATCH_TYPE.SINGLES ? (
            <Box>
              <CalculatorRow
                handicapId='playerOneHandicap'
                handicapPlaceholder='Player One Handicap...'
                label='Player One'
                nameId='playerOneName'
                namePlaceholder='Player One Name...'
              />
              <CalculatorRow
                handicapId='playerTwoHandicap'
                handicapPlaceholder='Player Two Handicap...'
                label='Player Two'
                nameId='playerTwoName'
                namePlaceholder='Player Two Name...'
              />
            </Box>
          ) : (
            <Box>
              <CalculatorRow
                handicapId='playerOneHandicap'
                handicapPlaceholder='Player One Handicap...'
                label='Player One/Pair One:'
                nameId='playerOneName'
                namePlaceholder='Player One Name...'
              />
              <CalculatorRow
                handicapId='playerTwoHandicap'
                handicapPlaceholder='Player Two Handicap...'
                label='Player Two/Pair One'
                nameId='playerTwoName'
                namePlaceholder='Player Two Name...'
              />
              <CalculatorRow
                handicapId='playerThreeHandicap'
                handicapPlaceholder='Player Three Handicap...'
                label='Player One/Pair Two'
                nameId='playerThreeName'
                namePlaceholder='Player Three Name...'
              />
              <CalculatorRow
                handicapId='playerFourHandicap'
                handicapPlaceholder='Player Four Handicap...'
                label='Player Two/Pair Two:'
                nameId='playerFourName'
                namePlaceholder='Player Four Name...'
              />
            </Box>
          )}
          <Box
            direction='row'
            gap='small'
            justify='center'
            margin='small'
          >
            <Button
              label='Calculate Odds'
              disabled={determineDisabled(values, matchType)}
              onClick={() => onCalculateOdds()}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}