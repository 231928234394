import React from 'react'
import {
  Box,
  Form,
  RadioButtonGroup,
  Select,
  Text,
  TextInput
} from 'grommet'
import { connect } from 'react-redux'
import styled from 'styled-components'

import PrimaryButton from '../../components/PrimaryButton'
import DangerBanner from '../../components/DangerBanner'
import DateSelect from '../../components/DateSelect'
import FieldRow from '../../components/FieldRow'
import Header from '../../components/Header'
import PlayerSelect from '../../components/PlayerSelect'
import ResponsiveBox from '../../components/ResponsiveBox'
import StyledFormField from '../../components/StyledFormField'
import { getCourts } from '../../ducks/court'
import rtoClient from '../../helpers/client'
import getErrorMessage from '../../helpers/getErrorMessage'
import MATCH_TYPES, { WEIGHT_TYPES } from '../../helpers/matchTypes'
import VERBS from '../../helpers/verbs'

const StyledLegend = styled.legend`
  font-size: 0.75em;
`

const MATCH_PAIRS = {
  SINGLES: 'Singles',
  DOUBLES: 'Doubles'
}

const DEFAULT = {
  courtID: '',
  hcapDiff: '',
  hl: 'H',
  sc: 'S',
  score: ''
}

const oneMonthAgo = new Date()
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)

const NewMatch = ({
  courts = [],
  dispatch,
  user
}) => {
  const [court, setCourt] = React.useState()
  const [date, setDate] = React.useState(new Date())
  const [matchType, setMatchType] = React.useState(MATCH_PAIRS.SINGLES)
  const [values, setValues] = React.useState(DEFAULT)
  const [availableCourts, setAvailableCourts] = React.useState([])
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    setAvailableCourts(courts)
  }, [courts])

  React.useEffect(() => {
    dispatch(getCourts())
  }, [dispatch])

  const submitMatch = () => {
    const params = {
      matchDate: date.toJSON(),
      courtID: values.courtID,
      player1: values.playerOne.identity,
      player2: values.playerTwo.identity,
      score: values.score,
      hcapDifference: values.hcapDiff,
      hl: values.hl,
      sc: values.sc,
      description: values.description,
      enteredByPersonID: user.identity
    }
    rtoClient.post(VERBS.MATCHES, params)
      .then(res => console.log(res))
      .catch(err => {
        const message = getErrorMessage(err.response)
        setError(message)
      })
  }

  return (
    <Box>
      <Header title='New Match' />
      <Box
        alignSelf='center'
        width='medium'
      >
        <Form
          onChange={nextValue => setValues(nextValue)}
          onSubmit={e => submitMatch()}
        >
          <Box margin={{ bottom: 'small' }}>
            <ResponsiveBox gap='small'>
              <StyledFormField
                htmlFor='courtID__input'
                label='Court'
                name='courtID'
                required
              >
                <Select
                  id='courtID'
                  labelKey={e => e.nameLong}
                  name='courtID'
                  onChange={e => setCourt(e.target.value)}
                  onSearch={text => {
                    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                    const exp = new RegExp(escapedText, 'i');
                    setAvailableCourts(courts.filter(o => exp.test(o.nameLong)));
                  }}
                  options={availableCourts}
                  size='small'
                  value={court && parseInt(court)}
                  valueKey={{ key: 'identity', reduce: true }}
                />
              </StyledFormField>
              <DateSelect
                date={date}
                label='Date'
                minDate={oneMonthAgo}
                name='startDate'
                setDate={setDate}
              />
            </ResponsiveBox>
            <Box align='center'>
              <fieldset>
                <StyledLegend>Match Type</StyledLegend>
                <RadioButtonGroup
                  direction='row'
                  name='matchType'
                  onChange={(event) => setMatchType(event.target.value)}
                  options={[MATCH_PAIRS.SINGLES, MATCH_PAIRS.DOUBLES]}
                  size='small'
                  value={matchType}
                />
              </fieldset>
            </Box>
            {matchType === MATCH_PAIRS.SINGLES ? (
              <ResponsiveBox gap='small' margin={{ bottom: 'small' }}>
                <StyledFormField
                  htmlFor='playerOne__input'
                  label='Player One'
                  name='playerOne'
                  required
                >
                  <PlayerSelect id='playerOne' />
                </StyledFormField>
                <StyledFormField
                  htmlFor='playerTwo__input'
                  label='Player Two'
                  name='playerTwo'
                  required
                >
                  <PlayerSelect id='playerTwo' />
                </StyledFormField>
              </ResponsiveBox>
            ) : (
              <Box>
                <Box>
                  <Text size='small' weight='bold'>Pair One</Text>
                  <ResponsiveBox gap='small'>
                    <StyledFormField
                      htmlFor='playerOne__input'
                      label='Player One'
                      name='playerOne'
                      required
                    >
                      <PlayerSelect id='playerOne' />
                    </StyledFormField>
                    <StyledFormField
                      htmlFor='playerTwo__input'
                      label='Player Two'
                      name='playerTwo'
                      required
                    >
                      <PlayerSelect id='playerTwo' />
                    </StyledFormField>
                  </ResponsiveBox>
                </Box>
                <Box>
                  <Text size='small' weight='bold'>Pair Two</Text>
                  <ResponsiveBox gap='small'>
                    <StyledFormField
                      htmlFor='playerThree__input'
                      label='Player One'
                      name='playerThree'
                      required
                    >
                      <PlayerSelect id='playerOne' />
                    </StyledFormField>
                    <StyledFormField
                      htmlFor='playerFour__input'
                      label='Player Two'
                      name='playerFour'
                      required
                    >
                      <PlayerSelect id='playerTwo' />
                    </StyledFormField>
                  </ResponsiveBox>
                </Box>
              </Box>
            )}
            <ResponsiveBox gap='small' margin={{ bottom: 'small' }}>
              <FieldRow
                label='Score'
                id='score'
                name='score'
                validate={{
                  message: 'Please enter a valid score',
                  regexp: /([1-6]\/[1-6])+$/
                }}
                value={values.score}
              />
              <StyledFormField
                htmlFor='hcapDiff'
                label='Hcap Diff Played'
                name='hcapDiff'
                required
              >
                <TextInput
                  id='hcapDiff'
                  type='number'
                  min={-99}
                  max={99}
                  name='hcapDiff'
                  size='small'
                />
              </StyledFormField>
            </ResponsiveBox>
            <ResponsiveBox>
              <FieldRow
                label='Description'
                id='description'
                name='description'
                value={values.description}
              />
            </ResponsiveBox>
            <ResponsiveBox>
              <StyledFormField
                htmlFor='type__input'
                label='Type'
                name='hl'
                required
              >
                <Select
                  id='type'
                  labelKey={e => e.label}
                  name='hl'
                  options={MATCH_TYPES}
                  size='small'
                  valueKey={{ key: 'value', reduce: true }}
                  value={values.hl}
                />
              </StyledFormField>
              <StyledFormField
                htmlFor='weight__input'
                label='Weight'
                name='sc'
                required
              >
                <Select
                  id='weight'
                  labelKey={e => e.label}
                  name='sc'
                  options={WEIGHT_TYPES}
                  size='small'
                  valueKey={{ key: 'value', reduce: true }}
                  value={values.sc}
                />
              </StyledFormField>
            </ResponsiveBox>
            {error.length > 0 && <DangerBanner message={error} />}
            <Box
              align='center'
              margin={{ top: 'small' }}
            >
              <PrimaryButton
                label='Next'
                type='submit'
              />
            </Box>
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  courts: state.court.courts,
  user: state.login.user
})

export default connect(mapStateToProps)(NewMatch)