import React from 'react'
import { Box } from 'grommet'

import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'
import DashOptions from './DashOptions'
import TermTable from './TermTable'

export default function Dashboard() {
  return (
    <Box>
      <Header title='Dashboard' />
      {false ? (
        <NoneFound message='No handicaps were found for this person.' />
      ) : (
        <Box gap='medium'>
          <DashOptions />
          <TermTable />
        </Box>
      )}
    </Box>
  )
}