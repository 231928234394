import React from 'react'
import {
  Box,
  Form,
  FormField,
  TextInput
} from 'grommet'

import DangerButton from '../../components/DangerButton'
import PrimaryButton from '../../components/PrimaryButton'
import Modal from '../../components/Modal'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'

const EditMembershipCat = ({
  cat,
  onClose,
  org
}) => {
  const [values, setValues] = React.useState(cat)

  const onSave = (deleting = false) => {
    const params = { isDeleted: deleting, orgID: org.identity }
    const updatedCat = Object.assign(cat, values, params)

    if (updatedCat.orgID) {
      rtoClient.put(`${VERBS.ORG_CATEGORY}/${cat.orgCategoryID}`, updatedCat)
        .then(res => onClose(true))
        .catch(err => {
          onClose()
          console.warn(err)
        })
    }
  }

  return (
    <Modal
      label='Edit Membership Category'
      onClose={() => onClose()}
    >
      <Form
        onChange={nextValue => setValues(nextValue)}
        onSubmit={() => onSave()}
      >
        <FormField
          htmlFor='description'
          label='Description'
          name='description'
        >
          <TextInput
            id='description'
            name='description'
            value={values.description}
          />
        </FormField>
        <FormField
          htmlFor='orgCategoryCode'
          label='Category Code'
          name='orgCategoryCode'
        >
          <TextInput
            id='orgCategoryCode'
            name='orgCategoryCode'
            value={values.orgCategoryCode}
          />
        </FormField>
        <FormField
          htmlFor='domesticRate'
          label='Domestic Rate'
          name='domesticRate'
        >
          <TextInput
            id='domesticRate'
            name='domesticRate'
            step='0.01'
            type='number'
            value={values.domesticRate || ''}
          />
        </FormField>
        <FormField
          htmlFor='overSeasRate'
          label='Overseas Rate'
          name='overSeasRate'
        >
          <TextInput
            id='overSeasRate'
            name='overSeasRate'
            step='0.01'
            type='number'
            value={values.overSeasRate || ''}
          />
        </FormField>
        <Box
          direction='row'
          gap='small'
          justify='center'
        >
          <PrimaryButton
            label='Save'
            type='submit'
          />
          <DangerButton
            label='Delete'
            onClick={() => onSave(true)}
          />
        </Box>
      </Form>
    </Modal>
  )
}

export default EditMembershipCat