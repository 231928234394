import React from 'react'
import {
  Box,
  Heading
} from 'grommet'
import { string } from 'prop-types'

export default function Header({ title }) {
  return (
    <Box
      border='bottom'
      margin={{ bottom: 'small' }}
    >
      <Heading
        level='3'
        margin='none'
      >
        {title}
      </Heading>
    </Box>
  )
}

Header.propTypes = {
  title: string
}