import React from 'react'
import {
  Box,
  Button,
  Menu,
  Text
} from 'grommet'
import styled from 'styled-components'

const HANDICAP_TYPE = ['Singles', 'Doubles']
const PERIOD = [
  'Last 12 months',
  'All time'
]
const PLAYERS = [
  'Quick Links',
  'Played 4+ times',
  'Played <4 times'
]

const StyledMenu = styled(Menu)`
  > div {
    border: 1px solid black;
    border-radius: 0.25em;
    padding: 0.2rem;
  }
`

export default function HeadToHeadOptions() {
  const [type, setType] = React.useState(HANDICAP_TYPE[0])
  const [period, setPeriod] = React.useState(PERIOD[0])
  const [players, setPlayers] = React.useState(PLAYERS[0])

  return (
    <Box
      background='ash'
      direction='row'
      gap='small'
      justify='between'
      pad='small'
    >
      <Box>
        <Text>Handicap Type</Text>
        <StyledMenu
          items={HANDICAP_TYPE.map((type) => {
            return {
              label: type,
              onClick: () => setType(type)
            }
          })}
          justifyContent='between'
          label={type}
        />
      </Box>
      <Box>
        <Text>Period</Text>
        <StyledMenu
          items={PERIOD.map((time) => {
            return {
              label: time,
              onClick: () => setPeriod(time)
            }
          })}
          justifyContent='between'
          label={period}
        />
      </Box>
      <Box>
        <Text>Players</Text>
        <StyledMenu
          items={PLAYERS.map((player) => {
            return {
              label: player,
              onClick: () => setPlayers(player)
            }
          })}
          justifyContent='between'
          label={players}
        />
      </Box>
      <Button
        alignSelf='end'
        label='Refresh'
        size='small'
      />
    </Box>
  )
}