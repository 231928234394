import React from 'react'
import {
  Box,
  FormField,
  Select
} from 'grommet'

import PrimaryButton from '../../components/PrimaryButton'

const PEOPLE = ['My Quick Links']
const HANDICAP_TYPE = ['Singles', 'Doubles']
const SHORT_TERM = [
  '60 days',
  '90 days',
  '120 days',
  '6 months'
]
const LONG_TERM = [
  '12 months',
  '24 months',
  '36 months',
  '48 months'
]

export default function DashOptions() {
  const [person, setPeople] = React.useState(PEOPLE[0])
  const [type, setType] = React.useState(HANDICAP_TYPE[0])
  const [shortTerm, setShortTerm] = React.useState(SHORT_TERM[0])
  const [longTerm, setLongTerm] = React.useState(LONG_TERM[0])

  return (
    <Box
      background='ash'
      gap='small'
      pad='small'
    >
      <Box
        direction='row'
        gap='small'
        justify='between'
      >
        <FormField
          htmlFor={`people__input`}
          label='People'
        >
          <Select
            id='people'
            onChange={e => setPeople(e.value)}
            options={PEOPLE}
            value={person}
          />
        </FormField>
        <FormField
          htmlFor={`type__input`}
          label='Handicap Type'
        >
          <Select
            id='type'
            onChange={e => setType(e.value)}
            options={HANDICAP_TYPE}
            value={type}
          />
        </FormField>
        <FormField
          htmlFor={`short_period__input`}
          label='Short Term Period'
        >
          <Select
            id='short_period'
            onChange={e => setShortTerm(e.value)}
            options={SHORT_TERM}
            value={shortTerm}
          />
        </FormField>
        <FormField
          htmlFor={`long_period__input`}
          label='Long Term Period'
        >
          <Select
            id='long_period'
            onChange={e => setLongTerm(e.value)}
            options={LONG_TERM}
            value={longTerm}
          />
        </FormField>
      </Box>
      <PrimaryButton
        alignSelf='end'
        label='Refresh'
        type='submit'
      />
    </Box>
  )
}