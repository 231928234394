import React from 'react'
import { Box } from 'grommet'
import { Route } from 'react-router-dom'

import Clubs from '../Clubs'
import Club from './Club'

export default function ClubsIndex({ match }) {
  return (
    <Box>
      <Route exact path={`${match.path}/`} component={Clubs} />
      <Route path={`${match.path}/:id`} component={Club} />
    </Box>
  )
}