import { Button } from 'grommet'
import styled from 'styled-components'

const FadeButton = styled(Button)`
  transition: 0.25s;

  &:hover, &:focus {
    color: #666510;
    text-decoration: underline;
  }
`

export default FadeButton;