import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField
} from 'grommet'
import { useHistory } from 'react-router-dom'

import Header from '../../components/Header'
import PlayerSelect from '../../components/PlayerSelect'

const DEFAULT = { player: null }

export default function PersonSearch() {
  const [value, setValue] = React.useState(DEFAULT)
  const history = useHistory()

  const goToProfile = (id) => {
    history.push(`/people/${id}/`)
  }

  return (
    <Box gap='medium'>
      <Header title='Player Search' />
      <Box margin={{ horizontal: 'auto' }}>
        <Form
          onChange={nextValue => setValue(nextValue)}
          onSubmit={() => goToProfile(value.player.identity)}
        >
          <FormField
            label='Search for a Player'
            name='player'
            width='medium'
          >
            <PlayerSelect id='player' />
          </FormField>
          <Box align='end'>
            <Button
              disabled={!value.player}
              label='Go to Profile'
              primary
              type='submit'
            />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}