import React from 'react'
import {
  Box,
  Form,
  FormField,
  Select,
  TextInput
} from 'grommet'

import PrimaryButton from '../../components/PrimaryButton'
import Modal from '../../components/Modal'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'

const DEFAULTS = {
  hcap: '',
  hcapFlagID: '',
  hcapMax: '',
}

const CreateHandicap = ({
  currentUser,
  flags = [],
  handicap,
  onClose,
  userToEdit
}) => {
  const [values, setValues] = React.useState(DEFAULTS)

  const onSubmit = () => {
    const params = {
      enteredByPersonID: currentUser.identity,
      hcap: parseInt(values.hcap) || 0,
      hcapMax: parseInt(values.hcapMax) || 0,
      hcapFlagID: parseInt(values.hcapFlagID) || 0,
      playerTypeID: handicap.identity,
      personID: userToEdit.personID
    }
    rtoClient.post(`${VERBS.PLAYER}/Create`, params)
      .then(res => onClose())
      .catch(err => {
        console.warn(err);
        onClose()
      })
  }

  return (
    <Modal
      label='Create New Handicap'
      onClose={onClose}
    >
      <Box width='medium'>
        <Form
          onChange={(next) => setValues(next)}
          onSubmit={onSubmit}
        >
          <FormField
            label='Handicap Type'
            htmlFor='type'
            name='type'
          >
            <TextInput
              disabled
              id='type'
              name='type'
              value={handicap.playerTypeLong}
            />
          </FormField>
          <FormField
            htmlFor='hcap'
            label='New Handicap'
            name='hcap'
            required
          >
            <TextInput
              id='hcap'
              name='hcap'
              type='number'
            />
          </FormField>
          <FormField
            htmlFor='hcapMax'
            label='Capped at'
            name='hcapMax'
          >
            <TextInput
              id='hcapMax'
              name='hcapMax'
              type='number'
            />
          </FormField>
          <FormField
            htmlFor='hcapFlagID__input'
            label='Handicap Flag'
            name='hcapFlagID'
          >
            <Select
              clear
              id='hcapFlagID'
              labelKey={(e) => e.description}
              name='hcapFlagID'
              options={flags}
              valueKey={{ key: 'identity', reduce: true }}
            />
          </FormField>
          <Box align='center'>
            <PrimaryButton
              a11yTitle='Save new handicap'
              label='Save'
              type='submit'
            />
          </Box>
        </Form>
      </Box>
    </Modal>
  )
}

export default CreateHandicap