import React from 'react'
import {
  Box,
  Chart,
  Text,
  Tip
} from 'grommet'
import SubHeader from '../../components/SubHeader'

const CONTENT = [
  { label: '<+5', total: 6 },
  { label: '00', total: 6 },
  { label: '05', total: 19 },
  { label: '10', total: 23 },
  { label: '15', total: 55 },
  { label: '20', total: 104 },
  { label: '25', total: 119 },
  { label: '30', total: 217 },
  { label: '35', total: 340 },
  { label: '40', total: 470 },
  { label: '45', total: 684 },
  { label: '50', total: 875 },
  { label: '55', total: 1067 },
  { label: '60', total: 1268 },
  { label: '65', total: 1392 },
  { label: '70', total: 1435 },
  { label: '75', total: 1169 },
  { label: '80', total: 951 },
  { label: '85', total: 673 },
  { label: '90', total: 472 },
  { label: '90>', total: 433 },
]

const TOTAL = 11778

const LabelledChart = ({ label, value }) => (
  <Box>
    <Tip
      content={`${value.toLocaleString()} Players ${Math.floor(value / TOTAL * 100)}%`}
      dropProps={{ align: { left: 'right' } }}
    >
      <Chart
        bounds={[
          [0, 2],
          [0, 1435],
        ]}
        values={[{ value: [1, value] }]}
        size={{ height: 'small', width: '18em' }}
      />
    </Tip>
    <Box align='center'>
      <Text size='0.5em'>{label}</Text>
    </Box>
  </Box>
);

export default function ByHandicap() {
  return (
    <Box>
      <SubHeader title='By Handicap' />
      <Box pad='xsmall' direction='row'>
        {CONTENT.map((item, i) => {
          return (
            <LabelledChart
              key={`CHART_${i}`}
              label={item.label}
              value={item.total}
            />
          )
        })}
      </Box>
    </Box>
  )
}