import { FormField } from 'grommet'
import styled from 'styled-components'

const StyledFormField = styled(FormField)`
  flex: 1;

  label {
    flex: 1;
    margin-right: 0.5em;
  }

  > div {
    flex: 2;
  }

  svg {
    margin-left: auto;
  }
`

export default StyledFormField