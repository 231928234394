import './App.css'
import React from 'react'
import {
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'
import { Box, Grommet } from 'grommet'
import { connect } from 'react-redux'

import TopBar from './components/TopBar'
import Footer from './components/Footer'
import Navigation from './components/Navigation'
import ScrollToTop from './helpers/scrollToTop'
import About from './screens/About'
import Bookings from './screens/Bookings'
import Matches from './screens/Matches'
import Calculators from './screens/Calculators'
import ClubsIndex from './screens/Clubs/ClubsIndex'
import Configuration from './screens/Configuration'
import Contact from './screens/Contact'
import Home from './screens/Home'
import People from './screens/People'
import Privacy from './screens/Privacy'
import Terms from './screens/Terms'
import theme from './theme'
import { initializeApp } from './ducks/initialize'
import LoginForm from './components/Login'
import PersonSearch from './screens/People/PersonSearch'

const App = ({
  dispatch,
  isInitialized,
  user
}) => {
  React.useEffect(() => {
    dispatch(initializeApp())
  }, [dispatch])

  if (!isInitialized) return null
  if (!user) {
    return (
      <Grommet id='grommet' theme={theme}>
        <LoginForm />
      </Grommet>
    )
  }

  return (
    <Grommet id='grommet' theme={theme}>
      <Router>
        <Box height={{ min: '100vh' }}>
          <TopBar />
          <Navigation />
          <Box
            flex='grow'
            pad='medium'
          >
            <ScrollToTop />
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/calculators' component={Calculators} />
              <Route path='/configuration' component={Configuration} />
              <Route path='/clubs' component={ClubsIndex} />
              <Route exact path='/people' component={PersonSearch} />
              <Route path='/people/:id' component={People} />
              <Route path='/about' component={About} />
              <Route path='/terms' component={Terms} />
              <Route path='/matches' component={Matches} />
              <Route path='/contact' component={Contact} />
              <Route path='/privacy' component={Privacy} />
              <Route path='/bookings' component={Bookings} />
            </Switch>
          </Box>
          <Footer />
        </Box>
      </Router>
    </Grommet>
  );
}

const mapStateToProps = (state) => ({
  isInitialized: state.initialize.isInitialized,
  user: state.login.user
})

export default connect(mapStateToProps)(App);
