import React from 'react'
import { Box } from 'grommet'

import Header from '../../../components/Header'

const AddBooking = () => {
  return (
    <Box margin='small'>
      <Header title='Manage Booking' />
    </Box>
  )
}

export default AddBooking