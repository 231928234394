import React from 'react'
import {
  Box
} from 'grommet'
import {
  Route,
  withRouter
} from 'react-router-dom'

import Match from '../Match'
import NewMatch from './NewMatch'
import MatchSearch from './MatchSearch'

const Matches = ({ match }) => {
  return (
    <Box>
      <Route exact path={`${match.path}/`} component={NewMatch} />
      <Route path={`/matches/search`} component={MatchSearch} />
      <Route path={`/matches/match/:id`} component={Match} />
    </Box>
  )
}

export default withRouter(Matches)