import React from 'react'
import {
  Box,
  DataTable
} from 'grommet'
import { connect } from 'react-redux'

import COLUMNS from './columns'
import NoneFound from '../NoneFound'
import ItalicText from '../ItalicText'

const Handicaps = ({
  activePlayers = []
}) => {
  if (!activePlayers.length) {
    <NoneFound message='No handicaps were found for this person.' />
  }

  return (
    <Box
      gap='xsmall'
      overflow='scroll'
    >
      <DataTable
        columns={COLUMNS}
        data={activePlayers}
      />
      <ItalicText alignSelf='end' size='small'>
        * Rankings are based on handicaps and are not the official world rankings.
      </ItalicText>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activePlayers: state.player.activePlayers,
  playerTypes: state.player.playerTypes
})

export default connect(mapStateToProps)(Handicaps)