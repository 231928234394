const theme = {
  global: {
    colors: {
      ash: '#f5f5f5',
      brand: '#666510',
      clay: '#a03619',
      danger: '#df4759',
      darkGray: '#303130',
      dust: '#F5F5F5',
      focus: '#5E9ED6',
      lightClay: '#dbac9e',
      lightGray: '#ededed',
      lightOlive: '#f4f4df',
      medOlive: '#c6c9b5',
      olive: '#666510',
      success: '#42ba96'
    },
    font: {
      family: "'Karla', sans, sans-serif",
      face: `
        @font-face {
          font-family: 'Karla';
          font-style: normal;
          font-weight: 400;
          src:
            url("https://fonts.googleapis.com/css2?family=Karla&display=swap", format('woff2'));
        }
      `
    },
    input: {
      font: {
        weight: 100
      },
      padding: 'xsmall'
    }
  },
  accordion: {
    heading: {
      margin: 'small'
    }
  },
  button: {
    border: {
      color: 'olive',
      radius: '0.25em'
    },
    size: {
      small: {
        border: {
          radius: '0.25em'
        },
        pad: '0.25em'
      },
    }
  },
  checkBox: {
    size: '1em'
  },
  dataTable: {
    body: {
      extend: props => `
        tr:nth-child(even) {
          background: #f2f2f2;
        }

        tr {
          &:hover, &:focus {
            background: lightGray;
          }
        }

        span, a {
          font-size: 0.9rem;
        }
      `
    },
    header: {
      background: 'medOlive',
      font: {
        size: 'small',
        weight: 'bold'
      }
    }
  },
  dateInput: {
    icon: {
      size: '16px'
    }
  },
  formField: {
    border: {
      side: 'all'
    },
    error: {
      size: 'small'
    },
    extend: props => `
      label {
        font-weight: bold;
      }
    `,
    label: {
      margin: {
        bottom: '0.2em',
        horizontal: 'none'
      },
      size: 'small'
    }
  },
  radioButton: {
    container: {
      extend: props => `
        span {
          font-size: 14px;
        }
      `
    },
    size: '14px'
  },
  tip: {
    content: {
      background: 'white',
      round: 'xsmall'
    }
  }
}

export default theme