import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  Text,
  TextInput
} from 'grommet'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import DangerBanner from '../DangerBanner'
import FadeButton from './FadeButton'

const DEFAULT = {
  code: '',
  confirmNewPassword: '',
  newPassword: ''
}

const ConfirmationCodeForm = ({
  onResetSuccess,
  returnToLogin,
  username
}) => {
  const [error, setError] = React.useState(null)
  const [value, setValue] = React.useState(DEFAULT);

  React.useEffect(() => {
    setError(false)
  }, [])

  const resetPassword = () => {
    const { code, newPassword } = value
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(data => onResetSuccess())
      .catch(err => setError(true));
  }

  const disableCodeSubmit = !value.code.length || !value.newPassword.length
    || (value.newPassword !== value.confirmNewPassword)

  return (
    <Box align='center'>
      <Text alignSelf='start' size='small'>
        Enter the confirmation code that arrived in your email
      </Text>
      <Box
        margin={{ vertical: 'medium' }}
        width='medium'
      >
        <Form
          onChange={nextValue => setValue(nextValue)}
          onSubmit={() => resetPassword()}
        >
          <FormField
            name='code'
            htmlFor='code'
            label='Confirmation Code'
          >
            <TextInput
              id='code'
              name='code'
              pad='xsmall'
              value={value.code}
            />
          </FormField>
          <FormField
            name='newPassword'
            htmlFor='newPassword'
            label='New Password'
          >
            <TextInput
              id='newPassword'
              name='newPassword'
              pad='xsmall'
              type='password'
              value={value.newPassword}
            />
          </FormField>
          <FormField
            name='confirmNewPassword'
            htmlFor='confirmNewPassword'
            label='Confirm New Password'
          >
            <TextInput
              id='confirmNewPassword'
              name='confirmNewPassword'
              pad='xsmall'
              type='password'
              value={value.confirmNewPassword}
            />
          </FormField>
          <FadeButton
            label={<Text size='small'>Return to Login</Text>}
            onClick={returnToLogin}
            plain
          />
          {error && (
            <DangerBanner message='Oops, something went wrong. Please try again.' />
          )}
          <Box
            align='end'
            margin={{ top: 'small' }}
          >
            <Button
              disabled={disableCodeSubmit}
              label='Reset'
              type="submit"
            />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default connect()(ConfirmationCodeForm)