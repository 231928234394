import React from 'react'
import {
  Box,
  DataTable,
  Layer
} from 'grommet'
import { connect } from 'react-redux'

import EditButton from '../../components/EditButton'
import Header from '../../components/Header'
import NoneFound from '../../components/NoneFound'
import PrimaryButton from '../../components/PrimaryButton'
import Spinner from '../../components/Spinner'
import CreateMembershipCat from './CreateMembershipCat'
import { getMembershipCats } from '../../ducks/org'
import EditMembershipCat from './EditMembershipCat'
import STATES from '../../helpers/async'

const getColumns = (editCat) => {
  return [
    {
      property: 'action',
      header: 'Action',
      render: (datum) => <EditButton onClick={() => editCat(datum)} label='Edit' plain />
    }, {
      property: 'description',
      header: 'Description'
    }, {
      property: 'orgCategoryCode',
      header: 'Code'
    }, {
      property: 'domesticRate',
      header: 'Domestic Rate'
    }, {
      property: 'overSeasRate',
      header: 'Overseas Rate'
    }
  ]
}

const ClubMembershipCats = ({
  activeOrg = {},
  activeOrgMembershipCats = [],
  activeOrgMembershipCatsStatus,
  dispatch
}) => {
  const {
    identity
  } = activeOrg
  const [cat, selectCat] = React.useState(null)
  const [createNew, setCreateNew] = React.useState(false)

  React.useEffect(() => {
    if (identity) {
      dispatch(getMembershipCats(identity))
    }
  }, [dispatch, identity])

  if (activeOrgMembershipCatsStatus === STATES.FETCHING) {
    return (
      <Box align='center'>
        <Spinner label='Loading Membership Categories' />
      </Box>
    )
  }

  const onSave = (reset = false) => {
    if (reset) {
      dispatch(getMembershipCats(identity))
    }
    selectCat(null)
    setCreateNew(false)
  }

  return (
    <Box>
      {cat && (
        <Layer onClickOutside={() => selectCat(null)}>
          <EditMembershipCat
            cat={cat}
            onClose={(reset) => onSave(reset)}
            org={activeOrg}
          />
        </Layer>
      )}
      {createNew && (
        <Layer onClickOutside={() => setCreateNew(false)}>
          <CreateMembershipCat
            onClose={(reset) => onSave(reset)}
            orgID={activeOrg.identity}
          />
        </Layer>
      )}
      <Header title='Membership Categories' />
      {!activeOrgMembershipCats.length ? (
        <Box align='center'>
          <NoneFound message='No Membership Categories Found' />
        </Box>
      ) : (
        <DataTable
          columns={getColumns((cat) => selectCat(cat))}
          data={activeOrgMembershipCats}
          onClickRow={e => selectCat(e.datum)}
        />
      )}
      <PrimaryButton
        a11yTitle='Add new membership category'
        alignSelf='center'
        label='Create'
        margin={{ top: 'medium' }}
        onClick={() => setCreateNew(true)}
      />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activeOrg: state.org.activeOrg,
  activeOrgMembershipCats: state.org.activeOrgMembershipCats,
  activeOrgMembershipCatsStatus: state.org.activeOrgMembershipCatsStatus
})

export default connect(mapStateToProps)(ClubMembershipCats)