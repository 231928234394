import React from 'react'
import {
  Accordion,
  Box,
  DropButton
} from 'grommet'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import CollapsableSection from './CollapsableSection'
import NavAnchor from './NavAnchor'

const StyledBox = styled(Box)`
  align-items: stretch;
`

const DesktopView = styled(Box)`
  @media (max-width: 815px) {
    display: none;
  }
`

const MobileView = styled(Box)`
  @media (min-width: 816px) {
    display: none;
  }
`

const bookingState = {
  activeBackground: '#dbac9e',
  inactiveBackground: '#a03619',
  activeColor: 'black',
  inactiveColor: 'white'
}

const mainState = {
  activeBackground: '#f4f4df',
  inactiveBackground: '#666510',
  activeColor: 'black',
  inactiveColor: 'white'
}

const TopNav = ({
  activeRoute,
  id,
  location,
  routes
}) => {
  return (
    <Box>
      <DesktopView direction='row'>
        {routes.map((route, i) => {
          const isActive = route.href.length > 1 && location.pathname.includes(route.href)
          const state = route.isBooking ? bookingState : mainState;
          return (
            <StyledBox
              key={`NAV_${i}`}
            >
              <NavAnchor
                href={route.href}
                label={route.label}
                isActive={isActive}
                isExternal={route.isExternal}
                state={state}
              />
            </StyledBox>
          )
        })}
      </DesktopView>
      <MobileView>
        <DropButton
          dropContent={
            <Box
              background='white'
              border={{ color: 'black' }}
              overflow='auto'
            >
              <Accordion>
                {routes.map((route, i) => {
                  return (
                    <CollapsableSection
                      activeRoute={activeRoute}
                      id={id}
                      key={`SECTION_${i}`}
                      route={route}
                    />
                  )
                })}
              </Accordion>
            </Box>
          }
          dropProps={{ align: { top: 'bottom' } }}
          label={<FontAwesomeIcon icon={faBars} />}
          margin='xsmall'
          plain
        />
      </MobileView>
    </Box>
  )
}

export default withRouter(TopNav)