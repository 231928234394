import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  TextInput
} from 'grommet'
import VERBS from '../../helpers/verbs'
import rtoClient from '../../helpers/client'
import Modal from '../../components/Modal'

const ClubCourtEdit = ({
  court,
  onClose
}) => {
  const [values, setValues] = React.useState(court)

  React.useEffect(() => {
    if (court.courtID) {
      rtoClient.get(`${VERBS.COURT}/${court.courtID}`)
        .then(res => setValues(res.data))
        .catch(err => console.warn(err))
    }
  }, [court])

  const onSubmit = () => {
    rtoClient.put(`${VERBS.COURT}/${court.courtID}`, values)
      .then(res => onClose(true))
      .catch(err => {
        console.warn(err)
        onClose(false)
      })
  }

  return (
    <Modal
      label='Edit Court'
      onClose={() => onClose(false)}
    >
      <Form
        onChange={(next) => {
          const newVals = Object.assign(values, next)
          setValues({ ...newVals })
        }}
        onSubmit={onSubmit}
      >
        <FormField
          label='Name'
          name='nameLong'
        >
          <TextInput
            name='nameLong'
            value={values.nameLong}
          />
        </FormField>
        <Box
          align='center'
          margin={{ top: 'medium' }}
        >
          <Button
            label='Save'
            primary
            type='submit'
          />
        </Box>
      </Form>
    </Modal>
  )
}

export default ClubCourtEdit