export default function getErrorMessage(response) {
  const data = response.data

  if (data.errors?.length) {
    if (data.errors.length > 1) {
      return data.errors.join(', ');
    } else {
      return data.errors[0];
    }
  }

  switch (response.status) {
    case 403:
      return 'You do not have permission';
    default:
      return 'Sorry, something went wrong';
  }
}