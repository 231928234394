import rtoClient from '../helpers/client'
import VERBS from '../helpers/verbs'
import STATES from '../helpers/async'

const RESET_ACTIVE_PLAYERS = 'RESET_ACTIVE_PLAYERS'
const SET_ACTIVE_PLAYER = 'SET_ACTIVE_PLAYER'
const SET_ACTIVE_PLAYERS = 'SET_ACTIVE_PLAYERS'
const SET_PLAYER_TYPES = 'SET_PLAYER_TYPES'
const SET_MEMBERSHIP = 'SET_MEMBERSHIP'
const SET_MEMBERSHIP_STATUS = 'SET_MEMBERSHIP_STATUS'

const initialState = {
  activePlayer: null,
  activePlayers: [],
  memberships: [],
  membershipStatus: STATES.READY,
  playerTypes: []
};

const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ACTIVE_PLAYERS:
      return Object.assign({}, state, {
        activePlayer: null,
        activePlayers: []
      });

    case SET_ACTIVE_PLAYER:
      return Object.assign({}, state, {
        activePlayer: action.activePlayer
      });

    case SET_ACTIVE_PLAYERS:
      return Object.assign({}, state, {
        activePlayers: action.activePlayers
      });

    case SET_MEMBERSHIP:
      return Object.assign({}, state, {
        memberships: action.memberships,
        membershipStatus: STATES.READY
      });

    case SET_MEMBERSHIP_STATUS:
      return Object.assign({}, state, {
        membershipStatus: action.membershipStatus
      });

    case SET_PLAYER_TYPES:
      return Object.assign({}, state, {
        playerTypes: action.playerTypes
      });

    default:
      return state;
  }
}

const getMemberships = () => {
  return (dispatch, getState) => {
    const activePlayer = getState().player.activePlayer
    dispatch({
      type: SET_MEMBERSHIP_STATUS,
      membershipStatus: STATES.FETCHING
    })
    if (!activePlayer || !activePlayer.personID) return
    return rtoClient.get(`${VERBS.PERSON}/${activePlayer.personID}/Memberships`)
      .then(res => {
        dispatch({
          type: SET_MEMBERSHIP,
          memberships: res.data
        })
      })
      .catch(err => {
        console.warn('Error fetching memberships: ', err.message)
        dispatch({
          type: SET_MEMBERSHIP_STATUS,
          membershipStatus: STATES.READY
        })
      })
  }
}

const getPlayerTypes = () => {
  return (dispatch) => {
    return rtoClient.get(VERBS.PLAYER_TYPE)
      .then(res => {
        const { items } = res.data

        dispatch({
          type: SET_PLAYER_TYPES,
          playerTypes: items
        })
      })
      .catch(err => {
        console.warn('Player types fetch error: ', err.message)
      })
  }
}

const resetActivePlayer = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ACTIVE_PLAYERS })
  }
}

const setActivePlayers = (personId) => {
  return (dispatch) => {
    return rtoClient.get(`${VERBS.PERSON}/${personId}/Players/View`)
      .then(res => {
        const { items } = res.data

        dispatch({
          type: SET_ACTIVE_PLAYERS,
          activePlayers: items
        })
      })
      .catch(err => {
        console.warn('Players fetch error: ', err.message)
      })
  }
}

const setActivePlayer = (id) => {
  return (dispatch) => {
    if (!id) {
      return dispatch({
        type: SET_ACTIVE_PLAYER,
        activePlayer: null
      })
    }

    return rtoClient.get(`${VERBS.PERSON}/${id}/View`)
      .then(res => {
        const activePlayer = res.data
        dispatch(setActivePlayers(activePlayer.personID))

        dispatch({
          type: SET_ACTIVE_PLAYER,
          activePlayer
        })
      })
      .catch(err => {
        console.warn('User fetch error: ', err.message)
      })
  }
}

export default playerReducer;

export {
  getMemberships,
  getPlayerTypes,
  resetActivePlayer,
  setActivePlayer,
  setActivePlayers
};