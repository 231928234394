import React from 'react'
import {
  Box,
  Heading
} from 'grommet'

export default function SubHeader({
  margin = { bottom: 'small' },
  title
}) {
  return (
    <Box
      border='bottom'
      margin={margin}
    >
      <Heading
        level='4'
        margin='none'
      >
        {title}
      </Heading>
    </Box>
  )
}