import React from 'react'
import {
  Box,
  Nav,
  Text
} from 'grommet'
import ItalicText from './ItalicText'

import CommonAnchor from './CommonAnchor'

export default function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <Nav
      background='darkGray'
      border='top'
      direction='row'
      justify='between'
      pad={{ horizontal: 'large', vertical: 'medium' }}
    >
      <Box>
        <ItalicText>Powered by RTO</ItalicText>
        <Text size='small'>&#169; Copyright 2001 - {currentYear}</Text>
      </Box>
      <Box gap='xxsmall'>
        <CommonAnchor
          color='white'
          label='About'
          size='medium'
          weight={100}
          url='/about'
        />
        <CommonAnchor
          color='white'
          label='Terms of Use'
          size='medium'
          weight={100}
          url='/terms'
        />
        <CommonAnchor
          color='white'
          label='Privacy'
          size='medium'
          weight={100}
          url='/privacy'
        />
        <CommonAnchor
          color='white'
          label='Contact'
          size='medium'
          weight={100}
          url='/contact'
        />
      </Box>
    </Nav>
  )
}