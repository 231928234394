import React from 'react'
import {
  Box,
  Button,
  Text
} from 'grommet'
import { connect } from 'react-redux'

import Modal from '../../components/Modal'
import { setActiveOrgCourts } from '../../ducks/org'
import rtoClient from '../../helpers/client'
import VERBS from '../../helpers/verbs'

const AddCourt = ({
  courtToAdd,
  dispatch,
  onClose,
  org
}) => {
  const onAddCourt = () => {
    const params = {
      courtID: courtToAdd.identity,
      orgID: org.identity
    }
    rtoClient.post(VERBS.ORG_COURT, params)
      .then(() => {
        dispatch(setActiveOrgCourts(org.identity))
        onClose()
      })
      .catch(err => {
        console.warn(err)
        onClose()
      })
  }

  return (
    <Modal
      label='Add Court'
      onClose={onClose}
    >
      <Text>
        Are you sure you want to add <strong>{courtToAdd.nameLong} </strong>
        as a <strong>{org.nameLong}</strong> court?
      </Text>
      <Box
        direction='row'
        gap='small'
        justify='center'
      >
        <Button
          label='Cancel'
          onClick={onClose}
        />
        <Button
          label='Confirm'
          onClick={onAddCourt}
          primary
        />
      </Box>
    </Modal>
  )
}

export default connect()(AddCourt)