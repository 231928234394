import React from 'react'
import {
  Box,
  Button,
  Form,
  FormField,
  Text,
  TextInput
} from 'grommet'
import { connect } from 'react-redux'

import PrimaryButton from '../../../components/PrimaryButton'
import Modal from '../../../components/Modal'
import rtoClient from '../../../helpers/client'
import VERBS from '../../../helpers/verbs'
import { getHandicapFlags } from '../../../ducks/handicap'

const DEFAULT = {
  description: ''
}

const EditFlag = ({
  activeFlag,
  dispatch,
  onClose
}) => {
  const [values, setValues] = React.useState(DEFAULT)
  const action = !!activeFlag ? 'Edit' : 'Create New'

  React.useEffect(() => {
    if (!!activeFlag) {
      setValues(activeFlag)
    }
  }, [activeFlag])

  const onSubmit = (isDeleted = false) => {
    const params = Object.assign(values, { isDeleted })
    if (activeFlag) {
      rtoClient.put(`${VERBS.HANDICAP_FLAG}/${activeFlag.identity}`, params)
        .then(res => {
          dispatch(getHandicapFlags())
          onClose()
        })
        .catch(err => {
          console.warn(err)
          onClose()
        })
    } else {
      rtoClient.post(VERBS.HANDICAP_FLAG, params)
        .then(res => {
          dispatch(getHandicapFlags())
          onClose()
        })
        .catch(err => {
          console.warn(err)
          onClose()
        })
    }
  }

  return (
    <Modal
      label={`${action} Flag`}
      onClose={() => onClose()}
    >
      {!activeFlag && (
        <Text size='small'>
          Enter a name to save a new handicap flag in the RTO database
        </Text>
      )}
      <Form
        onChange={(next) => setValues(next)}
        onSubmit={() => onSubmit()}
      >
        <FormField
          htmlFor='description'
          label='Handicap Flag'
          name='description'
        >
          <TextInput
            id='description'
            name='description'
            size='small'
            value={values.description}
          />
        </FormField>
        <Box
          justify='center'
          direction='row'
          gap='small'
          margin={{ top: 'medium' }}
        >
          {!!activeFlag && (
            <Button
              label='Delete'
              onClick={() => onSubmit(true)}
            />
          )}
          <PrimaryButton
            disabled={!values.description.length}
            label='Submit'
            type='submit'
          />
        </Box>
      </Form>
    </Modal>
  )
}

export default connect()(EditFlag)