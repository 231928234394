import React from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Button,
  DataTable,
  Select,
  Text
} from 'grommet'
import styled from 'styled-components'

import NoneFound from '../NoneFound'
import { DAYS } from '../../helpers/timeSpan'
import { getMovements } from '../../ducks/handicap'
import COLUMNS, { SUMMARY_COLUMNS } from './columns'

const TIME_SPANS = [
  { label: '90 days', span: DAYS.THREE_MONTHS },
  { label: '180 days', span: DAYS.SIX_MONTHS },
  { label: '365 days', span: DAYS.ONE_YEAR }
]

const StyledButton = styled(Button)`
  background: #f4f4df;
  color: black;
`

const HandicapMovements = ({
  activePlayer,
  activePlayers = [],
  dispatch,
  movements = [],
  movementSummary = []
}) => {
  const [type, setType] = React.useState()
  const [timeSpan, setTimeSpan] = React.useState(TIME_SPANS[0])
  const noMovements = !activePlayer || activePlayers.length < 1

  const updateMovements = () => {
    dispatch(getMovements(type.playerID, timeSpan.span))
  }

  React.useEffect(() => {
    if (activePlayers.length) {
      setType(activePlayers[0])
      dispatch(getMovements(activePlayers[0].playerID, TIME_SPANS[0].span))
    }
  }, [
    activePlayers,
    dispatch
  ])

  if (noMovements) {
    return (
      <Box align='center'>
        <NoneFound message='No handicap movements were found for this person.' />
      </Box>
    )
  }

  return (
    <Box
      gap='medium'
      margin={{ vertical: 'small' }}
    >
      <Box
        gap='small'
        margin={{ horizontal: 'auto' }}
      >
        <Box
          direction='row'
          gap='small'
        >
          <Box align='center'>
            <Text
              alignSelf='start'
              size='small'
            >
              Handicap Type
            </Text>
            <Select
              a11yTitle='Handicap Type'
              labelKey={(e) => e.playerTypeLong}
              onChange={(e) => setType(e.value)}
              options={activePlayers}
              size='small'
              value={type}
            />
          </Box>
          <Box align='center'>
            <Text
              alignSelf='start'
              size='small'
            >
              Period
            </Text>
            <Select
              a11yTitle='Time Period'
              labelKey={(e) => e.label}
              onChange={(e) => setTimeSpan(e.value)}
              options={TIME_SPANS}
              size='small'
              value={timeSpan}
            />
          </Box>
        </Box>
        <StyledButton
          alignSelf='end'
          label='Refresh'
          onClick={() => updateMovements()}
          primary
          size='small'
        />
      </Box>
      {!movements.length ? (
        <Box align='center'>
          <NoneFound message='No Matches Found' />
        </Box>
      ) : (
        <Box gap='medium'>
          <DataTable
            columns={SUMMARY_COLUMNS}
            data={movementSummary}
          />
          <DataTable
            columns={COLUMNS}
            data={movements}
          />
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  activePlayer: state.player.activePlayer,
  activePlayers: state.player.activePlayers,
  playerTypes: state.player.playerTypes,
  movements: state.handicap.movements,
  movementSummary: state.handicap.movementSummary,
})

export default connect(mapStateToProps)(HandicapMovements)